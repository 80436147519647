import BaseRoute from '../components/Router/BaseRoute';
import {Route} from '@/components/Router/decorators/RouteDecorator';

class CheckoutRoutes extends BaseRoute {

    @Route('/checkout')
    private openCheckout;

	@Route('/bond/{id}')
	private openCheckoutBond;

	@Route('/loan/{id}')
	private openCheckoutLoan;

    @Route('/checkout/step/{step}')
    private switchCheckoutStep;
}

export default CheckoutRoutes;
