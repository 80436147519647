











































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../ExporoVue';
import InvestService from '../Checkout/services/InvestService';
import IbanService from './services/IbanService';
import Error from '@/components/Checkout/Validator/Error.vue';
import CheckoutValidator from '../Checkout/Validator/CheckoutValidator';
import Input from '@/components/Input/Input.vue';

@Component({
    components: {
        Error,
        Input
    }
})
export default class IbanInput extends ExporoVue {

    private iban: string = '';
    private bic: string | null = null;

    @Prop()
    private investService!: InvestService;
    @Prop()
    private checkoutValidator!: CheckoutValidator;
    @Prop()
    private step!: number;
    @Prop()
    private onChange!: (any) => void;
    @Prop({default: ''})
    private userIban!: string;
    @Prop({default: null})
    private userBic!: string;

    @Prop({default: false})
    private hideIban!: boolean;

    @Prop({default: 'invest_iban'})
    private errorNameIban!: string;
    @Prop({default: 'invest_bic'})
    private errorNameBic!: string;

    private ibanService: IbanService;
    private error: string | null = null;
    private validated: boolean = false;

    constructor() {
        super();

        this.ibanService = new IbanService();
    }

    mounted() {
      this.iban = this.userIban;
      this.bic = this.userBic;
    }

    onBlur() {
        if (!this.ibanService.isValidIBANNumber(this.iban)) {
            this.bic = null;
            this.error = this.trans.get('iban.error_not_valid');
        }
    }

    @Watch('iban')
    onIbanChanged(val: string, oldVal: string) {
        this.validated = false;

        if (this.ibanService.isValidIBANNumber(val)) {
            if (this.investService) {
                this.investService.updateFomData('invest_iban', this.iban);
            }

            this.ibanService.getBicFromIban(val, (ibanResponse: IbanCheckResponse) => {
                this.validated = true;

                if (!ibanResponse.error) {
                    this.bic = ibanResponse.bic;
                    this.error = null;

                    if (this.investService) {
                        this.investService.updateFomData('invest_bic', this.bic);
                    }

                    if (this.onChange) {
                        this.onChange({
                            'iban': this.iban,
                            'bic': this.bic
                        });
                    }
                } else {
                    this.bic = null;
                    this.error = this.trans.get('iban.error_cannot_check');
                }
            });
        }
    }
}
