




























































































































































































































































    import Accordion from '../../Accordion/Accordion.vue';
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import IbanService from '../../Iban/services/IbanService';
    import ForceEmailCheckbox from '@/components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
    import InvestService from '../services/InvestService';
    import IbanInput from '../../Iban/IbanInput.vue';
    import Error from '@/components/Checkout/Validator/Error.vue';
    import CheckoutValidator from '../Validator/CheckoutValidator';
    import Checkbox from '@/components/Checkbox/Checkbox.vue';
    import {Contract} from '../models/CheckoutDataWpHGLight';
    import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
    import InvestmentRequestPart from '../models/InvestmentRequestPart';
    import Select from '@/components/Select/Select.vue';
    import Input from '@/components/Input/Input.vue';
    import ActionCode from '@/components/ActionCode/ActionCode.vue';
    import PubSubService, {TYPE_BOND} from '@/components/PubSub/services/PubSubService';
    import ActionCodeUserDetails from '@/components/ActionCode/ActionCodeUserDetails';
    import Events from '../../../events';
    import ExporoVue from '@/components/ExporoVue';
    import axios, {AxiosError} from 'axios';
    import {BankAccount} from '@/components/Checkout/interfaces/eMoney';
    import SessionStorage from '@/components/SessionStorage/SessionStorage';

    @Component({
        components: {
            Accordion,
            ForceEmailCheckbox,
            IbanInput,
            Error,
            Checkbox,
            Input,
            Select,
            ActionCode,
        },
        props: {
            shouldHide: Boolean,
            switchStep: Function,
        },
    })
    class InvestmentWpHGLite extends ExporoVue implements InvestmentRequestPart {

        private MIN_INVESTMENT_SUM = 2000;

        private payloadPart: any = {
            piece_value: 0,
            pieces: 2,
            investtype: 'debit',
            debitMandate: false,
            vib_file: false,
            legal_obligation: false,
            invest_iban: '',
            invest_bic: '',
            single_unit_threshold: false,
            kistam: false,
        };

        public TITLE = this.trans.get('checkout.WpHGLite.investmentWpHGLite.title');
        private pieces: number = 2;
        private pieceValue: number = 0;
        private investsumText: string = '7.000';
        @Prop()
        private isBondTypeBlockchain!: boolean;
        @Prop()
        private singleUnitThresholdText!: string;
        @Prop()
        private investService!: InvestService;
        private investtype: string = this.investService.getInvestTypeDebit();
        private ibanService: IbanService;
        @Prop()
        private contract!: Contract;
        @Prop()
        private isFirstInvestment!: boolean;
        @Prop()
        private tradingAccountExists!: boolean;
        @Prop()
        private user!: any;
        private vibFile = null;
        @Prop()
        private step!: number;
        @Prop()
        private checkoutValidator!: CheckoutValidator;
        @Prop()
        private legalTextInvestment!: string;
        @Prop()
        private legalTextInformationDocument!: string;
        @Prop()
        private legalTextVermittlerinformationen!: string;
        @Prop()
        private availablePieces!: number;
        @Prop()
        private legalTextSepa!: string;
        @Prop()
        private requiredFileLegalVermittlerinformationen!: string;
        @Prop()
        private requiredFileLegalWib!: string;
        @Prop()
        private showKistam!: boolean;
        @Prop()
        private isFinancing!: boolean;
        @Prop()
        private storeInvestmentRequestService!: StoreInvestmentRequestService;
        private debitMandate: boolean = false;
        private isLoading: boolean = false;

        private remainingPieces: any = 0;
        private bankAccounts: BankAccount[] = [];
      private activeBankAccount: string | null = null;
      private createBankAccount: any = null;
      private createUsersIban: any = null;
      private createUsersBic: any = null;
      private isMandateInvalid: boolean = false;
      private shouldCreateMandateFor: any = null;
      private existMandateId: string | null = null;

      @Watch('payloadPart.pieces')
      piecesChanges() {
        this.investsumText = this.formatPrice(this.pieceValue * this.payloadPart.pieces);
        this.investService.setInvestSum(this.pieceValue * this.payloadPart.pieces);


        if (this.pieceValue * this.payloadPart.pieces > 20000) {
          this.toggleDebit(false);
        } else {
          this.toggleDebit(true);
        }
      }

      private showIban = true;

     @Watch('payloadPart.invest_iban')
      ibanChanged() {
       this.showIban = false;
       this.$nextTick(() => {
         this.showIban = true;

         this.$forceUpdate();
       });
      }


        constructor() {
            super();

            this.pieceValue = this.contract.piece_value || 1000;
            this.investsumText = this.formatPrice(this.pieceValue * this.payloadPart.pieces);
            this.ibanService = new IbanService();
            this.investService.setInvestSum(this.pieceValue * this.pieces);
            this.investService.updateFomData('pieces', this.pieces);
            this.storeInvestmentRequestService.registerRequestPart(this);
			this.pieces = this.contract.min_investment_sum / this.pieceValue;

            if (this.pieceValue < 500) {
				this.pieces = this.MIN_INVESTMENT_SUM;
			}

            this.investsumText = this.formatPrice(this.pieceValue * this.pieces);
            this.investService.setInvestSum(this.pieceValue * this.pieces);
            this.investService.updateFomData('pieces', this.pieces);
            this.payloadPart.pieces = this.pieces;
            this.payloadPart.piece_value = this.pieceValue;

            this.payloadPart.invest_iban = this.user.usersIban;
            this.payloadPart.invest_bic = this.user.usersBic;

            PubSubService.getInstance(() => {
                this.remainingPieces = PubSubService.getInstance().getProject(this.contract.id, TYPE_BOND).availableInvestment;
            });

        }

        mounted() {
          this.getListOfBankAccounts();
          this.updateInvestType();

          if (SessionStorage.get('checkMandate', false)) {
            this.payloadPart = SessionStorage.getObject('investmentPayload', {});
            this.activeBankAccount = SessionStorage.get('activeBankAccount', null);
            if (this.investtype === 'debit') {
              this.checkMandate(this.activeBankAccount);
            }
          }
          this.$forceUpdate();
        }

        onChangeBankAccount(createBankAccount) {
          this.createBankAccount = createBankAccount;
        }

        addBankAccount() {
          const token = localStorage.getItem('authToken');
          if (token) {
            if (this.createBankAccount) {
              const data = {
                'IBAN': this.createBankAccount.iban,
                'BIC': this.createBankAccount.bic,
                'OwnerName': this.user.first_name + this.user.last_name,
                'OwnerAddress': {
                  'AddressLine1': `${this.user.street} ${this.user.street_no}`,
                  'City': `${this.user.city}`,
                  'Region': `${this.user.city}`,
                  'PostalCode': `${this.user.postal_code}`,
                  'Country': `${this.user.country}`
                },
              };

              this.isLoading = true;

              axios.post('https://api.emoney.exporo.com/prod/bank-account', data,  {
                headers: {
                  'Authorization': `Bearer ${JSON.parse(token)?.idToken}`
                }
              }).then((response: any) => {
                if (response.data && response.data.CreationDate) {
                  this.bankAccounts.push(response.data);
                  this.setBankAccount(response.data);
                  this.isLoading = false;
                }
              });
            }
          }
        }


        setBankAccount(activeBankAccount: BankAccount) {
          this.activeBankAccount = activeBankAccount.Id;
          this.onChangeIbanOrBic({
            iban: activeBankAccount.IBAN,
            bic: activeBankAccount.BIC
          });

          if (this.investtype === 'debit') {
            this.checkMandate(activeBankAccount.Id);
          }
        }

        addMandate(bankAccountId: string) {
          const token = localStorage.getItem('authToken');

          this.saveCheckoutData();

          if (token) {
            this.isLoading = true;
            axios.post(`https://api.emoney.exporo.com/prod/mandates`, {
              'BankAccountId': bankAccountId,
              'ReturnURL': window.location.href,
              'Culture': 'DE'
            }, {
              headers: {
                'Authorization': `Bearer ${JSON.parse(token)?.idToken}`
              }
            }).then((response: any) => {
              if (response && response.data && response.data.RedirectURL) {
                this.saveCheckoutData();
                window.location.href = response.data.RedirectURL;
              }

              this.isLoading = false;
            }).catch((error: AxiosError) => {
              if (error.response?.status === 409 && this.existMandateId) {
                axios.get(`https://api.emoney.exporo.com/prod/mandates/${this.existMandateId}`, {
                  headers: {
                    'Authorization': `Bearer ${JSON.parse(token)?.idToken}`
                  }
                }).then((response: any) => {
                  if (response && response.data && response.data.RedirectURL) {
                    this.saveCheckoutData();
                    window.location.href = response.data.RedirectURL;
                  }

                  this.isLoading = false;
                });
              } else {
                this.isLoading = false;
              }
            });
          }
        }

        saveCheckoutData() {
          SessionStorage.add('checkMandate', true);
          Events.$emit('saveCheckoutData');
          SessionStorage.addObject('investmentPayload', this.payloadPart);
          SessionStorage.add('activeBankAccount', this.activeBankAccount);

        }

        checkMandate(bankAccountId) {
          const token = localStorage.getItem('authToken');
          if (token) {
            this.shouldCreateMandateFor = null;
            this.isLoading = true;
            axios.get(`https://api.emoney.exporo.com/prod/bank-accounts/${bankAccountId}/mandates?sort=creationdate:desc&per_page=1`, {
              headers: {
                'Authorization': `Bearer ${JSON.parse(token)?.idToken}`
              }
            }).then((response: any) => {
              if (response.data && response.data.hasOwnProperty('length') && response.data.length === 0) {
                this.shouldCreateMandateFor = bankAccountId;
              } else if (response.data && response.data.hasOwnProperty('length') && response.data.length > 0) {
                this.payloadPart['mandate_id'] = null;

                response.data.forEach((mandate: any) => {
                  if (mandate.Status === 'ACTIVE' || mandate.Status === 'SUBMITTED') {
                    this.payloadPart['mandate_id'] = mandate.Id;
                    this.payloadPart.debitMandate = true;
                  } else {
                    this.existMandateId = mandate.Id;
                  }
                });

                if (!this.payloadPart['mandate_id']) {
                  this.shouldCreateMandateFor = bankAccountId;
                }
              }

              this.isLoading = false;
            });
          }
        }

        getListOfBankAccounts() {
          const token = localStorage.getItem('authToken');
          if (token) {
            axios.get('https://api.emoney.exporo.com/prod/bank-account?per_page=10&page=1&sort=creationdate:desc', {
              headers: {
                'Authorization': `Bearer ${JSON.parse(token)?.idToken}`
              }
            }).then((response: any) => {
              this.bankAccounts = response.data as BankAccount[];
            });
          }
        }

        onChangeIbanOrBic(ibanBic: { iban: string, bic: string }) {
            this.payloadPart.invest_iban = ibanBic.iban;
            this.payloadPart.invest_bic = ibanBic.bic;
            this.$forceUpdate();
        }

        updateInvestType() {
            this.investService.setInvestType(this.investtype);
            this.investService.updateFomData('investtype', this.investtype);
            this.investService.setInvestSum(this.pieceValue * this.pieces);
        }

        onActionCodeChanged(values: ActionCodeUserDetails) {
            this.payloadPart.action_code = values.code;
            this.payloadPart.action_code_confirm = values.confirmed;
            this.payloadPart.action_code_reuse_iban = values.reuseIban;
            this.payloadPart.action_code_iban = values.iban ? values.iban : '';
            this.payloadPart.action_code_bic = values.bic ? values.bic : '';
        }

        @Watch('debitMandate')
        onDebitMandateChanged() {
            this.investService.updateFomData('debitMandate', this.debitMandate);
            this.payloadPart.debitMandate = this.debitMandate;
        }

        @Watch('investtype')
        onTypeChanged(val: string, oldVal: string) {
            this.payloadPart.investtype = this.investtype;
            this.updateInvestType();

            if (this.investtype === 'prepay' && this.shouldCreateMandateFor) {
              this.shouldCreateMandateFor = false;
            } else if (this.investtype === 'debit' && this.activeBankAccount) {
              this.checkMandate(this.activeBankAccount);
            }
        }

        @Watch('vibFile')
        onVibChanged(val: string, oldVal: string) {
            this.payloadPart.vib_file = val;
            this.investService.updateFomData('vib_file', val);
        }

        @Watch('pieces')
        onPiecesChanged(val: string, oldVal: string) {

            if (Number(val) > this.availablePieces && Number(oldVal) > 1) {
                this.pieces = this.availablePieces;
            }
            this.investsumText = this.pieces < 1 ? '1.000' : this.formatPrice(this.pieceValue * this.pieces);

            this.investService.setInvestSum(this.pieceValue * this.pieces);
            this.investService.updateFomData('pieces', this.pieces);
            this.payloadPart.pieces = this.pieces;
        }

        private debitEnabled: boolean = true;

        toggleDebit(enabled: boolean) {
          this.debitEnabled = enabled;

          if (!enabled) {
            this.payloadPart.investtype = 'prepay';
            this.investtype = 'prepay';
          }

          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }

        onBlur() {
            if (!this.pieces || 0 >= this.pieces) {
                this.pieces = 1;
            }

            if (this.pieces < this.contract.min_investment_sum / this.pieceValue) {
              this.pieces = this.contract.min_investment_sum / this.pieceValue;
            }
        }

        formatPrice(value: number): string {

            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }

        getRequestPart(): object {

            return this.payloadPart;
        }
    }

    export default InvestmentWpHGLite;
