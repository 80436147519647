













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ForceEmailService from './services/ForceEmailService';
import RequestCallback from '../../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import Events from '../../../events';
import InvestService from '../services/InvestService';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import LegalMailRequestApi from '../../Rest/requests/LegalMailRequestApi';
import ExporoVue from '../../ExporoVue';

@Component({
    components: {
        Checkbox,
    },
})
class ForceEmailCheckbox extends ExporoVue implements RequestCallback {

    @Prop({default: 'force-email-checkbox'})
    private name!: string;
    @Prop({default: null})
    private vibSent?: object;
    @Prop({default: false})
    private legalMailsAlreadySent!: boolean;
    @Prop({default: false})
    private checked!: boolean;
    @Prop()
    private emails!: string[];
    @Prop({default: ''})
    private email!: '';
    @Prop({default: 'force-email-checkbox'})
    private emailsFor!: string;
    private isChecked: boolean = false;
    private locked: boolean = false;
    @Prop({default: -1})
    private nodeId!: number;
    @Prop({default: true})
    private shouldSend!: boolean;
    @Prop()
    private investService!: InvestService;
    @Prop()
    private file!: string;
    @Prop({default: false})
    private isBondLight!: boolean;
    private legalMailRequestApi: LegalMailRequestApi;
    @Prop()
    private onChangeCallback!: (checked: boolean) => void;
    @Prop()
    private isBondTypeBlockchain!: boolean;

    @Watch('isChecked')
    onChange() {
      this.toggleEmailCheck();
    }

    toggleEmailCheck(sendEmail: boolean = true) {
      if (this.onChangeCallback) {
        this.onChangeCallback(this.isChecked);
      }

      if (this.investService) {
        this.investService.updateFomData(this.name, this.isChecked);
      }

      if (!this.isReceiverComponent() && this.isChecked && !this.locked) {
        const files = JSON.stringify(this.forceEmailService.getFiles());

        if (this.shouldSend && sendEmail) {
          if (this.isBondLight) {
            this.legalMailRequestApi.sendBondLightLegalMail({
              files: files,
              contract_id: this.nodeId,
            }, this);
          } else {
            this.legalMailRequestApi.sendLegalMail({
              files: files,
              nodeId: this.nodeId,
            }, this);
          }
        } else {
          this.forceEmailService.addEmail(this.name);
          Events.$emit('checkEmails');
        }

        this.locked = true;
      }
    }

    private emailReceived: boolean = false;

    private forceEmailService!: ForceEmailService;

    constructor() {
        super();

        this.legalMailRequestApi = this.restApi.create(new LegalMailRequestApi());
    }

    mounted() {
        if (this.checked) {
          this.toggleEmailCheck(false);
        }

        this.emailReceived = null !== this.vibSent || this.legalMailsAlreadySent;
        this.forceEmailService = new ForceEmailService(this.emailReceived, this.emails, this.nodeId);

        if (this.isReceiverComponent() && 0 === this.forceEmailService.getFiles().length) {

            this.emailReceived = true;
        }

        this.checkEmails();

        Events.$on('checkEmails', () => {
            this.checkEmails();
        });

        if (this.file) {
            this.forceEmailService.addFile(this.file);
        }
    }

    data() {
        return {
            emailReceived: false,
        };
    }

    shouldDisable() {

        return this.isReceiverComponent() && !this.emailReceived;
    }

    isReceiverComponent() {

        return this.emails && this.emails.length > 0;
    }

    checkEmails() {
        if (this.emails) {
            this.emailReceived = this.forceEmailService.isAllEmailsSent();
        }
    }

    onFailure(error: any): void {
        this.locked = false;
        this.forceEmailService.addEmail(this.name);
        Events.$emit('checkEmails');
    }

    onSuccess(response: AxiosResponse): void {
        // if (response.data.success) {
        //     this.forceEmailService.addEmail(this.name);
        // } else {
        //     //@TODO should not an error
        //     if ('Files already sent' === response.data.message) {
        //         this.forceEmailService.addEmail(this.name);
        //     }
        // }

        this.forceEmailService.addEmail(this.name);

        Events.$emit('checkEmails');
    }
}

export default ForceEmailCheckbox;
