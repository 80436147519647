






























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import URLHelper from '../Helper/URLHelper';
import CookieHelper from '../Helper/CookieHelper';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import Controller from '@/components/Router/Controller';
import ExporoTokenService from '@/components/JWT/services/ExporoTokenService';

@Component({
    components: {
        ExporoModal,
        Toggle
    }
})
class QuerySettings extends ExporoVue {

    private savedSettings: any = null;

    constructor() {
        super();
    }

    onClose() {
        window.location.href = window.location.href.split('?')[0];
    }

    onToggle(isChecked: boolean, name: string) {

        if ('speec' === name) {

            if (isChecked) {
                navigator.permissions.query({ name: 'microphone' }).then((permissionStatus: any) => {

                    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
                    const speechRecognition = new SpeechRecognition();

                    if ('granted' !== permissionStatus.state) {
                        this.savedSettings.speec = false;
                        speechRecognition.start();
                    }

                    permissionStatus.onchange = (event: any) => {
                        this.savedSettings.speec = 'granted' === event.target.state;

                        LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, this.savedSettings.speec);
                        speechRecognition.stop();
                    };
                });
            } else {
                LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, isChecked);
            }
        }

        if ('spinner' === name) {
            LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, isChecked);
        }
    }

    mounted() {
        const jwtRedirect = URLHelper.getQueryParam('jwtRedirect');

        if (jwtRedirect) {
            const tokenService = new ExporoTokenService();
            tokenService.fetchToken((token: string) => {
                if (token) {
                    window.location.href = `${jwtRedirect}?jwtToken=${token}`;
                }
            });
        }

        const aaid = URLHelper.getQueryParam('a_aid');
        if (aaid && null === CookieHelper.getCookie('affiliated')) {
            CookieHelper.setCookie('affiliated', aaid);
        }

        const a_aid_ref = URLHelper.getQueryParam('a_aid_ref');
        if (a_aid_ref && null === CookieHelper.getCookie('a_aid_ref')) {
            CookieHelper.setCookie('a_aid_ref', a_aid_ref);
        }

        const isPortfolioFeature = URLHelper.hasQueryParam('portfolio-v2');
        if (isPortfolioFeature && null === CookieHelper.getCookie('portfolio-v2')) {
            CookieHelper.setCookie('portfolio-v2', 'true');
        }

        const cockpitSettings = URLHelper.getQueryParam('cockpit');

        if (cockpitSettings && 'v2' === cockpitSettings) {
            CookieHelper.setCookie('exporo-cockpit-version', cockpitSettings);
        } else if (cockpitSettings && 'v2' !== cockpitSettings) {
            CookieHelper.setCookie('exporo-cockpit-version', 'v1');
        }

        const cockpitUserId = URLHelper.getQueryParam('userid');

        if (cockpitUserId) {
            LocalStorage.add(ExporoVue.USER_ID_KEY, cockpitUserId);
        }

        const debug = URLHelper.getQueryParam('debug');

        if (debug) {
            LocalStorage.addBoolean(ExporoVue.ACTIVATE_LOGS_KEY, '1' === debug);
        }

        const hasExperimentalFeatures = URLHelper.hasQueryParam('experimental-features');

        if (hasExperimentalFeatures) {

            const useSpinner = LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, false);
            const useSpeec = LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, false);

            this.savedSettings = {
                speec: useSpeec,
                spinner: useSpinner
            };

            this.getModalService().openModal('experimental-features', {
                height: 'auto',
                width: '600px',
                top: '10%'
            });
        }

    }
}

export default QuerySettings;
