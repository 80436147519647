









































import {Component, Prop, Vue} from 'vue-property-decorator';
import AuthService from '@/components/Auth/services/AuthService';
import Login from '@/components/Auth/Login.vue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import Register from '@/components/Auth/Register.vue';
import PasswordForget from '@/components/Auth/PasswordForget.vue';
import PasswordReset from '@/components/Auth/PasswordReset.vue';
import Activation from '@/components/Auth/Activation.vue';
import RestApiService from '../Rest/services/RestApiService';
import index, {LOGOUT} from '../../store';
import ExporoVue from '../ExporoVue';
import CookieHelper from '../Helper/CookieHelper';
import URLHelper from '../Helper/URLHelper';
import QuerySettings from '@/components/QuerySettings/QuerySettings.vue';
import Controller from '@/components/Router/Controller';
import {Action} from '@/components/Router/decorators/RouteDecorator';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import RequestCallback from '@/components/Rest/RequestCallback';


export const LOGIN_MODAL_NAME = 'login-modal';
export const REGISTER_MODAL_NAME = 'register-modal';
export const PASSWORD_FORGET_MODAL_NAME = 'password-forget-modal';
export const PASSWORD_RESET_MODAL_NAME = 'password-reset-modal';
export const ACTIVATION_MODAL_NAME = 'activation-modal';

@Component({
    store: index,
    components: {
        Activation,
        PasswordForget,
        PasswordReset,
        Register,
        Login,
        ExporoModal,
        QuerySettings,
    },
})
export default class AuthModal extends Controller implements RequestCallback {

    public html: any;
    private lastModalName: string = LOGIN_MODAL_NAME;
    private authService: AuthService;

    @Prop({default: ''})
    private dataClass!: string;

    @Prop({default: ''})
    private dataStyle!: string;

    constructor() {
        super('AuthModal');

        const api = new RestApiService(this);
        this.authService = new AuthService(api);
    }

    onSuccess(response: any, id?: string) {
    }

    onFailure(error: any, id?: string) {
    }

  getName() {
        return 'AuthModal';
    }

    mounted() {
        const modalName = URLHelper.getQueryParam('open-modal');

        if (modalName) {
            this.getModalService().openModal(modalName, {
                height: 'auto',
                width: '600px',
                top: '10%',
            });
        }

        if (document.location.href.indexOf('activation/') > -1) {
            const urlPart = document.location.href.split('activation/')[1];
            const id = urlPart.split('&')[0];
            this.openAuthModalByName(ACTIVATION_MODAL_NAME, {
                'activationID': id,
            });
        }

        if (document.location.href.indexOf('password/reset/') > -1) {
            const urlPart = document.location.href.split('password/reset/')[1];
            const id = urlPart.split('&')[0];

            this.openAuthModalByName(PASSWORD_RESET_MODAL_NAME, {
                'passwordResetToken': id,
            });
        }
    }

    @Action('openActivation')
    openActivationAction(token) {
        LocalStorage.add(ExporoVue.ACTIVATION_ID, token);
        this.openAuthModalByName(ACTIVATION_MODAL_NAME, {
            'activationID': token,
        });
        Tracking.trackOpen('activation overlay', false, 'activation form');

    }

    getModalNames() {
        return {
            LOGIN_MODAL_NAME,
            REGISTER_MODAL_NAME,
            PASSWORD_FORGET_MODAL_NAME,
            PASSWORD_RESET_MODAL_NAME,
            ACTIVATION_MODAL_NAME,
        };
    }

    isLoggedIn(): boolean {

        return this.authService.isLoggedIn();
    }

    openAuthModalByName(name: string, data = {}) {
        this.getModalService().closeModal(this.lastModalName, {}, true);

        this.getModalService().openModal(name, {
            height: 'auto',
            width: '600px',
            top: '10%',
            data: data,
        });

        this.lastModalName = name;
    }

    logout(): void {
        this.$store.commit(LOGOUT);
        this.authService.logout();
    }
}
