




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CheckoutVermAnlG from '@/components/Checkout/CheckoutVermAnlG.vue';
import CheckoutWpHGLite from '@/components/Checkout/CheckoutWpHGLite.vue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import ExporoVue from '@/components/ExporoVue';
import Router from '@/components/Router/Router';
import {Action} from '@/components/Router/decorators/RouteDecorator';
import Controller from '@/components/Router/Controller';
import Events from '@/events';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import AuthService from '@/components/Auth/services/AuthService';
import RestApiService, {BASE_TOKEN} from '@/components/Rest/services/RestApiService';
import RequestCallback from '@/components/Rest/RequestCallback';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {LOGIN, LOGIN_FAILED, LOGIN_SUCCESS} from '@/store/index';
import UserEdit from '@/components/User/Edit/UserEdit.vue';
import {resumeSession} from '@/components/Checkout/Auth';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import URLHelper from '@/components/Helper/URLHelper';
import CookieHelper from '@/components/Helper/CookieHelper';

@Component({
  components: {
    CheckoutVermAnlG,
    CheckoutWpHGLite,
    ExporoModal,
    UserEdit
  }
})
class Checkout extends Controller implements RequestCallback {

  private type: any = null;
  private id: any = null;
  private referrer: any = null;
  private restApiService: RestApiService;
  private authService: AuthService;
  private isLoggedIn: boolean = false;
  private loginStateFetched: boolean = false;
  private userDataComplete: boolean = true;
  private defaultConfig: object = {
    withCredentials: true,
    maxRedirects: 0,
  };

  private step = '1';

  constructor() {
    super('Checkout');

    this.restApiService = new RestApiService(this);
    this.authService = new AuthService(this.restApiService);
  }

  onSuccess() {

  }

  onFailure() {

  }

  closeCheckout() {
    if (this.referrer) {
      window.location.href = this.referrer;
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  mounted() {
    this.step = sessionStorage.getItem('currentStep') || '1';

    Events.$on('editUserData', () => {

      if (LocalStorage.getBoolean('userEditMode', false)) {
        const currentStep = window.location.href.split('checkout/step/')[1];

        if (currentStep) {
          sessionStorage.setItem('currentStep', currentStep);
          // LocalStorage.add('currentStep', currentStep);
        }
      }

      this.userDataComplete = false;
    });

    Events.$on('editUserDataComplete', () => {
      this.$nextTick(() => {
        this.userDataComplete = true;
        window.location.reload();
      });
    });

    window.addEventListener('message', async (event) => {
      const postedMessage = (typeof event.data === 'string' && this.isJsonString(event.data)) ? JSON.parse(event.data) : event.data;
      if (!!postedMessage.type && postedMessage.type.includes('sso')) {
        localStorage.removeItem('authToken');

        if (postedMessage.payload.error) {
          window.location.href = `https://user.exporo.de/#/signin?redirectURL=${encodeURIComponent(window.location.href)}`;
          // window.location.href = `https://dev.user.exporo.de/master/#/signin?redirectURL=${encodeURIComponent(window.location.href)}`;
        } else if (postedMessage.payload.refreshToken) {
          await resumeSession(postedMessage.payload.refreshToken);
          const token = localStorage.getItem('authToken');
          if (token) {
            const onboardingUrl = 'https://a8ih7lfei9.execute-api.eu-central-1.amazonaws.com/production/onboarding?type=wphgVermittlungSTO&domain=user.exporo'; // live
            // const onboardingUrl = 'https://8vwwrv9nxl.execute-api.eu-central-1.amazonaws.com/master/onboarding?type=wphgVermittlungSTO&domain=user.exporo'; // dev
            axios.request({
              url: onboardingUrl,
              method: 'get',
              headers: {
                'Authorization': 'Bearer ' + JSON.parse(token)?.idToken
              },
            }).then((onboardingResponse: any) => {
              if (onboardingResponse && onboardingResponse.data) {
                if (onboardingResponse.data.valid) {
                  this.init();
                } else {
                  window.location.href = `https://user.exporo.de/#/onboarding/?successUrl=${encodeURIComponent(window.location.href)}`;
                }
              }
            });
          }
        }
      }
    });
  }

  init() {
    this.getStore().commit(LOGIN);

    axios.request(this.createConfig()).then((response: any) => {
      if (response.data && response.data.userId) {
        // Track user object to GTM with Affiliate Partner ID from user service metadata call
        const recommendFrom = URLHelper.getQueryParam('a_aid') || response.data.affiliated_partner_id || null;

        Tracking.trackUser(response.data.userId, recommendFrom || '0');

        this.getStore().commit(LOGIN_SUCCESS);
        this.isLoggedIn = true;
        this.loginStateFetched = true;

        LocalStorage.add(ExporoVue.USER_ID_KEY, String(response.data.userId));
        setTimeout(() => Events.$emit('userIdLoaded'), 500);

        this.getCheckout();
      }
    }).catch((error: AxiosError) => {
      this.getStore().commit(LOGIN_FAILED);

      if (error.response && error.response.data && error.response.data.errorCode) {
        if (error.response.data.errorCode === 3) {
          this.isLoggedIn = false;
          this.loginStateFetched = true;
          window.location.href = `https://user.exporo.com/#/signin?redirectURL=${encodeURIComponent(window.location.href)}`;
        }
      }
    });
  }

  createConfig() {
    let config: AxiosRequestConfig = {
      url: 'https://backend.exporo.de/api/user/authenticated',
      method: 'get',
      headers: {
        'x-api-access-key': BASE_TOKEN,
        'x-requested-with': 'XMLHttpRequest',
        'content-type': 'application/x-www-form-urlencoded'
      },
    };

    config = {...config, ...this.defaultConfig} as AxiosRequestConfig;

    return config;
  }

  createUserMetadataConfig() {
    const token = localStorage.getItem('authToken');
    if (!token) return {};
    let config: AxiosRequestConfig = {
      url: 'https://api.user.exporo.com/prod/user/metaData',
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(token)?.idToken
      },
    };

    config = {...config} as AxiosRequestConfig;

    return config;
  }

  private splitURL() {
    const splitReferrer = window.location.href.split('?referrer=');

    if (splitReferrer[1]) {
      this.referrer = splitReferrer[1];
      window.location.href = splitReferrer[0];
    }

    Events.$on('backToExporo', () => {
      this.closeCheckout();
    });

    const hash: string = window.location.hash;

    if (hash) {
      this.type = hash.indexOf('bond') > -1 ? 'bond' : hash.indexOf('loan') > -1 ? 'loan' : 'checkout';
      this.id = hash.split(this.type)[1].split('/')[1];
    }

    return hash;
  }

  getCheckout() {
    const hash = this.splitURL();

    if ('checkout' === this.type || !hash) {
      this.$nextTick(() => {
        if (sessionStorage.getItem('checkoutURL')) {
          const referrer = sessionStorage.getItem('referrer');
          let redirectUrl: string = sessionStorage.getItem('checkoutURL') || '';

          if (referrer !== null) {
            redirectUrl += '?referrer=' + referrer;
          }

          window.location.href = redirectUrl;

          if (window.location.href === redirectUrl) {
            window.location.reload();
          }
        }
      });
    } else if ('loan' === this.type || 'bond' === this.type) {
      let origin = window.location.origin;

      if (!origin) {
        origin = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
      }

      if (this.referrer) {
        sessionStorage.setItem('referrer', this.referrer);
      }
      sessionStorage.setItem('checkoutURL', `${origin}/#/${this.type}/${this.id}`);
      // LocalStorage.add('referrer', this.referrer);
      // LocalStorage.add('checkoutURL', `${origin}/#/${this.type}/${this.id}`);

      this.$nextTick(() => {
        sessionStorage.setItem('currentStep', '1');
        // LocalStorage.add('currentStep', '1');
        Router.navigate('switchCheckoutStep', [this.step]);

        this.getModalService().openModal('checkout');
      });
    }
  }

  getName() {

    return this.getClassName() || 'Checkout';
  }


  @Action('openCheckoutBond')
  openCheckoutBond(id: any) {

  }
}

export default Checkout;
