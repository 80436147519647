/* tslint:disable */

const nl = {
    auth: {
        activation: {
            affiliated_partner_id: 'Aanbevolen door (optioneel)',
            error: 'Voer a.u.b. een geldige partner-ID in.',
            female: 'Vrouw',
            first_name: 'Voornaam',
            format_phone: 'Format: +31 (0)20 1234 567',
            format_mobile_phone: 'Format: +31 (0)6 1234 5678',
            last_name: 'Achternaam',
            male: 'Heer',
            password: 'Wachtwoord',
            password_lacks_complexity: 'Het wachtwoord moet minstens 8 tekens en een hoofdletter, een kleine letter en een cijfer of een speciaal teken bevatten.',
            phone: 'Telefoonnr.',
            phone_error: 'Vul a.u.b. het veld \'telefoonnr.\' in.',
            phone_mobile: 'Mobile telefoonnr.',
            phone_mobile_error: "Vul a.u.b. het veld 'mobiele telefoonnr.' in.",
            repeat_password: 'Herhaal het wachtwoord',
            save: 'Opslaan',
            title: 'Aanmelding aanvullen',
            toast_error: 'U gebruikt een foutieve activeringslink.'
        },
        cockpitButton: 'Investeringscockpit',
        loginButton: 'Aanmelden',
        logoutButton: 'Afmelden',
        modal: {
            checkbox_exporo_ag: (href1: string, href2: string, href3: string) => `Hierbij sta ik toe om mij informatie over kapitaalinvesteringen, project-updates en platformrelevante informatie via een nieuwsbrief te sturen. Deze vrijwillige <a href="${href1}" target="_blank">toestemming</a> kan ik op elk moment herroepen. Bovendien ga ik akkoord met de <a href="${href2}" target="_blank">Algemene Voorwaarden</a> en de <a href="${href3}" target="_blank">privacy-verklaring van Exporo AG</a>.`,
            checkbox_exporo_investment_gmbh: (href1: string, href2: string, href3: string) => `Hierbij sta ik toe om mij informatie over kapitaalinvesteringen, project-updates en platformrelevante informatie via een nieuwsbrief te sturen. Deze vrijwillige <a href="${href1}" target="_blank">toestemming</a> kan ik op elk moment herroepen. Bovendien ga ik akkoord met de <a href="${href2}" target="_blank">Algemene Voorwaarden</a> en de <a href="${href3}" target="_blank">privacy-verklaring van Exporo GmbH</a>.`,
            email_placeholder: 'E-mail-adres',
            error_password: 'Controleer a.u.b. of de ingevoerde aanmeldgegevens juist zijn.',
            password_forgot: 'Wachtwoord vergeten?',
            password_placeholder: 'Wachtwoord',
            register_checkbox_pre_hint: (CCTLD: string) => `Door registratie op Exporo. ${CCTLD} verklaar ik mij akkoord:`,
            register_checkbox_suf_hint_1: 'Ik ga akkoord met de bepalingen ten aanzien van cookies & Internet-reclame.',
            register_checkbox_suf_hint_2: 'Het beheer van de persoonsgegevens gebeurt voortaan door Exporo AG.',
            remember_me: 'Op deze computer aangemeld blijven.',
            submits: {
                login: 'Aanmelden',
                register: 'Registreren'
            },
            success_text: `<h1>BIJNA KLAAR</h1> <p class=\"description\">Hartelijk dank voor uw belangstelling. U ontvangt binnenkort een e-mail van Exporo. Klik a.u.b. op de link in die mail om de registratie te voltooien.</p>`,
            tabs: {
                login: 'Aanmelden',
                register: 'Registreren'
            }
        },
        passwordChange: {
            current_password_placeholder: 'Huidig wachtwoord',
            error_messages: {
                current_password_check_failed: 'Vul uw huidige wachtwoord in.',
                new_password_confirmation_failed: 'De nieuwe wachtwoorden komen niet overeen',
                password_lacks_complexity: 'Het wachtwoord moet uit minimaal 8 tekens, een hoofdletter, een kleine letter en een cijfer of speciaal teken bestaan.',
                password_used_before: 'Het zojuist ingevoerde wachtwoord heeft u al eerder gebruikt. Kies een nieuw wachtwoord'
            },
            new_password_hint: `<p> Gebruik a.u.b. niet reeds gebruikt watchwoord. Uw watchwoord moet tenminste 8 tekens lang zijn en voldoen aan de volgende criteria:
                                </p>
                          
                                <ul>
                                    <li>een hoofdletter</li>
                                    <li><b>en</b> een kleine letter</li>
                                    <li><b>en</b> een getal</li>
                                    <li><b>een</b> speciaal teken</li>
                                </ul>
                                <p>
                                    Vermijd gemakkelijk te raden watchwoorden zoals namen van familieleden, geboortedatum en/of adresgegevens.
                                </p>`,
            new_password_placeholder: 'Nieuw wachtwoord',
            repeat_password_placeholder: 'Bevestig nieuw wachtwoord',
            submit: 'Wachtwoord wijzigen',
            title: 'Wachtwoord wijzigen'
        },
        passwordForget: {
            login: 'Aanmelden?',
            submit: 'Verzenden',
            title: 'Wachtwoord resetten'
        },
        passwordReset: {
            new_password_hint: `<p> Gebruik a.u.b. geen eerder gebruikt wachtwoord.
                                    <br> Uw wachtwoord moet tenminste 8 tekens lang zijn en minimaal bevatten:
                                    </p>
                                    
                                    <ul>
                                        <li>een hoofdletter</li>
                                        <li><b>en</b> een kleine letter</li>
                                        <li><b>en</b> een getal <b>of</b> een speciaal teken</li>
                                    </ul>
                                    <p>
                                        Vermijd gemakkelijk te raden bestanddelen zoals namen van gezinsleden en geboorte- of adresgegevens.
                                 </p>`,
            password_hint: `<p> Ne pas utiliser un mot de passe que vous avez déjà utilisé dans le passé. <br> Votre mot de passe doit être au minimum de 8 caractères et doit contenir :</p><ul><li>une majuscule</li><li><b>et </b>une minuscule</li><li><b>et </b>un chiffre <b>ou</b>un caractère spécial</li></ul><p>Evitez les mot de passe faciles à deviner comme les noms et données des membres de famille (dates de naissances) ou adresse.</p>`,
            password_label: 'Wachtwoord',
            repeat_password_label: 'Herhaal het wachtwoord',
            submit: 'Verzenden',
            success: 'Wachtwoord is met succes gewijzigd.',
            title: 'Wachtwoord opslaan'
        },
        submit_feedback: {
            label_submit: 'Rapport verzenden',
            subtitle: 'Er wordt een rapport met alle benodigde gegevens aan ons gestuurd.',
            success_message: 'We hebben uw melding van het probleem ontvangen, hartelijke dank voor uw feedback',
            title: 'Vertel ons a.u.b. wat het probleem is.'
        }
    },
    basic: {
        no: 'Nee',
        yes: 'Ja',
        day: 'Dag',
        month: 'Maand',
        year: 'Jaar'
    },
    checkout: {
        action_code: {
            error_message: 'Uw invoer kon niet worden verwerkt. Probeer het a.u.b. opnieuw.',
            error_messages: {
                'action_code_is_not_coupon': 'Deze actiecode is niet meer geldig. Neem voor vragen a.u.b. contact met ons via <a href="tel:+4940210917300">0049 40 210 91 73 00</a> of via e-mail naar: <a href="mailto:support@exporo.com">support@exporo.com</a>.',
                'action_code_max_per_user': 'U heeft deze actiecode al een keer gebruikt.',
                action_code_min_investsum: (value: string) => `Deze code is geldig voor een minimaal investeringsbedrag van ${value} euro.`,
                'action_code_not_expired': 'De actiecode is niet meer geldig.',
                'action_code_not_found': 'De actiecode bestaat niet.',
                'action_code_only_first_signing': 'De actiecode geldt alleen voor de eerste investering.',
                'action_code_only_follow_up_signing': 'De actiecode geldt alleen voor een volgende investering.',
                'action_code_only_project': 'De actiecode kan voor dit project niet gebruikt worden.',
                'action_code_product_type': 'De actiecode kan voor dit project niet gebruikt worden.'
            },
            iban_alert: 'Voor betaling van de actiecode is een IBAN nodig:',
            iban_input: 'IBAN voor actiecodes',
            label: 'Actiecode invoeren',
            not_reuse_iban: 'U kunt een andere IBAN invoeren voor betaling van de actiecode:',
            placeholder: 'Actiecode',
            reuse_iban: 'Gebruik dezelfde IBAN',
            title: 'Uw actiecode'
        },
        bond_store_success: {
            description: `<p>Om de wettelijke eisen ten aanzien van bestrijding van witwassen na te leven, zijn wij verplicht om uw identiteit te controleren. Deze procedure is uitsluitend van toepassing bij uw eerste belegging in een verstrekte lening via Exporo.</p> <p>Hiervoor werken we samen met IDnow, waardoor u zich via video-ident kunt identificeren. Deze service is voor u eenvoudig, veilig en gratis.</p>`,
            headline: 'Nog slechts één stap!',
            host_button: 'Naar mijn investeringscockpit',
            ident_button: 'Nu identificeren',
            ident_content: 'Om de wettelijke eisen ten aanzien van bestrijding van witwassen na te leven, zijn wij verplicht om uw identiteit te controleren. Deze procedure is uitsluitend van toepassing bij uw eerste belegging in een verstrekte lening via Exporo en is voor u eenvoudig, veilig en gratis.',
            ident_problem: 'Hebt u problemen met het identificeren? Neem contact met ons op via het e-mailadres investment@exporo.nl of bel van ma t/m vr, tussen 10 en 17 uur via telefoonnummer +31 (0)20 8905 597.',
            ident_suggestion: 'NB: houd uw legitimatiebewijs bij de hand.',
            salutation_female: 'Beste mevrouw',
            salutation_male: 'Beste heer',
            show_projects: 'Andere proejcten weergeven',
            subline: (title: string, name: string) => `<p class="subline">${title} ${name}, van harte gefeliciteerd.<br>U hebt u aanbod tot intekenen met succes afgegeven!</p>`,
            suggestion: 'NB: houd uw legitimatiebewijs bij de hand.'
        },
        coupon: {
            confirmation: 'Bevestigen',
            label: 'Aanvullende belegging:'
        },
        employment_status: {
            branche: {
                self_employed_occupations: {
                    bergbau: 'Mijnbouw',
                    dienstleist_sport_unterhaltung: 'Dienstverlening - sport, entertainment',
                    einzelhandel: 'Detailhandel',
                    energieversorgung: 'Energievoorziening',
                    erziehung_u_unterricht: 'Onderwijs',
                    freiberuf_wissenschaftl_taetigkeiten: 'free-lance wetenschappelijke activiteiten',
                    gastronomie: 'Horeca',
                    gesundheitswesen: 'Gezondheidszorg',
                    glasswaren: 'Glasartikelen',
                    grundstuecks_u_wohungswesen: 'Makelaardij o.g.',
                    handel_mit_kfz_u_service: 'Handel in motorvoertuigen en service',
                    hochbau_bau_von_gebaeuden: 'Bovengrondse bouwwerken, bouw van gebouwen',
                    informationsdienstleistungen: 'Informatiedienstverlening',
                    kraftfahrzeug_branche: 'Automobielsector',
                    kuenstlerische_taetigkeiten: 'Artistieke activiteiten',
                    landwirtschaft: 'Agrarische sector',
                    oeffentliche_verwaltung: 'Overheid',
                    verkehrsdienstleistungen: 'Verkeersdienstverlening',
                    wasserversorgung_entsorgung: 'Watervoorziening en -afvoer',
                    wirtschaftliche_dienstleistungen: 'Economische dienstverlening'
                },
                title: 'Bedrijfstak'
            },
            employmentStatusOptions: {
                employee: 'Kantoormedewerker',
                pensioner: 'Gepensioneerd',
                privateer: 'Welgestelde privépersoon',
                public_official: 'Ambtenaar',
                self_employed: 'Zelfstandige',
                student: 'Student/volgt beroepsopleiding'
            },
            exploration_warning: 'We hebben van u onvoldoende informatie ontvangen om de gepastheid van de kapitaalinvestering te kunnen toetsen. Er bestaat daarom een risico dat u een voor u passende kapitaalinvestering verwerft, waarvan u de risico\'s niet kunt beoordelen.',
            job_category: 'Beroepsgroep',
            job_education: {
                current_job: {
                    data: {
                        asset_manager: 'Vermogensbeheerder',
                        bankangestellter: 'Bankmedewerker',
                        finanzberater: 'Financieel adviseur',
                        finanzmakler: 'Financieel intermediair',
                        finanzvermittler: 'Financieel intermediair',
                        handwerker: 'Ambachtsman/-vrouw',
                        investment_banker: 'Investeringsbankier',
                        kfm_angestellter: 'Commercieel medewerker',
                        leitende_funktion_controlling: 'Leidende functie bij controlling',
                        leitende_funktion_finanzabteilung: 'Leidende functie bij financiële afdeling',
                        rentner_pensionaer: 'Gepensioneerd',
                        sonstige: 'Overige beroepen buiten de financiële sector',
                        steuerberater: 'Belastingadviseur',
                        student_auszubildender: 'Student/volgt beroepsopleiding',
                        wirtschaftspruefer: 'Accountant'
                    },
                    label: 'Nu uitgeoefend beroep'
                },
                educational_achievement: {
                    data: {
                        fachhochschul_hochschulreife: 'Niveau om toegelaten te worden tot instelling hoger onderwijs / universiteit',
                        haupt_volks_realschulabschluss: 'Diploma voorgezet onderwijs / basisonderwijs / middelbaar onderwijs',
                        hochschulabschluss: 'Diploma hoger onderwijs / universiteit',
                        kaufmaennische_berufsausbildung: 'Commerciële beroepsopleiding',
                        keiner_der_genannten_abschluesse: 'geen van de vermelde diploma\'s',
                        promotion_habilitation: 'Doctoraat / postdoctoraat',
                        sonstige_berufsausbildung: 'Overige beroepsopleiding'
                    },
                    label: 'Hoogste opleidingsniveau'
                },
                past_job: {
                    data: {
                        asset_manager: 'Vermogensbeheerder',
                        bankangestellter: 'Bankmedewerker',
                        finanzberater: 'Financieel adviseur',
                        finanzmakler: 'Financieel intermediair',
                        finanzvermittler: 'Financieel intermediair',
                        handwerker: 'Ambachtsman/-vrouw',
                        investment_banker: 'Investeringsbankier',
                        kfm_angestellter: 'Commercieel medewerker',
                        leitende_funktion_controlling: 'Leidende functie bij controlling',
                        leitende_funktion_finanzabteilung: 'Leidende functie bij financiële afdeling',
                        rentner_pensionaer: 'Gepensioneerd',
                        sonstige: 'Overige beroepen buiten de financiële sector',
                        steuerberater: 'Belastingadviseur',
                        student_auszubildender: 'Student/volgt beroepsopleiding',
                        wirtschaftspruefer: 'Accountant'
                    },
                    label: 'Eerder uitgeoefend beroep'
                },
                title: 'Beroep & opleiding'
            }
        },
        investments: {
            bonds: 'Obligaties, certificaten',
            equity_interest: 'Deelnemingen in aandelenkapitaal (bijv. gesloten beleggingsfondsen)',
            how_long: {
                label: 'Hoe lang hebt u ervaring met dit soort beleggingen?',
                options: {
                    btw_3_5: '3 - 5 jaar',
                    more_than_5: 'meer dan 5 jaar',
                    upto_3: 'minder dan 3 jaar'
                }
            },
            how_many: {
                label: 'Hoeveel transacties geeft u gemiddeld per jaar in opdracht?',
                options: {
                    btw_5_10: '5 - 10',
                    more_than_10: 'Meer dan 10',
                    upto_5: 'Max. 5'
                }
            },
            how_much: {
                label: 'Wat was de gemiddelde hoogte van uw belegging tot nu toe?',
                options: {
                    more_than_10500: 'Meer dan € 10.500',
                    upto_10500: 'Tot € 10.500',
                    upto_2500: 'Tot € 2.500',
                    upto_5000: 'Tot € 5.000'
                }
            },
            knowledge: {
                label: 'Kennis aanwezig?',
                value_1: 'ja',
                value_2: 'nee'
            },
            open_stock: '”Open-end” fondsen',
            shares: 'Aandelen',
            subordinate_investments: 'Achtergestelde financiële beleggingen (bijv. achtergestelde lening met winstdeelneming)'
        },
        investor: {
            add_investor: 'Nog een belegger toevoegen',
            edit_user: 'Bewerken',
            information: ' (In behandeling)',
            title: 'Wie belegt er?'
        },
        modal_controls: {
            back: 'Achteruit',
            next: 'Verder'
        },
        VermAnlG: {
            exploration: {
                description: 'Als bemiddelaar van kapitaalbeleggingen zijn wij wettelijk verplicht om informatie te vragen over uw ervaring en kennis met betrekking tot transacties met bepaalde soorten financiële beleggingen en over uw opleiding en beroepsactiviteiten. U kunt de hierna volgende gegevens vrijwillig verstrekken; dit is in uw eigen belang.',
                description_hint_1: 'Wij zijn wettelijk ertoe verplicht om u erop te wijzen dat wij niet over voldoende informatie beschikken om te kunnen beoordelen of u over voldoende kennis en ervaring beschikt om de risico\'s van de beoogde transactie op gepaste wijze te kunnen beoordelen.',
                description_hint_2: 'Helaas hebt u de vragen niet of niet volledig ingevuld. Indien u doorgaat met het beleggingsproces en intekent op de kapitaalbelegging, wijzen we u er hierbij op dat we de wettelijk verplichte toetsing van de gepastheid niet hebben kunnen uitvoeren. Wij kunnen daarom niet beoordelen of de aangeboden kapitaalbelegging voor u gepast is met het oog op uw kennis en ervaring. Er bestaat daarom een risico dat u een niet voor u passende kapitaalinvestering verwerft, waarvan u de risico\'s niet kunt beoordelen.',
                not_perform: 'Ik wil de toetsing van de gepastheid niet laten uitvoeren.',
                perform: 'Ik wil de toetsing van de gepastheid laten uitvoeren.',
                title: 'Over welke ervaring en kennis als belegger beschikt u?'
            },
            headline: 'Belegging',
            investmentVermAnlG: {
                account_number: 'Bankrekening/bankcode',
                bank_account: 'Uw bankrekeninggegevens: ',
                data_should_be_saved: 'Sla mijn bankrekeninggegevens gecodeerd op voor mijn volgende belegging.',
                debit: 'Automatische incasso',
                debit_mandate: 'Ik verleen machtiging aan secupay AG, Goethestr. 6, 01896 Pulsnitz, om eenmalig door middel van automatische incasso een bedrag van mijn bankrekening af te schrijven. Tegelijk geef ik mijn kredietinstelling opdracht om de automatische incasso van secupay AG ten laste van mijn bankrekening uit te voeren. Deze automatische incasso dient voor afschrijving ter hoogte van het hierboven geselecteerde beleggingsbedrag. Opmerking: ik kan binnen acht weken, gerekend vanaf de datum van afschrijving, terugbetaling van het afgeschreven bedrag eisen. Daarbij zijn de met mijn kredietinstelling overeengekomen voorwaarden van toepassing.',
                email_confirmation: 'De bankrekeninggegevens worden via e-mail aan u verzonden, samen met de contractdocumenten.',
                ibanbic: 'IBAN',
                investsum: 'Bedrag',
                invest_bic: 'Bankcode',
                invest_iban: 'Rekeningnummer',
                payment_method: 'Betalingswijze',
                prepay: 'Overschrijving',
                small_investor: 'Voor zover het totale bedrag van alle kapitaalbeleggingen die ik totnogtoe van de emittent heb verworven en de kapitaalbeleggingen die ik nu ter hoogte van mijn hiervoor vermelde beleggingsbedrag van dezelfde emittent verwerf, meer dan € 1.000 bedraagt, bevestig ik hierbij in het kader van een door mij in te vullen schriftelijke verklaring (i) dat dit totale bedrag niet meer is dan het dubbele van mijn gemiddelde maandelijkse netto inkomen en ook niet meer dan € 25.000 is of (ii) dat het totale bedrag meer dan € 10.000 is en dat ik beschik over een vrij beschikbaar vermogen in de vorm van banktegoeden en financiële middelen ter hoogte van tenminste € 100.000.',
                title: 'Hoeveel wilt u beleggen?',
                use_no_bank_account: 'Andere bankrekening gebruiken: '
            },
            store_success: {
                description: 'Om u de mogelijkheid te bieden om in de totale productreeks van de Exporo-groep te beleggen, adviseren wij u om zich vandaag nog persoonlijk te identificeren.',
                headline: 'Met succes belegd',
                ident_required_description: 'Zum Abschluss Ihres Investments ist eine Identifizierung nötig. Dies können Sie einfach und digital in wenigen Minuten über unseren Identifizierungsdienstleister tun. Klicken Sie dazu einfach auf diesen Link:',
                ident_button: 'Nu gratis identificeren'
            },
            subline: 'Beleg vandaag nog in het project: ',
            summary: {
                additional_interest: 'Bonusrente: ',
                description: {
                    hint: 'Opmerking',
                    text: 'De hierna vermelde gegevens zijn overgenomen uit uw eerder uitgevoerde registratie. Controleer a.u.b. of de gegevens juist zijn cq. pas deze a.u.b aan, waar nodig. Sluit de transactie, als alle gegevens juist zijn, door op "Nu beleggen met betalingsverplichting" te klikken.'
                },
                download: (item) => `${item} downloaden`,
                dsgvo_secupay_hint: 'Hierbij ga ik ermee akkoord dat mijn gegevens in het kader van het sluiten van een contract worden verwerkt en aan onze betalingsdienstaanbieder SECUPAY worden doorgegeven.\n',
                interest: 'Rente: ',
                investsum: 'Beleggingsbedrag: ',
                i_have_read_all_docs: 'Ik bevestig dat ik de brochure over kapitaalbeleggingen en de  wettelijk verplichte informatie via e-mail heb ontvangen, opgeslagen en gelezen en accepteer deze hierbij.',
                project: 'Project: '
            },
            VIB: {
                information: {
                    edit: 'Bewerken'
                },
                information_container: {
                    edit: 'Bewerken',
                    title: 'Uw gegevens'
                },
                information_right: {
                    born_at: 'geboren op '
                },
                info_text: 'Info: Na het aanvinken van de verklaringen worden de hierboven vermelde documenten via e-mail aan u gezonden.',
                legal_download: 'Wettelijk verplichte informatie downloaden',
                legal_obligation_text_a: 'Ik heb kennis genomen van de wettelijke waarschuwing in de brochure over kapitaalbeleggingen en bevestig dit door het invoeren van mijn gegevens. De wettelijk verplichte waarschuwing luidt als volgt: "Het verwerven van deze kapitaalbelegging gaat gepaard met aanzienlijke risico\'s en kan tot volledig verlies van het ingelegde kapitaal leiden.',
                legal_obligation_text_b: 'Ik heb de wettelijk verplichte informatie gelezen en geprint en/of opgeslagen.',
                signature: {
                    description: 'Vermeld a.u.b. als bevestiging uw volledige voor- en achternaam, de datum van vandaag en de plaats:',
                    example: 'Jan Jansen 01-01-2019 Utrecht',
                    placeholder: 'Voornaam achternaam datum plaats'
                },
                vib_download: 'Brochure over kapitaalbeleggingen downloaden',
                vib_text_a: 'Ik heb kennis genomen van de wettelijke waarschuwing in de brochure over kapitaalbeleggingen en bevestig dit door het invoeren van mijn gegevens. De wettelijk verplichte waarschuwing luidt als volgt: "Het verwerven van deze kapitaalbelegging gaat gepaard met aanzienlijke risico\'s en kan tot volledig verlies van het ingelegde kapitaal leiden.',
                vib_text_b: 'Ik heb kennis genomen van de wettelijk verplichte waarschuwing onder punt 1 in de brochure over kapitaalbeleggingen. De waarschuwing luidt als volgt: `Het verwerven van deze kapitaalbelegging gaat gepaard met aanzienlijke risico\'s en kan tot volledig verlies van het ingelegde kapitaal leiden.´ Door het aankruisen van deze bevestiging en door hierna mijn gegevens in te voeren, bevestig ik dat ik hiervan kennis heb genomen.'
            }
        },
        WpHGLite: {
            explorationWpHGLite: {
                availableInvestments: (availableInvestments: number) => `Er kan slechts op ${availableInvestments} leningen worden ingetekend.`,
                checkbox: 'Ik heb de informatie begrepen en wil toch intekenen',
                exploration_hint: {
                    state_0: 'We hebben van u onvoldoende informatie ontvangen om de gepastheid van de kapitaalbelegging te kunnen toetsen. Er bestaat daarom een risico dat u een niet voor u passende kapitaalbelegging verwerft, waarvan u de risico\'s niet kunt beoordelen.',
                    state_1: 'Hartelijke dank voor uw hiervoor vermelde gegevens. Op grond van deze gegevens hebben wij een toetsing van de gepastheid uitgevoerd. Indien u doorgaat met het beleggingsproces en intekent op de kapitaalbelegging, wijzen wij u er hierbij op dat uit de toetsing van de gepastheid gebleken is dat de aangeboden kapitaalbelegging met het oog op de meegedeelde kennis en ervaring niet voor u gepast is. Er bestaat daarom een risico dat u een niet voor u passende kapitaalbelegging verwerft, waarvan u de risico\'s niet kunt beoordelen.'
                },
                nextButtonText_1: 'Nu een depot openen en intekenbod afgeven',
                nextButtonText_2: 'Nu een intekenbod afgeven',
                nextButtonText_3: 'Nu een depot openen en intekenbod afgeven',
                no_availableInvestments: 'Er kan niet meer op een lening worden ingetekend.',
                subtitle: 'Wij, als Exporo Investment GmbH, zijn bemiddelaars van effecten en daarom zijn wij wettelijk verplicht om informatie te vragen over uw ervaring en kennis met betrekking tot transacties met bepaalde soorten financiële beleggingen en over uw opleiding en beroepsactiviteiten. Exporo Investment GmbH is uitsluitend als bemiddelaar actief en geeft geen beleggingsadvies. Daarom kan Exporo Investment GmbH niet beoordelen of de gepresenteerde kapitaalbeleggingen voldoen aan uw beleggingsdoeleinden. Onze klanten worden in principe als particuliere klanten geclassificeerd.',
                title: 'Over welke ervaring en kennis als belegger beschikt u?'
            },
            headline: 'VERPLICHTE GEGEVENS VOOR UW BELEGGING',
            investmentWpHGLite: {
                checkbox: {
                    church_tax_deduction: 'NOT TRANSLATED because this segment is not relevant outside Germany / Austria NOT TRANSLATED because this segment is not relevant outside Germany / Austria'
                },
                description: (formatPrice: any) => `Vermeld hier het door u gewenste aantal in. U beleggingsbedrag wordt automatisch berekend aan de hand van de nominale waarde van € ${formatPrice}:`,
                info_text: 'Info: na het aanvinken van de verklaringen over de prospectus over effecten en over bemiddelaarsinformatie worden deze beide documenten via e-mail aan u gezonden.',
                payout_form: {
                    investment_hint: {
                        hint_a: 'Voer a.u.b. uw IBAN in voor afschrijving via automatische incasso. Toekomstige uitkeringen van het rendement worden naar deze bankrekening overgemaakt.',
                        hint_b: 'Na acceptatie van uw opdracht tot intekenen worden de bankgegevens via e-mail aan u verzonden. Voer a.u.b. uw IBAN in voor toekomstige uitkeringen van het rendement.'
                    },
                    investtype: 'Betalingswijze',
                    label: 'Selecteer hier de door u gewenste betalingswijze:',
                    type_debit: 'Automatische incasso',
                    type_prepay: 'Overschrijving (betaling vooraf)'
                },
                pieces: 'stuks komt overeen met €',
                remaining_pieces: 'Resterend aantal: ',
                single_unit_threshold: 'Voor zover het totale bedrag van alle effecten die ik totnogtoe van de emittent heb verworven en de effecten die ik nu ter hoogte van mijn hiervoor vermelde beleggingsbedrag van dezelfde emittent verwerf, meer dan € 1.000 bedraagt, bevestig ik hierbij in het kader van een door mij in te vullen schriftelijke verklaring (i) dat dit totale bedrag niet meer is dan het dubbele van mijn gemiddelde netto inkomen en ook niet meer dan € 10.000 is of (ii) dat het totale bedrag meer dan € 10.000 is en dat ik beschik over een vrij beschikbaar vermogen in de vorm van banktegoeden en financiële middelen ter hoogte van tenminste € 100.000.',
                sum: ' beleggingsbedrag',
                title: 'Uw belegging'
            },
            no_available_pieces: 'Geen leningen meer beschikbaar!',
            subline: 'Beleg vandaag nog in het project:',
            summaryWpHGLite: {
                accept_to_save: 'Ik/wij bevestig(en) dat de volgende documentatie op een duurzame gegevensdrager (in de vorm van een PDF-bijlage via e-mail) voor mijn/onze administratie heb ontvangen:',
                description: 'De hierna vermelde gegevens zijn overgenomen uit uw eerder uitgevoerde registratie. Controleer a.u.b. of de gegevens juist zijn cq. pas deze a.u.b aan, waar nodig. Sluit de transactie, als alle gegevens juist zijn, door op "Nu beleggen met betalingsverplichting" te klikken.',
                information: {
                    headline: 'Investeerder',
                    user_edit: 'Gegevens bewerken'
                },
                information_container: {
                    information_right: 'Baader Bank (Depotbank)',
                    label: 'Exporo Investment GmbH (bemiddelaar)',
                    protocol: 'Verslag van de toetsing van de gepastheid',
                    title: 'Om te downloaden'
                },
                information_right: {
                    debit: 'Automatische incasso',
                    headline: 'Project:',
                    invest_sum: 'Beleggingsbedrag:',
                    isin: 'ISIN:',
                    payment_method: 'Betalingswijze:',
                    project_name: 'Projectnaam:',
                    transfer: 'Overschrijving',
                    wkn: 'WKN:'
                },
                label: 'Opmerking:',
                risk_hint: (email: string) => `Voordat u uw belegging afsluit, is het van belang dat u de aangeboden dienstverlening en de daarme gepaard gaande risico’s begrijpt. Hierna vindt u informatiemateriaal hierover, dat u kunt downloaden. Deze informatie wordt u ook via e-mail aan ${email} toegestuurd.`,
                title: 'Intekenen'
            }
        }
    },
    cockpit: {
        close_cockpit: 'Investeringscockpit verlaten',
        cockpitDropbar: {
            bond: 'Bezit',
            invest_plan: 'Beleggingsplan',
            mezz: 'Financiering'
        },
        cockpitPanel: {
            less: 'Minder',
            more: 'Meer'
        },
        cockpitSlider: {
            less: 'Minder',
            more: 'Meer'
        },
        cockpitTimeline: {
            title: 'Project-updates',
            subtitle: 'Onze updates voorzien u regelmatig van informatie over de voortgang van gefinancierde projecten. Klik op de betreffende update voor meer informatie.',
            no_update_hint: 'Onze E-Mail updates voorzien u regelmatig van informatie over de voortgang van gefinancierde projecten. De eerste updates volgen binnenkort.'
        },
        cockpit_loading_hint: 'Uw Investmentcockpit wordt geladen...',
        customer_nr: 'Klantnummer: ',
        detailPanel: {
            all_pieces_offered: 'Max. Producten inmiddels aangeboden',
            buy_pieces: 'Aandelen te koop aanbieden',
            reject_offer: 'Intrekking van het aanbod tot verkoop',
            label: 'Handelsplaats',
            not_yet_ready_to_trade: 'Projectfinanciering',
            offered_pieces: 'Aangeboden aantal',
            piece: 'Stuks'
        },
        detailView: {
            back_button: 'BACK'
        },
        ident: {
            cta: 'Nu identificeren',
            description: 'Om in de toekomst in Exporo-leningen te beleggen is een persoonlijke identificatie nodig.',
            question_hint: 'Hebt u nog vragen over de identificatie? Neem contact met ons op via het e-mailadres investment@exporo.nl of bel van ma t/m vr, tussen 10 en 17 uur via telefoonnummer +31 (0)20 8905 597.',
            states: {
                done: 'Geïdentificeerd',
                open: 'Open',
                running: 'In behandeling',
                start: 'Identificatie starten'
            },
            successful: 'Hartelijk bedankt! U hebt zich met succes geïdentificeerd!',
            title: 'Identificatie'
        },
        investlist: {
            buy: 'Kopen',
            details: 'Details',
            invest_menu: {
                label: 'Sorteren op:'
            }
        },
        kpi: {
            historyPanel: {
                date: 'Geïnvesteerd op',
                documents: 'Documenten',
                downloads: 'Downloads',
                id: 'Contract-ID',
                investment: 'Belegging',
                no_documents: 'Geen documenten aanwezig',
                payback: 'Volgende uitkering',
                received_payback: 'Reeds terugbetaald',
                title: 'Beleggingen uit het verleden'
            }
        },
        list_financing_investments: {
            empty_state_message: {
                all: 'U heeft op dit momenteel geen investeringen',
                disbursed: 'U heeft momenteel geen rendement op uw investeringen',
                ongoing: 'U heeft momenteel geen lopende investeringen'
            },
            headline: 'Uw beleggingen in Exporo financiering'
        },
        list_financing_payments: {
            defaultActiveCol: 'Betaaldatum',
            empty_state_message: {
                all: 'Er zijn momenteel geen betalingen aan u gedaan',
                coming: 'U heeft momenteel geen uitstaande rendementsbetalingen',
                disbursed: 'Er zijn momenteel geen rendementsbetalingen aan u gedaan'
            },
            headline: 'Uw betalingen',
            payback_started: 'Achterstallige betalingen'
        },
        menu: {
            contact_center: 'Mededelingen',
            documents: 'Documenten',
            edit_user: 'Persoonsgegevens',
            friends: 'Vrienden werven',
            investors: 'Mijn investeerders',
            logout: 'Afmelden',
            password_change: 'Wachtwoord wijzigen',
            questions: 'Hebt u een vraag?',
            questions_url: 'https://exporo.nl/kontakt/',
            title: 'Menu'
        },
        pages: {
            contactCenter: {
                advertisment_notification_control: 'Recht om te adverteren',
                description: `U heeft de keuze: <br> beslis zelf over welke onderwerpen wij u informeren en via welke kanalen wij contact met u mogen opnemen.`,
                title: 'Mededelingen'
            },
            detail: {
                documents_title: 'Documenten',
                kpis: {
                    created_at: 'Geïnvesteerd op',
                    expected_interest: 'Verw. uit te keren rendement p.j.',
                    expected_total_interest: 'Verw. totaal rendement p.j.',
                    interest: 'Rendement p.j.',
                    investment: 'Belegging',
                    investor: 'Investeerder',
                    isin: 'ISIN',
                    payoutdate: 'Einddatum',
                    payoutsum: 'Bedrag van de uitkering',
                    period: 'Looptijd',
                    project: 'Project',
                    signing_id: 'Contract ID',
                    wkn: 'WKN',
                    pieces: 'Aantal',
                    piece_value: 'Nominale obligatiewaarde'
                },
                no_documents: 'Geen documenten aanwezig',
                reportings_charts: {
                    series_a: 'Is',
                    series_b: 'Gepland',
                    title_maintenance_reserve: 'Onderhoudsreserve',
                    title_payout_euro: 'Uitbetaling per kwartaal (€)',
                    title_payout_percent: 'Dividendrendement (%)',
                    title_rental_income: 'Netto huurinkomsten (€)'
                },
                reportings_charts_title: null,
                reportings_table_header: {
                    acquittance_payments: 'Aflossingsbetalingen',
                    income_outcome: 'Inkomsten/Uitgaven',
                    interest_payments: 'Rentebetalingen',
                    investor_capital: 'Investeerderskapitaal',
                    maintenance_cost: 'Onderhoudskosten',
                    maintenance_reserve_funds: 'Onderhoudsreserve',
                    management_costs: 'Administratieve kosten',
                    operating_costs: 'Exploitatiekosten',
                    payout_euro: 'Uitkering in €',
                    payout_percent: 'Uitkering in %',
                    property_management: 'Huisbeheerder',
                    real_estate_result: 'Resultaat onroerend(e) goed(eren)',
                    rental_income: 'Huurinkomsten',
                    rental_loss: 'Huurderving'
                },
                reportings_table_title: 'Kwartaaloverschot',
                reportings_hint_no_reportings: 'Hier vindt u binnenkort gedetailleerde informatie over het vastgoedobject, met meer details over de ontwikkeling en de verwachte winstcijfers.',
                reportings_title: 'Rapportage',
                reportings_title_current_development: 'Huidige ontwikkeling'
            },
            disbursed: {
                headline: 'Uitgekeerde rendementsbetalingen'
            },
            documents: {
                baseDataFiles: 'Formulier voor wijziging stamgegevens',
                depotFiles: 'Baader Bank - Depotdocumenten',
                documentsYearlyList: 'Fiscale documenten',
                explorationFiles: 'Exploratie',
                filesPayback: 'Verrekening van de rente',
                quarterlyReports: 'Kwartaalrapporten over Exporo',
                title: 'Documenten'
            },
            financing: {
                additionalEmptyText: 'U hebt momenteel geen uitkeringen',
                filter: {
                    investments: {
                        allInvestments: 'Alle beleggingen',
                        disbursedInvestments: 'Uitgekeerde beleggingen',
                        ongoingInvestments: 'Lopende beleggingen'
                    },
                    payments: {
                        allPayments: 'Alle uitkeringen',
                        comingPayments: 'Geplande rendementsbetalingen',
                        pastPayments: 'Uitgekeerde rendementsbetalingen'
                    }
                },
                keyfigures: {
                    count_running_investments: 'Aantal lopende beleggingen',
                    return_running_investments: 'Ø rendement p.j. op lopende beleggingen',
                    sum_running_investments: 'Totaal van de lopende beleggingen'
                },
                label: 'Belangrijkste cijfers over uw beleggingen in Exporo financiering',
                landing: {
                    action_button: 'Over de Exporo financiering',
                    description: 'Met de digitale vastgoedbelegging Exporo financiering geniet u al vanaf een minimum inleg van € 500 de voordelen van vastgoed projectontwikkeling – zonder extra kosten en zonder uitgaven.',
                    label: 'Zo werkt het',
                    text: 'Vastgoed bij Exporo wordt resp. voor ca. 50% door de Exporo beleggers en door een banklening gefinancierd. Uit de lopende huurinkomsten worden per kwartaal rendementsuitkeringen aan de beleggers betaald en tegelijkertijd de banklening afgelost; ook wordt de professionele ontwikkeling en het behoud van het vastgoed gewaarborgd.',
                    title: 'Exporo financiering'
                },
                list_headers: {
                    invested_at: 'Geïnvesteerd op',
                    investment: 'Belegging',
                    investor: 'Investeerder',
                    project: 'Project',
                    return_interest: 'Rendement p.j.',
                    running_time: 'Looptijd'
                },
                list_headers_payments: {
                    id: 'ID',
                    payout_date: 'Betaaldatum',
                    payout_sum: 'Uitgekeerd bedrag',
                    project: 'Project'
                },
                tabbar: {
                    investments: 'Beleggingen',
                    payments: 'Uitkeringen',
                    projects: 'Beleggingen'
                }
            },
            friends: {
                copy_link: 'Link kopiëren',
                description: 'Stel een bonus ter hoogte van € 100 veilig voor elke succesvolle aanbeveling. Deel daarvoor uw persoonlijke aanbevelingslink met vrienden, bekenden en uw familieleden.',
                explanation: {
                    headline: 'Zo gemakkelijk werkt het:',
                    hint: `Hier vindt u onze voorwaarden voor deelname: <a href="https://exporo-de-prod.s3.eu-central-1.amazonaws.com/uploads/freunde-werben-freunde-teilnahmebedingungen_NL.pdf" target="_blank">Voorwaarden voor deelname</a>`,
                    step_headline_1: '1. Delen',
                    step_headline_2: '2. Registreren',
                    step_headline_3: '3. Bonus ontvangen',
                    step_text_1: 'Deel uw persoonlijke aanbevelingslink met vrienden, bekenden en uw familieleden.',
                    step_text_2: 'Na succesvolle registratie op Exporo.de ontvangt uw aanbeveling een actiecode van € 100 voor de eerste belegging.',
                    step_text_3: 'Als er door uw aanbeveling een belegging tot stand komt, ontvangt ook u een actiecode van € 100 voor uw volgende belegging.'
                },
                link: (uid: string) => `http://investeringen.exporo.nl/vrienden-werven-vrienden/?friend_id=${uid}`,
                share_link: 'Deel uw link:',
                title: 'Vrienden werven'
            },
            investors: {
                add_investor: 'Investeerder toevoegen',
                admin_hint: 'only editable for admins',
                entity_authorized_as: 'Bevoegd tot vertegenwoordiging als',
                entity_commercial_register_file: 'Uittreksel Handelsregister',
                entity_commercial_register_number: 'Handelsregister nummer',
                entity_local_court: 'bevoegde rechtbank',
                entity_name: 'Naam van de onderneming',
                entity_shareholders_file: 'Lijst met vennoten',
                entity_status: 'Bewerkingsstatus',
                entity_status_accepted: 'Getoetst',
                entity_status_open: 'in behandeling',
                entity_tax_number: 'Fiscaal nummer',
                entity_tax_office: 'bevoegd belastingkantoor',
                entity_types: {
                    ag: 'NV',
                    default: 'selecteren',
                    gmbh: 'BV',
                    gmbhcokg: 'Commanditaire vennootschap met beperkte aansprakelijkheid & Co.',
                    kg: 'Commanditaire vennootschap',
                    ug: 'Ondernemend bedrijf met beperkte aansprakelijkheid'
                },
                entity_type_of_company: 'Bedrijfsvorm',
                form_hint: 'Maak hier een investeerder aan.',
                investor: 'Investeerder',
                investor_not_found_error: 'Investeerder bestaat niet.',
                label: 'Annuleren',
                legal_entity_checkbox: 'als rechtspersoon registreren.',
                legal_entity_label: 'Rechtspersoon',
                legal_entity_verification: 'Hierbij bevestig ik dat ik de wettelijke vertegenwoordiger van de onderneming ben.',
                submit_text_create: 'Aanmaken',
                submit_text_update: 'Bijwerken',
                title: 'Mijn investeerders'
            },
            investplan: {
                interest: 'Rentevoet',
                kpis: {
                    contributions_phase_ends_at: 'Einde van de termijnbetaling',
                    contributions_phase_starts_at: 'Begin van de termijnbetaling',
                    contributions_sum: 'Belegd bedrag',
                    contributions_sum_month: 'Maandelijkse termijn',
                    expected_interest_rate: 'Nagestreefde rentevoet p.j.',
                    id: 'Spaarplan ID',
                    interestrate_guaranteed: 'Basisrentevoet p.j.',
                    investor: 'Investeerder',
                    next_payout: 'Terugbetaling'
                },
                panel_title_documents: 'Documenten',
                panel_title_projects: 'Geïnvesteerde projecten',
                project: 'Project',
                title: 'Exporo beleggingsplan'
            },
            portfolio: {
                data_pending_type: {
                    bond_purchase: 'Aankoop van obligaties',
                    bond_purchase_secondary_market: 'Aankoop van obligaties (Handelscentrum)'
                },
                keyfigures: {
                    equity_investments_length: 'Aantal lopende beleggingen',
                    invested_total_equity_sum: 'Totaal van de lopende beleggingen'
                },
                kpi_title: 'Belangrijkste cijfers over uw beleggingen in Exporo vastgoed',
                landing: {
                    action_button: 'Over de projecten',
                    description: 'Met Exporo kunt u al vanaf € 1.000 de voordelen van het eigendom van vastgoed genieten - zonder kosten en uitgaven. Bekijk de video om te horen hoe een totnogtoe starre waarde met Exporo een verhandelbare geldbelegging in vastgoed wordt, waarmee u blijvend kapitaal kunt opbouwen.',
                    label: 'Zo werkt het',
                    text: 'Vastgoed bij Exporo wordt resp. voor ca. 50% door de Exporo beleggers en door een banklening gefinancierd. Het beste aan Exporo: u hebt geen kosten, hoeft u niet om de doorontwikkeling, verhuur en onderhoud te bekommeren en bent in principe niet aan het vastgoed gebonden – want: u kunt vanaf het voorjaar van 2020 uw aandelen weer op de Exporo Handelsplaats verkopen.',
                    title: 'Exporo'
                },
                list_empty_states: {
                    filter_investments: 'U hebt momenteel geen beleggingen voor dit filter.',
                    paied_investments: 'U hebt momenteel geen uitgekeerde beleggingen.',
                    running_investments: 'U hebt momenteel geen lopende beleggingen.'
                },
                list_headers: {
                    already_received: 'Al uitgekeerd',
                    current_state: 'Status',
                    expected_payout: 'Verw. uit te keren rendement p.j.',
                    invested_at: 'Geïnvesteerd op',
                    investment: 'Som',
                    next_payout: 'Volgende uitkering',
                    project: 'Project',
                    running_time: 'Looptijd',
                    type: 'Type'
                },
                list_title: 'Uw beleggingen in Exporo vastgoed',
                list_title_pending: 'Uw Exporo bestaand vastgoed | Status: In behandeling',
                list_title_tooltip: 'Uw  beleggingen worden nu in uw effectendepot cq. uw digitale kluis (wallet) ingeboekt. Deze procedure kan bij een eerste belegging maximaal twee weken in beslag nemen, al naar gelang de status van uw persoonlijke identificatie, het openen van uw depot bij de Baader Bank cq. uw digitale kluis (wallet) en de transactie van uw beleggingsbedrag. De actuele status kunt u zien in de statusbalk bij uw belegging.',
                list_undertitle: 'De volgende lijst weerspiegelt uw beleggingen van aandelen in uw digitale kluis.',
                list_undertitle_pending: 'Uw  beleggingen worden nu in uw effectendepot cq. uw digitale kluis (wallet) ingeboekt. Deze procedure kan bij een eerste belegging maximaal twee weken in beslag nemen, al naar gelang de status van uw persoonlijke identificatie, het openen van uw depot bij de Baader Bank cq. uw digitale kluis (wallet) en de transactie van uw beleggingsbedrag. De actuele status kunt u zien in de statusbalk bij uw belegging.',
                pending_filter: {
                    allInvestments: 'Alle beleggingen',
                    pending: 'In behandeling'
                },
                progress_bar: {
                    booking: 'Aanmelding',
                    depot: {
                        done: 'Depot geopend',
                        not_done: 'Opening van een depot'
                    },
                    ident: {
                        done: 'Geïdentificeerd',
                        not_done: 'Identificatie starten'
                    },
                    paid: {
                        done: 'Betaling is ontvangen',
                        not_done: 'Betaling nog niet ontvangen'
                    }
                }
            }
        },
        tabbar: {
            financing: 'Financiering',
            investplan: 'Beleggingsplan',
            portfolio: 'Cockpit'
        },
        title: 'Investmentcockpit'
    },
    contact: {
        checkbox_ag_1: 'Hierbij ga ik akkoord met de',
        checkbox_ag_2: 'akkoordverklaring bij het contactformulier',
        checkbox_ag_3: 'en de',
        checkbox_ag_4: 'privacy-verklaring',
        checkbox_ag_5: 'van Exporo AG.',
        checkbox_gmbh_5: 'van Exporo Investment GmbH.',
        contact_placeholder_1: 'Naam *',
        contact_placeholder_2: 'E-mail *',
        contact_placeholder_3: 'Betreft *',
        contact_placeholder_4: 'Mededeling *',
        cta: 'Verzenden',
        exporo_contact: 'Hoe kunnen we u helpen? Neem a.u.b. contact met ons op.',
        exporo_description: 'Met Exporo kunt u voordeel genieten van aantrekkelijke vastgoedprojecten - en dat al vanaf 500 Euro. Vanuit onze vestiging in Hamburg brengen wij beleggers en projectuitvoerders met elkaar in contact. Laat ons contact met u opnemen: schrijf ons gewoon en wij gaan met u het gesprek aan over ons aanbod aan projecten of wij helpen u bij het realiseren van uw project.',
        title: 'Contact'
    },
    customerFeedback: {
        title: 'Dit zeggen onze investeerders',
        link_text: 'Meer',
        link_url: 'https://investeringen.exporo.nl/ervaringen/'
    },
    error_form: {
        error_message: 'Kies a.u.b. uw titel.'
    },
    form: {
        adress: 'Adres',
        city: 'Plaats',
        email: 'E-mail',
        land: 'Land',
        legitimation_document: 'Soort legitimatiebewijs',
        primary_phone: 'Primair telefoonnr.',
        save: 'Opslaan',
        secondary_phone: 'Secundair telefoonnr.',
        to_be_filled: 'moet ingevuld zijn.',
        zip: 'Postcode'
    },
    header_phone_no: '+31 (0)20 8905 597',
    header_phone_office_hours: 'MA - VR 09:00 - 17:00 UUR',
    header_phone_text: 'SERVICE HOTLINE:',
    iban: {
        error_cannot_check: 'IBAN kon niet worden gecontroleerd. Voer a.u.b. uw BIC-code in.',
        error_not_valid: 'Voer a.u.b. een geldige IBAN in.'
    },
    invest_button: {
        label: 'Beleggen'
    },
    listView: {
        deselected_all: 'alle deselecteren',
        entries_from: 'van',
        entries_per_page: 'vermeldingen per pagina',
        export_csv: 'Data als .CSV exporteren',
        next: 'volgende',
        prev: 'vorige',
        print: 'Afdrukken',
        search_project: 'Projecten zoeken',
        selected_entries: 'vermeldingen geselecteerd'
    },
    person: {
        birthday: 'Geboortedatum',
        birth_city: 'Geboorteplaats',
        birth_land: 'Geboorteland',
        birth_name: 'Geboortenaam',
        citizenship: 'Nationaliteit',
        female: 'Vrouw',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        male: 'Man',
        marital_status: 'Burgerlijke staat',
        titles: {
            dr: 'Dr.',
            dr_med: 'Dr.',
            prof: 'Prof.',
            prof_dr: 'Prof. dr.'
        },
        title_label: 'Titel'
    },
    progress_bar: {
        funding_in_percentage: 'gefinancierd',
        remaining: 'resterend'
    },
    query_settings: {
        headline: 'Experimental Features',
        toggle: 'Animatie:'
    },
    secondaryMarket: {
        buyButton: {
            cta: 'Kopen'
        },
        buySuccess: {
            title: 'Er is met succes een bod tot aankoop ingediend.'
        },
        buyTransaction: {
            checkbox_1: 'Hierbij ga ik akkoord met de',
            checkbox_2: 'Algemene Voorwaarden',
            checkbox_3: 'ten aanzien van Exporo Handelsplaats.',
            cta: 'Bod tot aankoop indienen',
            label_1: 'Prijs per stuk:',
            label_2: 'Beschikbaar aantal:',
            label_3: 'Hoeveel exemplaren wilt u kopen?',
            label_4: 'Exemplaren',
            label_5: 'Uw bod tot aankoop:',
            small_hint: 'Opmerking: verkoop via de Exporo Handelsplaats is belastingplichtig, d.w.z. eventuele winsten\nzijn onderworpen aan de vermogenswinstbelasting,',
            title_1: 'Bod tot aankoop van',
            title_2: 'indienen',
            valid_input_1: 'Controleer a.u.b. wat u hebt ingevoerd!'
        },
        modalContainer: {
            label_1: 'IBAN:',
            label_2: 'Gegevens aanvullen',
            no_depot_available: 'Om een aankoopbod te kunnen indienen, is het nodig om een depot te openen.',
            reminder_text_1: 'Geef a.u.b. uw IBAN om een depot te kunnen aanmaken.',
            reminder_text_2: 'Uw persoonsgegevens moeten eerst volledig zijn.'
        },
        offerSuccess: {
            title: 'is op de Handelsplaats voor verkoop aangeboden.'
        },
        offerTransaction: {
            cta: 'Verkoopaanbod indienen',
            label_1: 'Uw beschikbaar kapitaal:',
            label_2: 'Hoeveel exemplaren wilt u maximaal verkopen?',
            label_3: 'exemplaren',
            label_4: 'Voor welke verkoopprijs per stuk wilt u verkopen?',
            label_5: '€',
            label_6: 'Advertentiekosten:',
            label_7: 'Uw verkoopaanbod:',
            sending_offer: 'Uw koopaanbod wordt gepubliceerd',
            small_hint: 'Opmerking: een verkoop via de Exporo Handelsplaats is belastingplichtig, d.w.z. eventuele winsten zijn onderworpen aan de vermogenswinstbelasting.',
            store_error: 'Bij de publicatie is een fout opgetreden. Probeer het nog eens.',
            title: 'Verkoopaanbod opmaken voor',
            tooltip_1: 'Berekening aantal exemplaren voor dit project : 1 exemplaar = € 1000',
            tooltip_2: 'Bij een succesvolle verkoop bedragen de advertentiekosten 0,5% van het verkoopaanbod.',
            valid_input_1: 'Geen verkoopbare exemplaren beschikbaar!',
            valid_input_2: 'Verkoop niet mogelijk'
        },
        offerRejection: {
            title: 'Verkoopopdracht intrekken voor ',
            already_sold: 'Reeds verkochte obligaties:',
            in_progress: 'Wordt verwerkt:',
            remaining_pieces: 'Openstaand aanbod:',
            sale_price_per_piece: 'Verkoopprijs obligatie:',

            store_error: 'Er is een fout opgetreden bij het intrekken van uw verkoopopdracht. Probeer het SVP opnieuw.',
            small_hint_1: 'Uw verkoopopdracht kan worden ingetrokken. Het aanbod is nog niet verhandeld.',
            small_hint_2: 'Uw verkoopopdracht kan alleen worden ingetrokken voor nog openstaand aanbod. Reeds verkocht en/of in de handel gebracht aanbod is uitgesloten van herroeping en blijft van kracht en/of zullen worden uitgevoerd.',
            cta: 'Cancel verkoop obligaties',
            sending_rejection: 'Verkoopaanbod wordt verwijderd',
            no_rejectable_pieces: 'Verkoopopdracht niet uitvoerbaar',
        },
        secondaryMarketVue: {
            login_btn: 'Nu aanmelden',
            login_hint: 'Nu inloggen of kosteloos registreren, aanbod selecteren, aandelen verwerven en profiteren van uitkeringen per kwartaal.'
        }
    },
    times: {
        months: 'Maanden'
    },
    userEdit: {
        countries: {
            AT: 'Oostenrijk',
            CH: 'Zwitserland',
            DE: 'Duitsland',
            FR: 'Frankrijk',
            HU: 'Hongarije',
            IE: 'Ierland',
            LU: 'Luxemburg',
            NL: 'Nederland'
        },
        description_change_name: 'Neem bij wijziging van de naam a.u.b. contact op met onze klantenondersteuning',
        legitimation_document_hint: 'Indien u het paspoort als identificatiedocument kiest, hebt u voor het openen van een depot\n                                een <b>uittreksel uit het bevolkingsregister</b> nodig. Daardoor kan het proces van het openen van een depot vertraagd worden.\n                                Wij adviseren u om u met een ID-kaart te identificeren.',
        link_change_name: 'https://exporo.nl/kontakt/',
        miles_and_more: 'Miles & More servicekaart nr.',
        miles_tooltip: 'Wij bieden alle Miles & More deelnemers die voor het eerst via Exporo.de beleggen, de mogelijkheid om een aantrekkelijke bonus van maximaal 15.000 premiemijl te ontvangen bij hun eerste belegging.',
        months: {
            apr: 'April',
            aug: 'Augustus',
            dec: 'December',
            feb: 'Februari',
            jan: 'Januari',
            jul: 'Juli',
            jun: 'Juni',
            mar: 'Maart',
            may: 'Mei',
            nov: 'November',
            oct: 'Oktober',
            sep: 'September',
            year: 'Jaar'
        },
        not_us_taxed: 'Ik ben niet belastingplichtig in de USA.',
        pass: 'Document nr',
        pass_authority: 'Afgegeven door',
        pass_country_of_issue: 'Afgegeven in (land)',
        pass_created_at: 'Datum van afgifte',
        pass_expired_at: 'Geldig tot',
        pep: 'Ik ben een "politically exposed person" (PEP).',
        pep_hint: `Als "politically exposed person" (PEP) kunt u op bepaalde producten pas na nadere toetsing intekenen. Neem a.u.b eerst contact op met onze ondersteuning:  <br><br> Support: <br> E-mail: support@exporo.com <br> Telefoon: 00-49-40 / 210 91 73 - 00`,
        pep_tooltip: 'Een "politically exposed person" (PEP) is iemand die hetzij zelf met een prominente openbare functie is belast (bijv. politici, ambassadeurs, rechters van de Hoge Raad) cq. belast is geweest of een persoon uit diens directe persoonlijke omgeving (bijv. familieleden of personen die dicht bij zo iemand staan).',
        street_additional: 'Extra adresinformatie',
        street_and_no: 'Straat en huisnr.',
        sub_title: 'Wijzig hier uw persoonsgegevens.',
        success_text: 'Gegevens met succes aangepast.',
        tax_id: 'Belasting-ID',
        tax_not_used_tooltip: 'Op grond van de in 2010 aangenomen "FATCA"-wet moeten personen uit de U.S.A. elk jaar kun financiële rekeningen buiten de V.S. melden. Tegelijkertijd worden financiële instellingen buiten de USA ertoe verplicht om personen uit de U.S.A. te registreren. Op grond van de bepalingen van FACTA kunnen klanten die in de U.S.A. Belastingplichtig zijn, geen belegging doen via Exporo.',
        tax_not_used_short: 'Op grond van de bepalingen van FACTA kunnen klanten die in de U.S.A. Belastingplichtig zijn, geen belegging doen via Exporo.',
        tax_nr: 'Burgerservicenummer (BSN)',
        tax_tooltip_at: 'In Oostenrijk is het fiscaal nummer een getal met 9 cijfers. Het fiscaal nummer staat links bovenaan op de aanslag inkomstenbelasting. Als er nog geen aanslag inkomstenbelasting ontvangen is, kan worden gebeld met het betreffende belastingkantoor. Daar zou men dan de gevraagde informatie moeten kunnen geven. Als er bij het belastingkantoor geen fiscaal nummer (TIN) bekend is, kan dit rechtstreeks bij het betreffende belastingkantoor worden aangevraagd.',
        tax_tooltip_de: 'De Belasting-ID (Belasting-Identificatienummer) is een cijfer met 11 getallen, dat elke burger in Duitsland die onder de belastingwetgeving valt, ontvangt. De Belasting-ID wordt ook aangeduid als ”Persoonlijk Identificatienummer”, ”Identificatienummer” of als TIN (Tax Identification Number). In de regel vindt u uw Belasting-ID op uw aanslag inkomstenbelasting of op uw loon- of salarisstrook onder een van de hierboven vermelde aanduidingen. Als er nog geen aanslag inkomstenbelasting ontvangen is, kan worden gebeld met het betreffende belastingkantoor.',
        tebmenu: {
            admin: 'Admin',
            data: 'Uw gegevens',
            partner: 'Partner',
            wallet: 'Digitale kluis'
        },
        title: 'UW PERSOONSGEGEVENS',
        wallet_description: 'Via de kluis kunt u in real time uw beleggingen in Exporo vastgoed opvragen en beheren. Deze service kunnen wij uitsluitend voor onze nieuwe digitale effecten aanbieden.',
        wallet_link: 'Digitale kluis openen'
    }
};

export default nl;
