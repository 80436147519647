
















import {Component, Prop, Vue} from 'vue-property-decorator';


@Component
class UserTooltip extends Vue {
    @Prop({default: false})
    private text!: string;
    @Prop({default: 0})
    private addLeft!: number;
    @Prop({default: 10})
    private screenPadding!: number;
    private showBubble = false;

    private tooltipStyle = {
        width: `${300}px`,
        left: 'initial'
    };

    private arrowStyle = {
        left: 'initial',
        width: 'initial'
    };

    private isLeftArrow: boolean = false;

    positionToolTip()  {
        const tooltipLeft = this.$el.getBoundingClientRect().left;
        const tooltipRight = window.innerWidth - tooltipLeft;
        const screenPadding = this.screenPadding;
        const bubbleWidth: number = window.innerWidth >= 900 ? 300 : 250;
        let bubbleLeft: number = - bubbleWidth / 2;
        const arrowWidth: number = 20;
        let arrowLeft: number = bubbleWidth / 2 - arrowWidth;

        this.tooltipStyle = {
            width: `${bubbleWidth}px`,
            left: `${bubbleLeft}px`
        };
        this.arrowStyle = {
            left: `${arrowLeft}px`,
            width: `${arrowWidth}px`,
        };

        if (tooltipLeft < bubbleWidth / 2 + screenPadding) {
            const newBubbleLeft = - tooltipLeft + screenPadding;
            const diffLefts = bubbleLeft - newBubbleLeft;
            arrowLeft = arrowLeft + (diffLefts);
            bubbleLeft = newBubbleLeft;
            this.setStyle(bubbleLeft, arrowLeft, bubbleWidth, arrowWidth);
        } else if (tooltipRight < bubbleWidth / 2 + screenPadding) {
            const newBubbleLeft = - bubbleWidth + tooltipRight - screenPadding;
            const diffLefts = + bubbleLeft - newBubbleLeft;
            arrowLeft = arrowLeft + (diffLefts);
            bubbleLeft = newBubbleLeft;
            this.setStyle(bubbleLeft, arrowLeft, bubbleWidth, arrowWidth);
        } else if (this.addLeft !== 0) {
            bubbleLeft = - bubbleWidth / 2 + this.addLeft;
            arrowLeft = arrowLeft - this.addLeft;
            this.setStyle(bubbleLeft, arrowLeft, bubbleWidth, arrowWidth);
        }
        this.showBubble = true;
    }

    hideBubble() {
        this.showBubble = false;
    }

    setStyle(newLeft, positionArrowLeft, bubbleWidth, arrowWidth) {
        this.isLeftArrow = false;
        this.tooltipStyle = {
            left: `${newLeft}px`,
            width: `${bubbleWidth}px`
        };

        this.arrowStyle = {
            left: `${positionArrowLeft}px`,
            width: `${arrowWidth}px`,
        };

        if (positionArrowLeft <= 0) {
            this.isLeftArrow = true;
            this.arrowStyle = {
                left: `${-14}px`,
                width: `${arrowWidth}px`,
            };
        }
    }
}

export default UserTooltip;

