







    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
	import LocalStorage from '@/components/LocalStorage/LocalStorage';

    @Component
    class BackToExporo extends Vue {

        constructor() {
            super();
        }

		backToExporo() {
			window.location.href = sessionStorage.getItem('referrer') || 'https://exporo.de';
		}
    }

    export default BackToExporo;
