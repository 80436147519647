
















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {HOST} from '../../Rest/services/RestApiService';
import ExporoVue from '@/components/ExporoVue';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import UserRequestApi from '@/components/Rest/requests/UserRequestApi';
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';
import URLHelper from '@/components/Helper/URLHelper';
import BackToExporo from '@/components/Checkout/BackToExporo.vue';


interface Zenloop {
    recipient: {};
}

@Component({
    components: {
        LoadingAnimation,
        BackToExporo
    }
})
class BondStoreSuccess extends ExporoVue implements RequestCallback {

    @Prop()
    private user!: any;
    @Prop()
    private userIdentRedirectUrl!: string;
    @Prop()
    private userIsIdentified!: boolean;
    @Prop()
    private signingId!: number;

    private userRequestApi: UserRequestApi;

    private identUrl: string | null = null;

    private isLoading: boolean = false;


    constructor() {
        super();
        this.userRequestApi = this.restApi.create(new UserRequestApi());
    }

    mounted() {
        if (this.userIsIdentified) {
            const zenloopScript: any = document.createElement('script');
            zenloopScript.onload = () => {
                const Zenloop: any = (window as any).Zenloop || {};
                Zenloop.recipient = {
                    identity: `BOND-${this.signingId}`,
                    identity_type: 'custom'
                };
            };

            zenloopScript.async = true;
            zenloopScript.setAttribute('src', 'https://zenloop-website-overlay-production.s3.amazonaws.com/loader/zenloop.load.min.js?survey=NWJzME9YV1R3R1FKZ1UrcjVCb1JGMGRscGlPTHVPMkhEa0pjby9OTnN4ND0%3D');
            zenloopScript.setAttribute('id', 'zl-website-overlay-loader');

            const wrapper = document.getElementById('zl-wrapper');
            if (wrapper) {
                wrapper.appendChild(zenloopScript);
            }
        }
    }

    getBaseHost() {

        return `https://cockpit.exporo.${this.cctld.toLowerCase()}`;
    }

    getIdentUrl() {
        if (null === this.identUrl && false === this.isLoading) {
            this.isLoading = true;
            this.userRequestApi.getIdentUrl(this).addParam('url', this.userIdentRedirectUrl.split(HOST)[1]);
        }
    }


    getTitle() {

        if ('male' === this.user.sex) {

            return this.trans.get('checkout.bond_store_success.salutation_male');
        }

        return this.trans.get('checkout.bond_store_success.salutation_female');
    }

    getName() {

        return this.user.last_name;
    }

    onFailure(error: RestError, id?: string): void {
        this.isLoading = false;
    }

    onSuccess(response: AxiosResponse, id?: string): void {

        if (UserRequestApi.GET_IDENT_KEY === id && response.data.hasOwnProperty('redirectUrl')) {
            this.identUrl = response.data.redirectUrl;
            const identButton: any = this.$refs.identButton;

            identButton.href = this.identUrl;
            identButton.target = '_blank';
            identButton.click();

            this.isLoading = false;
        }
    }

    isExporoAT(): boolean {
        return 'AT' === this.cctld;
    }
}

export default BondStoreSuccess;
