import RequestCallback from '@/components/Rest/RequestCallback';
import {HOST, default as RestApiService} from '@/components/Rest/services/RestApiService';
import {AxiosResponse} from 'axios';
import ExporoVue from '@/components/ExporoVue';
import CheckoutRequestApi from '@/components/Rest/requests/CheckoutRequestApi';

type IbanCheckResponseCallback = (any) => void;

class IbanService extends ExporoVue implements RequestCallback {

    private checkoutRequestApi: CheckoutRequestApi;
    private callback: IbanCheckResponseCallback | null = null;

    constructor() {
        super();

        this.checkoutRequestApi = this.restApi.create(new CheckoutRequestApi());
    }

    public getBicFromIban(iban: string, callback: IbanCheckResponseCallback) {
        this.callback = callback;
        this.checkoutRequestApi.checkIban(this).addParam('iban', iban);
    }

    onFailure(error: any): void {
        const ibanCheckResponse: IbanCheckResponse = {
            bic: '',
            error: true
        };

        if (this.callback) {
            this.callback(ibanCheckResponse);
        }
    }

    onSuccess(response: AxiosResponse) {
        if (this.isIbanCheckResponse(response.data) && this.callback) {
            this.callback(response.data);
        }
    }

    isValidIBANNumber(ibanString: string) {
        const CODE_LENGTHS: any = {DE: 22, AT: 20, CH: 21, BE: 16, LU: 20, ES: 24, NL: 18, GB: 22, FR: 27, BG: 22, LT: 20, PT: 25};
        const iban = String(ibanString).toUpperCase().replace(/[^A-Z0-9]/g, '');
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }

        const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g,  (letter: any): any => {

            return letter.charCodeAt(0) - 55;
        });

        return this.mod97(digits);
    }

    mod97(string: string): any {
        let checksum: any = string.slice(0, 2);
        let fragment: string;
        for (let offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }

        return checksum;
    }

    isIbanCheckResponse(response: IbanCheckResponse | object): response is IbanCheckResponse {

        return void(0) !== (response as IbanCheckResponse).bic;
    }
}

export default IbanService;
