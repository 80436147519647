














































    import {Component, Vue} from 'vue-property-decorator';
    import AuthService from './services/AuthService';
    import Credentials from './models/Credentials';
    import RequestCallback from '../Rest/RequestCallback';
    import {AxiosResponse} from 'axios';
    import RestApiService from '../Rest/services/RestApiService';
    import LocalStorage from '../LocalStorage/LocalStorage';
    import ExporoVue from '../ExporoVue';
    import Events from '../../events';
    import Checkbox from '@/components/Checkbox/Checkbox.vue';
    import RestError from '../Rest/models/RestError';
    import Input from '@/components/Input/Input.vue';
    import Register from '@/components/Auth/Register.vue';
    import PasswordForget from '@/components/Auth/PasswordForget.vue';
    import Controller from '@/components/Router/Controller';
    import {Action} from '@/components/Router/decorators/RouteDecorator';
    import Router from '@/components/Router/Router';
    import Tracking from '@/components/GoogleTagManager/services/Tracking';
    import ExporoModalService from '@/components/Modal/services/ExporoModalService.vue';
    import {Severity} from '@sentry/types';
    import * as Sentry from '@sentry/browser';


    @Component({
        components: {
            PasswordForget,
            Register,
            Checkbox,
            Input,
        },
        props: {
            openAuthModalByName: Function,
            modalNames: Object,
        },
    })
    export default class Login extends Controller implements RequestCallback {

        public input: { password: string, email: string, remember: boolean } = {
            email: '',
            password: '',
            remember: false,
        };

        public $props: any;
        private authService: AuthService;
        private errorEmail: any = '';
        private errorPassword: any = '';
        private currentTab: number = 1;

        constructor() {
            super('Login');

            this.authService = new AuthService(new RestApiService(this));
        }

        getName() {

            return this.getClassName() || 'Login';
        }

        onCloseModal(): void {
            LocalStorage.remove(ExporoVue.REDIRECT_TO_KEY);
        }

        closeModal() {
            this.getModalService().closeModal('login-modal');

        }

        navigate(event: any, auth: string) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            Router.navigate('openAuth', [auth]);
        }


        @Action('openAuth', {'openLogin': []})
        onTab(auth: string) {
            this.onTabClick('login' === auth ? 1 : 2);
        }

        navigateToForgotModal(e) {
            e.preventDefault();
            this.closeModal();
            Router.navigate('openModal', ['password-forget-modal']);
        }

        onTabClick(value: number) {
            this.currentTab = value;
            if (value === 1) {
                Tracking.trackOpen('login form', false, undefined);
            } else if (value === 2) {
                Tracking.trackOpen('registration form', false, undefined);
            }
        }

        login(): void {
            // this.$store.commit(LOGIN);
            this.authService.login(new Credentials(this.input.email, this.input.password), this.input.remember, this);
            Tracking.trackSubmit('login form', false, undefined);
        }

        isLoggedIn(): boolean {

            const isLoggedIn = this.authService.isLoggedIn();

            if (!isLoggedIn) {
                // this.$store.commit(LOGOUT);
            }

            return isLoggedIn;
        }

        onFailure(resterror: RestError): void {
            // this.$store.commit(LOGIN_FAILED);
            const messages = resterror.errorMessage;
            this.errorEmail = '';
            this.errorPassword = '';

            if (messages.hasOwnProperty('email')) {
                if (messages.email instanceof Array) {
                    this.errorEmail = messages.email[0];
                    Tracking.trackError('login form', true, undefined);
                } else {
                    this.errorPassword = messages.email;
                    Tracking.trackError('login form', true, undefined);
                }
            }
            if (messages.hasOwnProperty('password')) {
                this.errorPassword = messages.password[0];
                Tracking.trackError('login form', true, undefined);
            }

            if (this.errorEmail || this.errorPassword) {
                this.sendSentryError(this.errorPassword, this.errorEmail);
            }

            LocalStorage.remove(ExporoVue.REDIRECT_TO_KEY);
        }

        sendSentryError(errorPassword: any, errorEmail: any) {
            Sentry.withScope((scope) => {
                scope.setExtra('messages', {
                    errorMessage: errorPassword,
                    errorEmail: errorEmail,
                    email: this.input.email
                });

                Sentry.captureMessage(`[LOGIN] User can't login`, Severity.Info);
            });
        }

        onSuccess(response: AxiosResponse): void {
            const data: any = response.data;
          // this.$store.commit(LOGIN_SUCCESS);
            let lifetime = 30;
            if (data.hasOwnProperty('userId') && (null !== data.userId)) {
                LocalStorage.add(ExporoVue.USER_ID_KEY, data.userId);
                Events.$emit('userIdLoaded');

            } else if (data.hasOwnProperty('userId') && (null === data.userId)) {
                // this.$store.commit(LOGIN_FAILED);
                this.errorPassword = this.trans.get('auth.modal.error_password');
                LocalStorage.remove(ExporoVue.REDIRECT_TO_KEY);

                this.sendSentryError(this.errorPassword, '');

                return;
            }

            if (response.headers.hasOwnProperty('x-session-lifetime')) {
                lifetime = response.headers['x-session-lifetime'];
            }

            this.authService.storeLogin(JSON.stringify({
                'lifetime': lifetime,
                'saved_at': +new Date(),
            }));

            this.closeModal();
			const checkoutURL = LocalStorage.get('checkoutURL', null);

            Events.$emit('onLoggedIn');

            if (checkoutURL) {
				window.location.href = checkoutURL;
				window.location.reload();
            } else {
                window.location.reload();
            }
        }
    }
