// @TODO id deprecated?
/* tslint:disable */
import Credentials from '../models/Credentials';
import RestApiService, {HOST} from '@/components/Rest/services/RestApiService';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import {LOGIN_FAILED, LOGOUT} from '@/store';
import CompletedCredentials from '@/components/Auth/models/CompletedCredentials';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import AuthRequestApi from '@/components/Rest/requests/AuthRequestApi';
import ExporoVue from '@/components/ExporoVue';

export const TOKEN_KEY = 'token';

class AuthService extends ExporoVue {

    private authRequestApi: AuthRequestApi;
    $store: any;

    constructor(restApiService: RestApiService) {
        super();

        this.authRequestApi = this.restApi.create(new AuthRequestApi());
        this.$store = restApiService.$store;
    }

    public isLoggedIn(): boolean {

		return this.getStore ?
			this.getStore().state.isLoggedIn :
			false;
    }

    public storeLogin(token: string) {
        localStorage.setItem(TOKEN_KEY, token);
    }

    public login(credentials: Credentials, remember: boolean, callback: RequestCallback) {
        const bodyFormData = new FormData();

        bodyFormData.append('email', credentials.email);
        bodyFormData.append('password', credentials.password || '');
        bodyFormData.append('remember', String(Number(remember)) || '0');

        this.authRequestApi.login(bodyFormData, callback);
    }

    public sendRegister(credentials: Credentials, callback: RequestCallback, affiliatedPartnerId: any, a_aid_ref?: any) {
        const bodyFormData = new FormData();

        bodyFormData.append('email', credentials.email);
        bodyFormData.append('exporo-ag', 'on');
        bodyFormData.append('exporo-investment-gmbh', 'on');
        bodyFormData.append('affiliated_partner_id', affiliatedPartnerId);

        if (a_aid_ref) {
            bodyFormData.append('a_aid_ref', a_aid_ref);
        }

        this.authRequestApi.register(bodyFormData, callback);
    }

    public resetPassword(credentials: Credentials, callback: RequestCallback) {
        const bodyFormData = new FormData();

        bodyFormData.append('email', credentials.email);
        bodyFormData.append('exporo-ag', 'on');
        bodyFormData.append('exporo-investment-gmbh', 'on');

        this.authRequestApi.resetPassword(bodyFormData, callback);
    }

    public resetPasswordComplete(credentials: CompletedCredentials, token: string, callback: RequestCallback) {

        const bodyFormData = new FormData();
        bodyFormData.append('email', credentials.email);
        bodyFormData.append('password', credentials.password || '');
        bodyFormData.append('password_confirmation', credentials.passwordConfirmation);
        bodyFormData.append('token', token);

        this.authRequestApi.resetPasswordComplete(bodyFormData, callback);
    }

    public logout() {
        localStorage.removeItem(TOKEN_KEY);
    }
}

export default AuthService;
