class SessionStorage {
    public static add<T>(key: string, value: T) {
        sessionStorage.setItem(key, String(value));
    }

    public static addObject(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static get<T>(key: string, defaultValue?: T): T {
        if (!SessionStorage.has(key)) {

            return defaultValue as T;
        }

        return this.getTransformedItem(key, defaultValue) as T;
    }


    public static getObject<T>(key: string, defaultValue: T): T {
        if (!SessionStorage.has(key)) {

            return defaultValue;
        }

        const item = sessionStorage.getItem(key);

        return item ? JSON.parse(item) : {} as T;
    }

    public static remove(key: string) {
        if (SessionStorage.has(key)) {
            sessionStorage.removeItem(key);
        }
    }

    public static has(key: string): boolean {

        return null !== sessionStorage.getItem(key);
    }

    private static getTransformedItem<T>(key: string, defaultValue: T) {
        let item: any = sessionStorage.getItem(key);

        if ('number' === typeof defaultValue) {
            item = Number(item);
        } else if ('string' === typeof defaultValue) {
            item = String(item);
        }

        return item;
    }
}

export default SessionStorage;
