const de = {
    listView: {
        prev: 'vorherige',
        next: 'nächste',
        entries_per_page: 'Einträge pro Seite',
        entries_from: 'von',
        selected_entries: 'Einträge ausgewählt',
        deselected_all: 'alle abwählen',
        print: 'Drucken',
        export_csv: 'Datei als CSV exportieren',
        search_project: 'In Investments suchen'
    },
    basic: {
        yes: 'Ja',
        no: 'Nein',
        day: 'Tag',
        month: 'Monat',
        year: 'Jahr',
    },
    customerFeedback: {
        title: 'DAS SAGEN UNSERE KUNDEN',
        link_text: 'Weitere Kundenstimmen',
        link_url: 'https://direkt.exporo.de/kundenstimmen/'
    },
    times: {
        months: 'Monate'
    },
    person: {
        male: 'Mann',
        female: 'Frau',
        title_label: 'Title',
        titles: {
            dr: 'Dr.',
            dr_med: 'Dr. med.',
            prof_dr: 'Prof. Dr.',
            prof: 'Prof.'
        },
        first_name: 'Vorname',
        last_name: 'Nachname',
        birthday: 'Geburtsdatum',
        birth_name: 'Geburtsname',
        birth_land: 'Geburtsland',
        birth_city: 'Geburtsort',
        citizenship: 'Staatsangehörigkeit',
        marital_status: 'Familienstatus'
    },
    form: {
        email: 'E-Mail',
        adress: 'Adresse',
        zip: 'Postleitzahl',
        city: 'Stadt',
        land: 'Land',
        primary_phone: 'Telefonnr.',
        secondary_phone: 'Mobiltelefonnr.',
        legitimation_document: 'Legitimationsdokument',
        save: 'Speichern',
        to_be_filled: 'muss ausgefüllt sein.'
    },
    auth: {
        cockpitButton: 'Investmentcockpit',
        loginButton: 'Anmelden',
        logoutButton: 'Abmelden',
        modal: {
            tabs: {
                login: 'Anmelden',
                register: 'Registrieren'
            },
            remember_me: 'Auf diesem Gerät angemeldet bleiben.',
            submits: {
                login: 'Anmelden',
                register: 'Registrieren'
            },
            password_forgot: 'Passwort vergessen?',
            email_placeholder: 'E-Mail Adresse',
            password_placeholder: 'Passwort',
            register_checkbox_pre_hint: (CCTLD: string) => `Mit der Registrierung auf Exporo.${CCTLD} erkläre ich mich einverstanden:`,
            checkbox_exporo_ag: (href1: string, href2: string, href3: string) => `Hiermit willige ich ein, Informationen über Kapitalanlagen, Projekt-Updates sowie plattformrelevante Informationen per Newsletter zu erhalten. Diese freiwillige <a href="${href1}" target="_blank">Einwilligung</a> kann ich jederzeit widerrufen. Zusätzlich stimme ich den <a href="${href2}" target="_blank">AGB</a> und der <a href="${href3}" target="_blank">Datenschutzerklärung der Exporo AG</a> zu.`,
            checkbox_exporo_investment_gmbh: (href1: string, href2: string, href3: string) => `Hiermit willige ich ein, Informationen über Kapitalanlagen, Projekt-Updates sowie plattformrelevante Informationen per Newsletter zu erhalten. Diese freiwillige <a href="${href1}" target="_blank">Einwilligung</a> kann ich jederzeit widerrufen. Zusätzlich stimme ich den <a href="${href2}" target="_blank">AGB</a> und der <a href="${href3}" target="_blank">Datenschutzerklärung der Exporo Investment GmbH</a> zu.`,
            register_checkbox_suf_hint_1: 'Mit den Bestimmungen zu Cookies & Internet-Werbung erkläre ich mich einverstanden.',
            register_checkbox_suf_hint_2: 'Die Verwaltung der personenbezogenen Daten erfolgt weiterhin durch die Exporo AG.',
            success_text: `<h1>FAST FERTIG</h1><p class="description">Vielen Dank für Ihr Interesse. Sie werden in Kürze eine E-Mail von Exporo erhalten. Bitte klicken Sie auf den darin enthaltenen Link, um die Registrierung abzuschließen.</p>`,
            error_password: 'Bitte stellen Sie sicher, dass Sie die richtigen Logindaten eingeben.',
        },
        passwordChange: {
            title: 'Passwort ändern',
            current_password_placeholder: 'Aktuelles Passwort',
            new_password_placeholder: 'Neues Passwort',
            repeat_password_placeholder: 'Neues Passwort bestätigen',
            new_password_hint: `<p> Verwenden Sie kein Passwort, welches Sie bereits in der Vergangenheit benutzt haben.
                        <br> Ihr Passwort muss mind. 8 Zeichen lang sein und folgende Zeichen enthalten:
                    </p>

                    <ul>
                        <li>einen Großbuchstaben</li>
                        <li><b>und</b> einen Kleinbuchstaben</li>
                        <li><b>und</b> eine Ziffer <b>oder</b> ein Sonderzeichen</li>
                    </ul>
                    <p>
                        Vermeiden Sie leicht zu erratende Bestandteile, wie Namen von Familienmitgliedern und Geburts- oder Adressdaten.
                    </p>`,
            submit: 'Passwort ändern',
            error_messages: {
                current_password_required: 'Bitte füllen Sie das Feld \'Aktuelles Password\' aus',
                new_password_required: 'Bitte füllen Sie das Feld \'Neues Password\' aus',
                current_password_check_failed: 'Bitte geben Sie ihr aktuelles Passwort ein.',
                password_used_before: 'Sie haben das eingegebene Password bereits in der Vergangenheit benutzt. Vergeben sie bitte ein neues Passwort.',
                password_lacks_complexity: 'Verwenden Sie in ihrem Passwort mind. 8 Zeichen und einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer oder ein Sonderzeichen.',
                new_password_confirmation_failed: 'Neues Passwort stimmt nicht mit der Bestätigung überein.'
            }
        },
        passwordForget: {
            title: 'Passwort zurücksetzen',
            submit: 'Passwort festlegen',
            login: 'Anmelden?'
        },
        passwordReset: {
            title: 'Passwort festlegen',
            success: 'Passwort erfolgreich geändert.',
            password_label: 'Passwort',
            repeat_password_label: 'Passwort wiederholen',
            new_password_hint: `<p> Verwenden Sie kein Passwort, welches Sie bereits in der Vergangenheit benutzt haben.
                        <br> Ihr Passwort muss mind. 8 Zeichen lang sein und folgende Zeichen enthalten:
                    </p>

                    <ul>
                        <li>einen Großbuchstaben</li>
                        <li><b>und</b> einen Kleinbuchstaben</li>
                        <li><b>und</b> eine Ziffer <b>oder</b> ein Sonderzeichen</li>
                    </ul>
                    <p>
                        Vermeiden Sie leicht zu erratende Bestandteile, wie Namen von Familienmitgliedern und Geburts- oder Adressdaten.
                    </p>`,
            submit: 'Passwort festlegen',
        },
        activation: {
            title: 'Anmeldung vervollständigen',
            male: 'Herr',
            female: 'Frau',
            first_name: 'Vorname',
            last_name: 'Nachname',
            phone: 'Telefonnr.',
            phone_mobile: 'Mobilfunknr.',
            password: 'Passwort',
            repeat_password: 'Passwort wiederholen',
            affiliated_partner_id: 'Empfohlen von (optional)',
            save: 'Speichern',
            error: 'Bitte geben Sie eine gültige Partner-ID ein.',
            toast_error: 'Sie verwenden einen falschen Aktivierungslink.',
            phone_error: 'Bitte füllen Sie das Feld \'Telefonnr.\' aus.',
            phone_mobile_error: 'Bitte füllen Sie das Feld \'Mobiltelefonnr.\' aus.',
            password_lacks_complexity: 'Verwenden Sie in ihrem Passwort mind. 8 Zeichen und einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer oder ein Sonderzeichen.',
            format_phone: 'Format: +49 (0)40 1234567',
            format_mobile_phone: 'Format: +49 (0)170 1234567'
        },
        submit_feedback: {
            title: 'Teilen Sie uns Ihr Problem mit.',
            subtitle: 'Ein Bericht mit allen notwendigen Daten wird an uns gesendet.',
            label_submit: 'Bericht senden',
            success_message: 'Wir haben Ihr Problem erhalten, vielen Dank für Ihr Feedback',
        },
    },
    checkout: {
        employment_status: {
            branche: {
                title: 'Branche',
                self_employed_occupations: {
                    landwirtschaft: 'Landwirtschaft',
                    bergbau: 'Bergbau',
                    glasswaren: 'Glasswaren',
                    kraftfahrzeug_branche: 'Kraftfahrzeug Branche',
                    energieversorgung: 'Energieversorgung',
                    wasserversorgung_entsorgung: 'Wasserversorgung, Entsorgung',
                    hochbau_bau_von_gebaeuden: 'Hochbau, Bau von Gebäuden',
                    handel_mit_kfz_u_service: 'Handel mit Kfz u. Service',
                    einzelhandel: 'Einzelhandel',
                    verkehrsdienstleistungen: 'Verkehrsdienstleistungen',
                    gastronomie: 'Gastronomie',
                    informationsdienstleistungen: 'Informationsdienstleistungen',
                    grundstuecks_u_wohungswesen: 'Grundstücks- u. Wohungswesen',
                    freiberuf_wissenschaftl_taetigkeiten: 'Freiberuf. wissenschaftl. Tätigkeiten',
                    wirtschaftliche_dienstleistungen: 'Wirtschaftliche Dienstleistungen',
                    oeffentliche_verwaltung: 'Öffentliche Verwaltung',
                    erziehung_u_unterricht: 'Erziehung u. Unterricht',
                    gesundheitswesen: 'Gesundheitswesen',
                    kuenstlerische_taetigkeiten: 'Künstlerische Tätigkeiten',
                    dienstleist_sport_unterhaltung: 'Dienstleist. - Sport, Unterhaltung'
                },
            },
            job_category: 'Berufsgruppe',
            employmentStatusOptions: {
                employee: 'Angestellte(r)',
                pensioner: 'Rentner/Pensionär',
                privateer: 'Privatier',
                public_official: 'Beamte(r)',
                self_employed: 'Selbstständig',
                student: 'Student/Auszubildender',
            },
            exploration_warning: 'Wir haben von Ihnen nicht ausreichende Informationen erhalten, um die Angemessenheit der Kapitalanlage prüfen zu können. Es besteht daher das Risiko, dass Sie eine für Sie nicht angemessene Kapitalanlage erwerben, deren Risiken Sie nicht beurteilen können.',
            job_education: {
                title: 'Beruf & Bildung',
                current_job: {
                    label: 'Aktuell ausgeübter Beruf',
                    data: {
                        asset_manager: 'Asset Manager',
                        finanzberater: 'Finanzberater',
                        finanzmakler: 'Finanzmakler',
                        finanzvermittler: 'Finanzvermittler',
                        investment_banker: 'Investment Banker',
                        wirtschaftspruefer: 'Wirtschaftsprüfer',
                        bankangestellter: 'Bankangestellter',
                        leitende_funktion_controlling: 'Leitende Funktion Controlling',
                        leitende_funktion_finanzabteilung: 'Leitende Funktion Finanzabteilung',
                        steuerberater: 'Steuerberater',
                        kfm_angestellter: 'kfm. Angestellter',
                        handwerker: 'Handwerker',
                        rentner_pensionaer: 'Rentner/Pensionär',
                        student_auszubildender: 'Student/Auszubildender',
                        sonstige: 'Sonstige Berufe außerhalb des Finanzwesens',
                    },
                },
                past_job: {
                    label: 'Früherer Beruf',
                    data: {
                        asset_manager: 'Asset Manager',
                        finanzberater: 'Finanzberater',
                        finanzmakler: 'Finanzmakler',
                        finanzvermittler: 'Finanzvermittler',
                        investment_banker: 'Investment Banker',
                        wirtschaftspruefer: 'Wirtschaftsprüfer',
                        bankangestellter: 'Bankangestellter',
                        leitende_funktion_controlling: 'Leitende Funktion Controlling',
                        leitende_funktion_finanzabteilung: 'Leitende Funktion Finanzabteilung',
                        steuerberater: 'Steuerberater',
                        kfm_angestellter: 'kfm. Angestellter',
                        handwerker: 'Handwerker',
                        rentner_pensionaer: 'Rentner/Pensionär',
                        student_auszubildender: 'Student/Auszubildender',
                        sonstige: 'Sonstige Berufe außerhalb des Finanzwesens',
                    },
                },
                educational_achievement: {
                    label: 'Höchster Bildungsabschluss',
                    data: {
                        promotion_habilitation: 'Promotion/Habilitation',
                        hochschulabschluss: 'Hochschulabschluss',
                        kaufmaennische_berufsausbildung: 'Kaufmännische Berufsausbildung',
                        fachhochschul_hochschulreife: 'Fachhochschul- oder Hochschulreife',
                        sonstige_berufsausbildung: 'Sonstige Berufsausbildung',
                        haupt_volks_realschulabschluss: 'Haupt-/Volks-/Realschulabschluss',
                        keiner_der_genannten_abschluesse: 'keiner der genannten Abschlüsse',
                    },
                }
            }
        },
        investments: {
            subordinate_investments: 'Nachrangige Finanzanlagen (z.B. partiarische Nachrangdarlehen)',
            equity_interest: 'Eigenkapitalbeteiligungen (z.B. geschlossene Investmentfonds)',
            shares: 'Aktien',
            bonds: 'Anleihen, Zertifikate',
            open_stock: 'Offene Fonds',
            knowledge: {
                label: 'Kenntnisse vorhanden?',
                value_1: 'ja',
                value_2: 'nein',
            },
            how_long: {
                label: 'Wie lange haben Sie Erfahrung mit dieser Art von Investments?',
                options: {
                    upto_3: 'bis 3 Jahre',
                    btw_3_5: '3 - 5 Jahre',
                    more_than_5: 'über 5 Jahre',
                },
            },
            how_much: {
                label: 'Welche durchschnittliche Höhe hatte ihr Investment bislang?',
                options: {
                    upto_2500: 'Bis 2.500€',
                    upto_5000: 'Bis 5.000€',
                    upto_10500: 'Bis 10.500€',
                    more_than_10500: 'Über 10.500€'
                },
            },
            how_many: {
                label: 'Wie viele Transaktionen veranlassen Sie durchschnittlich pro Jahr?',
                options: {
                    upto_5: 'Bis 5',
                    btw_5_10: '5 - 10',
                    more_than_10: 'Über 10'
                }
            },
        },
        investor: {
            title: 'Wer investiert?',
            edit_user: 'Bearbeiten',
            information: ' (In Bearbeitung)',
            add_investor: 'Weiteren Investor hinzufügen',
        },
        action_code: {
            label: 'Aktionscode eingeben',
            title: 'Ihr Aktionscode',
            placeholder: 'Aktionscode',
            reuse_iban: 'Die gleiche IBAN verwenden',
            not_reuse_iban: 'Sie können eine andere IBAN für die Auszahlung des Aktionscodes eingeben:',
            iban_alert: 'Für die Auszahlung des Aktionscodes wird eine IBAN benötigt:',
            iban_input: 'IBAN für Aktionscodes',
            error_message: 'Ihre Eingabe konnte nicht ausgewertet werden. Bitte versuchen Sie es erneut.',
            error_messages: {
                'action_code_not_found': 'Der Aktionscode existiert nicht.',
                'action_code_max_per_user': 'Sie haben diesen Aktionscode bereits verwendet.',
                'action_code_only_first_signing': 'Der Aktionscode ist nur für das erste Investment gültig.',
                'action_code_only_follow_up_signing': 'Der Aktionscode ist nur für Folgeinvestments gültig.',
                'action_code_product_type': 'Der Aktionscode kann für dieses Projekt nicht angewendet werden.',
                'action_code_only_project': 'Der Aktionscode kann für dieses Projekt nicht angewendet werden.',
                action_code_min_investsum: (value: string) => `Dieser Code ist ab einer Mindest-Investmentsumme von ${value} EUR gültig.`,
                'action_code_not_expired': 'Der Aktionscode ist nicht mehr gültig.',
                'action_code_is_not_coupon': 'Dieser Aktionscode ist nicht mehr gültig. Nehmen Sie bei Fragen gerne Kontakt zu uns auf unter <a href="tel:+4940210917300">040 210 91 73 00</a> oder per Mail an: <a href="mailto:support@exporo.com">support@exporo.com</a>.',
            }
        },
        coupon: {
            label: 'Zusätzliches Investment:',
            confirmation: 'Bestätigen',
        },
        modal_controls: {
            back: 'Zurück',
            next: 'Weiter',
        },
        bond_store_success: {
            headline: 'INVESTMENT',
            subline: (title: string, name: string) => `<p class="subline">${title} ${name}, herzlichen Glückwunsch.<br> Sie haben Ihr Zeichnungsangebot erfolgreich abgegeben!</p>`,
            description: `<p>Zur Einhaltung der rechtlichen Anforderungen zur Bekämpfung von Geldwäsche sind wir dazu verpflichtet, Ihre Identität zu prüfen. Dieser Vorgang erfolgt ausschließlich bei Ihrem ersten Investment in eine Anleihe auf Exporo.</p> <p>Hierzu arbeiten wir mit IDnow zusammen, die es Ihnen ermöglichen, sich per Video-Ident zu identifizieren. Dieser Service ist für Sie einfach, sicher und kostenfrei.</p>`,
            suggestion: 'Hinweis: Bitte halten Sie Ihr Legitimationsdokument bereit.',
            ident_button: 'Jetzt identifizieren',
            ident_content: 'Zur Einhaltung der rechtlichen Anforderungen zur Bekämpfung von Geldwäsche sind wir dazu verpflichtet, Ihre Identität zu prüfen. Dieser Vorgang erfolgt ausschließlich bei Ihrem ersten Investment in eine Anleihe auf Exporo und ist für Sie einfach, sicher und kostenfrei.',
            ident_suggestion: 'Hinweis: Bitte halten Sie Ihr Legitimationsdokument bereit.',
            ident_problem: 'Sie haben Probleme mit der Identifizierung? Kontaktieren Sie uns per E-Mail an investment@exporo.de oder von Mo - Fr, 10 bis 17 Uhr unter der Telefonnummer 040 / 228 686 99 - 0.',
            show_projects: 'Weitere Projekte anzeigen',
            host_button: 'Zu meinem Investmentcockpit',
            salutation_male: 'Lieber Herr',
            salutation_female: 'Liebe Frau',
            recruit_friends: 'Jetzt Freunde werben',
            recruit_friends_hint: 'Sichern Sie sich jetzt 100 € für jede erfolgreiche Weiterempfehlung!',
        },
        VermAnlG: {
            headline: 'Investment',
            subline: 'Investieren Sie noch heute in das Projekt: ',
            exploration: {
                title: 'Welche Erfahrungen und Kenntnisse haben Sie?',
                description: 'Als Vermittler von Vermögensanlagen sind wir gesetzlich verpflichtet, Informationen über Ihre Erfahrungen und Kenntnisse in Bezug auf Geschäfte mit bestimmten Arten von Finanzanlagen und über Ihre Ausbildung und berufliche Tätigkeiten einzuholen. Die Erteilung der nachfolgenden Angaben ist für Sie freiwillig und liegt in Ihrem eigenen Interesse.',
                perform: 'Ich möchte die Angemessenheitsprüfung durchführen lassen.',
                not_perform: 'Ich möchte die Angemessenheitsprüfung nicht durchführen lassen.',
                description_hint_1: 'Wir sind gesetzlich verpflichtet, Sie darauf hinzuweisen, dass uns keine ausreichenden Informationen vorliegen, um beurteilen zu können, ob Sie über ausreichende Kenntnisse und Erfahrungen verfügen, um die Risiken des beabsichtigten Geschäfts angemessen beurteilen zu können.',
                description_hint_2: 'Leider haben Sie die Fragen nicht, bzw. nicht vollständig ausgefüllt. Sollten Sie den Investmentprozess fortsetzen und die Vermögensanlage zeichnen, weisen wir Sie hiermit darauf hin, dass wir die gesetzlich geforderte Angemessenheitsprüfung nicht haben durchführen können. Wir können daher nicht beurteilen, ob die angebotene Vermögensanlage im Hinblick auf Ihre Kenntnisse und Erfahrungen für Sie angemessen ist. Es besteht daher das Risiko, dass Sie eine für Sie nicht angemessene Vermögensanlage erwerben, deren Risiken Sie nicht angemessen beurteilen können.',
            },
            VIB: {
                vib_text_a: 'Ich habe den gesetzlichen Warnhinweis im Vermögensanlagen-Informationsblatt zur Kenntnis genommen und bestätige diesen durch die Eingabe meiner Daten. Dieser lautet: „Der Erweb dieser Vermögensanlage ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.',
                vib_text_b: 'Ich habe den gesetzlichen Warnhinweis unter Ziffer 1. im Vermögensanlagen-Informationsblatt zur Kenntnis genommen. Der Warnhinweis lautet: `Der Erwerb dieser Vermögensanlage ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.´ Durch das Ankreuzen dieses Bestätigungsvermerks und die nachstehende Eingabe meiner Daten bestätige ich die Kenntnisnahme.',
                vib_download: 'Vermögensanlageninformationsblatt downloaden',
                legal_obligation_text_a: 'Ich habe den gesetzlichen Warnhinweis im Vermögensanlagen-Informationsblatt zur Kenntnis genommen und bestätige diesen durch die Eingabe meiner Daten. Dieser lautet: „Der Erweb dieser Vermögensanlage ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.',
                legal_obligation_text_b: 'Ich habe die gesetzlichen Pflichtangaben gelesen und ausgedruckt und/oder gespeichert.',
                legal_download: 'Gesetzliche Pflichtangaben downloaden',
                info_text: 'Info: Nach Anhaken der Bestätigungsvermerke werden Ihnen die oben genannten Dokumente per E-Mail zugeschickt.',
                information_container: {
                    title: 'Ihre Angaben',
                    edit: 'Bearbeiten',
                },
                information_right: {
                    born_at: 'geboren am ',
                },
                information: {
                    edit: 'Bearbeiten',
                },
                signature: {
                    description: 'Bitte geben Sie zur Bestätigung Ihren vollständigen Vor- und Zunamen, das heutige Datum und den Ort ein:',
                    placeholder: 'Vorname Nachname Datum Ort',
                    example: 'Beispiel: Max Mustermann 01.01.2017 Hamburg',
                },
            },
            investmentVermAnlG: {
                title: 'Wieviel möchten Sie investieren?',
                investsum: 'Betrag',
                small_investor: 'Soweit der Gesamtbetrag sämtlicher Vermögensanlagen, die ich bisher von dem Emittenten erworben habe und der Vermögensanlagen, die ich vorliegend in Höhe meines vorgenannten Investitionsbetrages von demselben Emittenten erwerbe, 1.000 € übersteigt, bestätige ich hiermit im Rahmen einer Selbstauskunft, (i) dass dieser Gesamtbetrag nicht den zweifachen Betrag meines durchschnittlichen monatlichen Nettoeinkommens und auch nicht 25.000 € übersteigt oder (ii) dass der Gesamtbetrag 10.000 € nicht übersteigt und dass ich über ein frei verfügbares Vermögen in Form von Bankguthaben und Finanzinstrumenten in Höhe von mindestens 100.000 € verfüge.',
                payment_method: 'Bezahlart',
                debit: 'Lastschrift',
                prepay: 'Überweisung',
                ibanbic: 'IBAN',
                account_number: 'Konto/BLZ',
                bank_account: 'Ihre Bankverbindung:  ',
                use_no_bank_account: 'Andere Bankverbindung verwenden: ',
                data_should_be_saved: 'Meine Bankverbindung für mein nächstes Investment verschlüsselt speichern.',
                invest_iban: 'Kontonummer',
                invest_bic: 'Bankleitzahl',
                debit_mandate: 'Ich ermächtige die secupay AG, Goethestr. 6, 01896 Pulsnitz, einmalig eine Zahlung von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der secupay AG auf mein Konto gezogene Lastschrift einzulösen. Diese Lastschrift dient zum Einzug in Höhe des oben ausgewählten Investmentbetrages. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',
                email_confirmation: 'Die Kontodaten werden Ihnen mit den Vertragsunterlagen per E-Mail zugeschickt.',
            },
            summary: {
                description: {
                    hint: 'Hinweis',
                    text: 'Die nachfolgenden Daten sind aus Ihrer vorangegangenen Registrierung übernommen. Bitte prüfen Sie die Richtigkeit der Daten bzw. passen Sie sie entsprechend an und schließen Sie die Transaktion bei Richtigkeit aller Daten durch Anklicken von "Jetzt zahlungspflichtig investieren" ab.',
                },
                project: 'Projekt: ',
                interest: 'Verzinsung: ',
                additional_interest: 'Zusatzzins: ',
                investsum: 'Investitionssumme: ',
                download: (item) => `${item} downloaden`,
                i_have_read_all_docs: 'Ich bestätige das Vermögensanlagen-Informationsblatt und die gesetzlichen Pflichtangaben per E-mail empfangen, gespeichert und gelesen zu haben, und akzeptiere diese hiermit.',
                dsgvo_secupay_hint: 'Hiermit willige ich ein, dass meine Daten im Zuge des Vertragsschlusses verarbeitet und an unseren Zahlungsdienstleister SECUPAY weitergegeben werden.\n',
            },
            store_success: {
                headline: 'Erfolgreich investiert',
                description: 'Um Ihnen die Möglichkeit zu geben, in das gesamte Produkt-Universum der Exporo-Gruppe zu investieren, empfehlen wir Ihnen sich noch heute persönlich zu identifizieren.',
                ident_required_description: 'Zum Abschluss Ihres Investments ist eine Identifizierung nötig. Dies können Sie einfach und digital in wenigen Minuten über unseren Identifizierungsdienstleister tun. Klicken Sie dazu einfach auf diesen Link:',
                ident_button: 'Jetzt kostenfrei identifizieren',
            },
        },
        WpHGLite: {
            headline: 'Investment',
            subline: 'Investieren Sie noch heute in das Projekt:',
            no_available_pieces: 'Keine Anleihen mehr verfügbar!',
            explorationWpHGLite: {
                title: 'Welche Erfahrungen und Kenntnisse haben Sie?',
                subtitle: 'Wir, als die Exporo Investment GmbH, sind Vermittlerin von Wertpapieren und somit verpflichtet, Informationen über Ihre Erfahrungen und Kenntnisse in Bezug auf Geschäfte mit bestimmten Arten von Finanzanlagen und über Ihre Ausbildung und berufliche Tätigkeiten einzuholen. Die Exporo Investment GmbH tätigt ausschließlich eine Vermittlung und erbringt keine Anlageberatung. Daher kann die Exporo Investment GmbH nicht beurteilen, ob die dargestellten Kapitalanlagen Ihren Anlagezielen entsprechen. Unsere Kunden werden grundsätzlich als Privatkunden eingestuft.',
                availableInvestments: (availableInvestments: number) => `Es gibt nur noch ${availableInvestments} Anleihen zu zeichnen.`,
                no_availableInvestments: 'Es gibt keine Anleihen mehr zu zeichnen.',
                nextButtonText_1: 'Jetzt Depot eröffnen und Zeichnungsangebot abgeben',
                nextButtonText_2: 'Jetzt Zeichnungsangebot abgeben',
                nextButtonText_3: 'Jetzt Depot eröffnen und Zeichnungsangebot abgeben',
                exploration_hint: {
                    state_0: 'Wir haben von Ihnen nicht ausreichende Informationen erhalten, um die Angemessenheit der Kapitalanlage prüfen zu können. Es besteht daher das Risiko, dass Sie eine für Sie nicht angemessene Kapitalanlage erwerben, deren Risiken Sie nicht beurteilen können.',
                    state_1: 'Vielen Dank für Ihre vorstehenden Angaben. Auf Basis dieser Angaben haben wir eine Angemessenheitsprüfung durchgeführt. Sollten Sie den Investmentprozess fortsetzen und die Kapitalanlage zeichnen, weisen wir Sie hiermit darauf hin, dass die Angemessenheitsprüfung ergeben hat, dass die angebotene Kapitalanlage angesichts der mitgeteilten Kenntnisse und Erfahrungen für Sie nicht angemessen ist. Es besteht daher das Risiko, dass Sie eine für Sie nicht angemessene Kapitalanlage erwerben, deren Risiken Sie nicht beurteilen können.',
                },
                checkbox: 'Ich habe den Hinweis verstanden und möchte dennoch zeichnen',
            },
            investmentWpHGLite: {
                title: 'Ihr Investment',
                description: (formatPrice: any) => `Tragen Sie hier Ihre gewünschte Stückzahl ein. Ihre Investitionssumme wird automatisch anhand des Nennbetrags von ${formatPrice} € berechnet:`,
                pieces: 'Stück entspricht',
                sum: ' € Investitionssumme',
                remaining_pieces: 'Verbleibende Stückzahl: ',
                single_unit_threshold: 'Soweit der Gesamtbetrag sämtlicher Wertpapiere, die ich bisher von der Emittenten erworben habe und der Wertpapiere, die ich vorliegend in Höhe meines vorgenannten Investitionsbetrages von derselben Emittentin erwerbe, 1.000 € übersteigt, bestätige ich hiermit im Rahmen einer Selbstauskunft, (i) dass dieser Gesamtbetrag nicht den zweifachen Betrag meines durchschnittlichen Nettoeinkommens und auch nicht 10.000 € übersteigt oder (ii) dass der Gesamtbetrag 10.000 € nicht übersteigt und dass ich über ein frei verfügbares Vermögen in Form von Bankguthaben und Finanzinstrumenten in Höhe von mindestens 100.000 € verfüge.',
                payout_form: {
                    label: 'Wählen Sie hier Ihre gewünschte Bezahlart:',
                    investtype: 'Bezahlart',
                    type_debit: 'Lastschrift',
                    type_prepay: 'Überweisung (Vorkasse)',
                    investment_hint: {
                        hint_a: 'Bitte hinterlegen Sie Ihre IBAN zum Lastschrifteinzug. Zukünftige Rendite-Ausschüttungen werden diesem Konto gutgeschrieben.',
                        hint_b: 'Nach Annahme Ihres Zeichnungsauftrags werden Ihnen die Bankdaten per E-Mail zugeschickt. Bitte hinterlegen Sie Ihre IBAN für zukünftige Rendite-Ausschüttungen.',
                    },
                },
                checkbox: {
                    church_tax_deduction: 'Ich wünsche, dass das Kirchensteuerabzugsmerkmal (KiStAM) im Rahmen der Kontoeröffnung beim Bundeszentralamt für Steuern abgerufen wird (Anlassabfrage). Infolge der gesetzlichen Änderung der § 51a, 52a EStG müssen ab dem 1.1.2015 neben Banken und Kreditinstituten u.a. auch alle Kapitalgesellschaften im Zuge einer Ausschüttung die Kirchensteuerpflicht der Empfänger der Kapitalerträge ermitteln und die Kirchensteuer auf die Abgeltungsteuer automatisch einbehalten und an die steuererhebenden Religionsgemeinschaften abführen.'
                },
                info_text: 'Info: Nach Anhaken der Bestätigungsvermerke zum Wertpapierprospekt und zu den Kundeninformationen werden Ihnen diese beiden Dokumente per E-Mail zugeschickt.',
                info_text_financing: 'Info: Nach Anhaken der Bestätigungsvermerke zum Wertpapierinformationsblatt und zu den Kundeninformationen werden Ihnen diese beiden Dokumente per E-Mail zugeschickt.',
            },
            summaryWpHGLite: {
                title: 'Zeichnung',
                label: 'Hinweis:',
                description: 'Die nachfolgenden Daten sind aus Ihrer vorangegangenen Registrierung übernommen. Bitte prüfen Sie die Richtigkeit der Daten bzw. passen Sie sie entsprechend an und schließen Sie die Transaktion bei Richtigkeit aller Daten durch Anklicken von "Jetzt zahlungspflichtig investieren" ab.',
                information: {
                    headline: 'Investor',
                    user_edit: 'Daten bearbeiten',
                },
                information_right: {
                    headline: 'Projekt:',
                    project_name: 'Projektname:',
                    wkn: 'WKN:',
                    isin: 'ISIN:',
                    invest_sum: 'Investitionssumme:',
                    payment_method: 'Bezahlart:',
                    debit: 'Lastschrift',
                    transfer: 'Überweisung',
                },
                risk_hint: (email: string) => `Vor Abschluss Ihres Investments sollten Sie die angebotenen Dienstleistungen und die damit verbundenen Risiken verstehen. Nachfolgend finden Sie hierzu Informationsmaterial zum Herunterladen. Diese Informationen werden Ihnen auch per E-Mail an ${email} zugesandt.`,
                accept_to_save: 'Ich/wir bestätigen, die folgenden Unterlagen auf einem dauerhaften Datenträger (in Form eines PDF-Anhangs per E-Mail) für meine/unsere Unterlagen erhalten zu haben:',
                information_container: {
                    title: 'Zum Herunterladen',
                    label: 'Exporo Investment GmbH (Vermittler)',
                    protocol: 'Protokoll der Angemessenheitsprüfung',
                    information_right: 'Baader Bank (Depotbank)',
                },
            },
        }
    },
    userEdit: {
        title: 'IHRE PERSÖNLICHEN DATEN',
        sub_title: 'Ändern Sie hier Ihre persönlichen Daten.',
        tebmenu: {
            data: 'Ihre Daten',
            wallet: 'Digitales Schließfach',
            partner: 'Partner',
            admin: 'Admin'
        },
        countries: {
            DE: 'Deutschland',
            AT: '\u00d6sterreich',
            NL: 'Niederlande',
            CH: 'Schweiz',
            FR: 'Frankreich',
            HU: 'Ungarn',
            IE: 'Irland',
            LU: 'Luxemburg'
        },
        link_change_name: 'https://exporo.de/kontakt/',
        description_change_name: 'Bei Namenänderungen nehmen Sie bitte Kontakt zu unserem Kundensupport auf',
        legitimation_document_hint: `Bei der Wahl des Reisepass als Identifikationsdokument benötigen Sie zur Depoteröffnung eine <b>Meldebescheinigung</b>. Dadurch kann sich der Depoteröffnungsprozess verzögern. Wir empfehlen die Identifikation mit dem Personalausweis.`,
        pass: 'Ausweis-Nr.',
        pass_created_at: 'Ausstellungsdatum',
        pass_expired_at: 'Gültig bis',
        pass_authority: 'Ausstellende Behörde',
        pass_country_of_issue: 'Ausstellungsland',
        street_and_no: 'Strasse und Hausnr.',
        street_additional: 'Adresszusatz',
        miles_and_more: 'Miles & More Servicekartennr.',
        miles_tooltip: 'Wir bieten allen Miles & More Teilnehmern, die erstmalig auf Exporo.de investieren, die Möglichkeit sich einen attraktiven Bonus von bis zu 15.000 Prämienmeilen für Ihr erstes Investment zu sichern.',
        tax_id: 'Steuer-ID',
        tax_nr: 'Steuernr. (TIN)',
        tax_tooltip_at: 'Die Steuernummer in Österreich ist eine neunstellige Nummer. Die Steuernummer steht links oben auf dem Einkommensteuerbescheid. Sollte noch kein Einkommenssteuerbescheid vorliegen, kann ein Anruf beim zuständigen Finanzamt getätigt werden. Dieses sollte dann Auskunft erteilen können. Sollte beim Finanzamt keine Steuernr. (TIN) vorliegen, kann diese direkt beim zuständigen Finanzamt beantragt werden.',
        tax_tooltip_de: 'Die Steuer-ID (Steuer-Identifikationsnummer) ist eine elfstellige Nummer, die jeder steuerlich erfasste Bürger in Deutschland erhält. Die Steuer-ID wird auch als ”Persönliche Identifikationsnummer”, ”Identifikationsnummer” oder auch TIN (Tax Identification Number) bezeichnet. In der Regel finden Sie Ihre Steuer-ID in Ihrem Einkommensteuerbescheid oder auf Ihrer Lohnsteuerbescheinigung unter einer der oben genannten Bezeichnungen. Sollten Sie keinen Einkommenssteuerbescheid vorliegen haben, können Sie Ihre Steuer-ID beim zuständigen Finanzamt erfragen.',
        tax_not_used_tooltip: 'Aufgrund des in 2010 erlassenen "FATCA"-Gesetzes müssen U.S.-Personen jährlich ihre Finanzkonten außerhalb der USA melden. Gleichzeitig werden Finanzinstitute außerhalb der USA verpflichtet, U.S.-Personen zu erfassen. Aufgrund der im FACTA definierten Anforderungen können Kunden, welche der US-Steuerpflicht unterliegen, kein Investment auf Exporo tätigen.',
        tax_not_used_short: 'Aufgrund der im FACTA definierten Anforderungen können Kunden, welche der US-Steuerpflicht unterliegen, kein Investment auf Exporo tätigen.',
        pep: 'Ich bin eine politisch exponierte Person (PEP).',
        not_us_taxed: 'Ich bin nicht US-Steuerpflichtig.',
        pep_tooltip: 'Eine politisch exponierte Person (PEP) ist eine Person, die entweder selbst ein hochrangiges wichtiges öffentliches Amt (z.B. Politiker, Botschafter, Richter oberster Gerichte) ausübt bzw. ausgeübt hat oder eine Person aus deren unmittelbarem persönlichen Umfeld (z.B. Familienmitglieder oder nahestehende Personen).',
        pep_hint: `Als politisch exponierte Person (PEP) können Sie bestimmte Produkte erst nach genauerer Prüfung zeichnen. Bitte kontaktieren Sie zuerst unseren Support: <br><br> Support: <br> E-Mail: support@exporo.com <br> Telefon: 040 / 210 91 73 - 00`,
        wallet_description: 'Über das Schließfach können Sie in Echtzeit Ihre Investments in Exporo Bestandsimmobilien abrufen und verwalten. Diesen Service können wir ausschließlich für unsere neuen digitalen Wertpapiere anbieten.',
        wallet_link: 'Digitales Schließfach öffnen',
        success_text: 'Daten erfolgreich aktualisiert.',
        months: {
            year: 'Jahr',
            jan: 'Januar',
            feb: 'Februar',
            mar: 'März',
            apr: 'April',
            may: 'Mai',
            jun: 'Juni',
            jul: 'Juli',
            aug: 'August',
            sep: 'September',
            oct: 'Oktober',
            nov: 'November',
            dec: 'Dezember',
        },
        drop_down_values: {
            legitimation_document: {
                id_card: 'Personalausweis',
                passport: 'Reisepass'
            }
        }
    },
    cockpit: {
        title: 'Investmentcockpit',
        cockpit_loading_hint: 'Ihr Investmentcockpit wird geladen...',
        close_cockpit: 'Investmentcockpit verlassen',
        customer_nr: 'Kunden-Nr: ',
        tabbar: {
            investplan: 'Investmentplan',
            portfolio: 'Bestand',
            financing: 'Finanzierung',
        },
        kpi: {
            historyPanel: {
                title: 'Investment Historie',
                id: 'Vertrags-ID',
                investment: 'Investment',
                date: 'Investiert am',
                payback: 'Nächste Ausschüttung',
                downloads: 'Downloads',
                documents: 'Dokumente',
                no_documents: 'Keine Dokumente vorhanden',
                received_payback: 'Bereits zurückgezahlt',
            },
        },
        menu: {
            title: 'Menü',
            friends: 'Freunde werben',
            documents: 'Dokumente',
            investors: 'Meine Investoren',
            edit_user: 'Persönliche Daten',
            password_change: 'Passwort ändern',
            questions: 'Sie haben eine Frage?',
            questions_url: 'https://exporo.de/kontakt/',
            contact_center: 'Benachrichtigungen',
            logout: 'Abmelden'
        },
        cockpitPanel: {
            more: 'Mehr',
            less: 'Weniger',
        },
        cockpitDropbar: {
            bond: 'Bestand',
            mezz: 'Finanzierung',
            invest_plan: 'Investmentplan',
        },
        cockpitTimeline: {
            title: 'Projekt-Updates',
            subtitle: 'Unsere Projekt-Updates informieren Sie regelmäßig zum Fortschritt Ihrer Projektfinanzierungen. Klicken Sie auf das jeweilige Projekt-Update, um weitere Informationen zu sehen.',
            no_update_hint: 'Unsere Projekt-Updates informieren Sie regelmäßig zum Fortschritt Ihrer Projektfinanzierungen. Die ersten Projekt-Updates erscheinen in den nächsten Wochen.'
        },
        detailView: {
            back_button: 'BACK',
        },
        ident: {
            title: 'Identifizierung',
            description: 'Um zukünftig in Exporo-Anleihen zu investieren ist eine persönliche Identifizierung notwendig.',
            successful: 'Vielen Dank! Sie haben sich erfolgreich identifiziert!',
            cta: 'Jetzt identifizieren',
            question_hint: 'Sie haben noch Fragen zur Identifizierung? Kontaktieren Sie uns per E-Mail an investment@exporo.de oder von Mo - Fr, 10 bis 17 Uhr unter der Telefonnummer 040 / 228 686 99 - 0.',
            states: {
                start: 'Identifizierung starten',
                open: 'Offen',
                running: 'In Bearbeitung',
                done: 'Identifiziert'
            }
        },
        investlist: {
            buy: 'Kaufen',
            details: 'Details',
            invest_menu: {
                label: 'Sortieren nach:',
            },
        },
        list_financing_investments: {
            headline: 'Ihre Projekte in Exporo Finanzierung',
            empty_state_message: {
                all: 'Sie haben aktuell keine Investments',
                ongoing: 'Sie haben aktuell keine laufenden Investments',
                disbursed: 'Sie haben aktuell keine ausgezahlten Investments',
            },
        },
        list_financing_payments: {
            headline: 'Ihre Auszahlungen',
            defaultActiveCol: 'Auszahlungsdatum',
            payback_started: 'In Verzug',
            empty_state_message: {
                all: 'Sie haben aktuell keine Auszahlungen',
                coming: 'Sie haben aktuell keine anstehenden Renditezahlungen',
                disbursed: 'Sie haben aktuell keine ausgezahlten Renditezahlungen',
            },
        },
        detailPanel: {
            buy_pieces: 'Anteile zum Kauf anbieten',
            reject_offer: 'Verkaufsangebot zurückziehen',
            not_yet_ready_to_trade: 'Projekt in Finanzierung',
            all_pieces_offered: 'Max. Stückzahl bereits angeboten',
            label: 'Handelsplatz',
            offered_pieces: 'Angebotene Stückzahl',
            piece: 'Stück',
        },
        pages: {
            portfolio: {
                kpi_title: 'Kennzahlen zu Ihren Investments in Exporo Bestand',
                keyfigures: {
                    equity_investments_length: 'Anzahl laufender Bestandsobjekte',
                    invested_total_equity_sum: 'Summe laufender Bestandsobjekte'
                },
                list_title: 'Ihr Exporo Bestand | Status: Erfolgreich eingebucht',
                list_undertitle: 'Die folgende Liste Ihrer Investments spiegelt Ihre eingebuchten Anteile in Ihrem Baader Bank Depot sowie Ihrem digitalen Schließfach wieder.',
                list_title_pending: 'Ihr Exporo Bestand | Status: In Bearbeitung',
                list_undertitle_pending: 'Ihre Investments werden nun in Ihr Wertpapierdepot bzw. digitales Schließfach (Wallet) eingebucht. Dieser Vorgang kann bei Erstinvestitionen bis zu zwei Wochen in Anspruch nehmen, je nach Status Ihrer persönlichen Identifikation, der Eröffnung Ihres Depots bei der Baader Bank bzw. Ihres digitales Schließfachs (Wallet) sowie der Transaktion Ihres Investmentbetrags. Den aktuellen Status quo können Sie der Statusleiste unter Ihrem Investment entnehmen.',
                pending_filter: {
                    pending: 'In Bearbeitung',
                    allInvestments: 'Alle Investments',
                },
                list_title_tooltip: 'Ihre Investments werden nun in Ihr Wertpapierdepot bzw. digitales Schließfach (Wallet) eingebucht. Dieser Vorgang kann bei Erstinvestitionen bis zu zwei Wochen in Anspruch nehmen, je nach Status Ihrer persönlichen Identifikation, der Eröffnung Ihres Depots bei der Baader Bank bzw. Ihres digitales Schließfachs (Wallet) sowie der Transaktion Ihres Investmentbetrags. Den aktuellen Status quo können Sie der Statusleiste unter Ihrem Investment entnehmen.',
                list_headers: {
                    project: 'Projekt',
                    investment: 'Summe',
                    expected_payout: 'Erw. Rendite p.a.',
                    next_payout: 'Nächste Ausschüttung',
                    already_received: 'Bereits Ausgeschüttet',
                    current_state: 'Status',
                    running_time: 'Laufzeit',
                    invested_at: 'Zeichnungsdatum',
                    type: 'Typ'
                },
                data_pending_type: {
                    bond_purchase: 'Anleihenkauf',
                    bond_purchase_secondary_market: 'Anleihenkauf (Handelsplatz)',
                },
                list_empty_states: {
                    running_investments: 'Sie haben aktuell keine laufenden Investments.',
                    paied_investments: 'Sie haben aktuell keine ausgezahlten Investments.',
                    filter_investments: 'Sie haben aktuell keine Investments für diesen Filter.'
                },
                landing: {
                    title: 'Exporo Bestand',
                    description: 'Mit Exporo Bestand können Sie schon ab 1.000 € von den Vorteilen des Immobilieneigentums profitieren - ohne Gebühren und Aufwand. Erfahren Sie im Video, wie aus einem bislang unflexiblen Wert mit Exporo Bestand eine handelbare Geldanlage in Immobilien wird, mit der Sie dauerhaft Vermögen aufbauen können.',
                    label: 'So funktioniert\'s',
                    text: 'Immobilien bei Exporo Bestand werden jeweils etwa zur Hälfte durch die Exporo Anleger und durch ein Bank-Darlehen finanziert. Das Beste an Exporo Bestand: Sie haben keinen Aufwand, müssen sich nicht um die Weiterentwicklung, Vermietung und Instandhaltung kümmern und sind grundsätzlich nicht an die Immobilie gebunden – denn: Sie können jederzeit Ihre Anteile auf dem Exporo Handelsplatz wieder veräußern.',
                    action_button: 'Zu den Projekten',
                },
                progress_bar: {
                    ident: {
                        not_done: 'Identifizierung starten',
                        done: 'Identifiziert'
                    },
                    depot: {
                        not_done: 'Depoteröffnung',
                        done: 'Depot eröffnet'
                    },
                    paid: {
                        not_done: 'Zahlung ausstehend',
                        done: 'Zahlung erfolgt'
                    },
                    booking: 'Einbuchung'
                }
            },
            disbursed: {
                headline: 'Ausgezahlte Renditezahlungen',
            },
            financing: {
                label: 'Kennzahlen zu Ihren Investments in Exporo Finanzierung',
                tabbar: {
                    investments: 'Investments',
                    projects: 'Projekte',
                    payments: 'Auszahlungen'
                },
                additionalEmptyText: 'Sie haben aktuell keine Auszahlungen.',
                keyfigures: {
                    count_running_investments: 'Anzahl laufender Investments',
                    sum_running_investments: 'Summe laufender Investments',
                    return_running_investments: 'Ø-Rendite p.a. laufender Investments'
                },
                list_headers: {
                    project: 'Projekt',
                    investment: 'Investment',
                    return_interest: 'Rendite p.a.',
                    investor: 'Investor',
                    running_time: 'Laufzeit',
                    invested_at: 'Investiert am',
                },
                list_headers_payments: {
                    id: 'ID',
                    project: 'Projekt',
                    payout_sum: 'Auszahlungssumme',
                    payout_date: 'Auszahlungsdatum',
                },
                filter: {
                    investments: {
                        ongoingInvestments: 'Aktive Projekte',
                        disbursedInvestments: 'Beendete Projekte',
                        allInvestments: 'Übersicht aller Projekte',
                    },
                    payments: {
                        comingPayments: 'Geplante Auszahlungen',
                        pastPayments: 'Vergangene Auszahlungen',
                        allPayments: 'Übersicht aller Auszahlungen',
                    }
                },
                landing: {
                    title: 'Exporo Finanzierung',
                    description: 'Mit dem digitalen Immobilieninvestment Exporo Finanzierung profitieren Sie schon ab 500€ Mindestanlage von den Vorteilen der Immobilienprojektentwicklung – ohne zusätzliche Kosten und ohne Aufwand.',
                    label: 'So funktioniert\'s',
                    text: 'Zur Realisierung von Immobilienprojekten benötigen Projektentwickler kurzfristig und viel Kapital, das sie aufgrund von gestiegenen Regulierungen nur zum Teil von den Banken erhalten. An dieser Stelle setzt Exporo Finanzierung ein. Bei Exporo Finanzierung Projekten schließen sich viele Investoren über die Exporo Plattform zusammen, um gemeinsam das Projekt mitzufinanzieren und aus den Projekterlösen eine attraktive Rendite zu erzielen.',
                    action_button: 'Zu Exporo Finanzierung',
                },
            },
            investplan: {
                title: 'Exporo Investmentplan',
                project: 'Projekt',
                interest: 'Zins',
                panel_title_projects: 'Investierte Projekte',
                panel_title_documents: 'Dokumente',
                kpis: {
                    id: 'Sparplan ID',
                    contributions_sum: 'Investierter Betrag',
                    contributions_sum_month: 'Monatliche Rate',
                    contributions_phase_starts_at: 'Beginn der Ratenzahlung',
                    interestrate_guaranteed: 'Grundverzinsung p.a.',
                    contributions_phase_ends_at: 'Ende der Ratenzahlung',
                    next_payout: 'Rückzahlung',
                    investor: 'Investor',
                    expected_interest_rate: 'Angestrebte Verzinsung p.a.'
                }
            },
            friends: {
                title: 'Freunde werben',
                description: 'Sichern Sie sich einen Bonus in Höhe von 100 EUR für jede erfolgreiche Empfehlung. Teilen Sie dazu Ihren persönlichen Empfehlungslink mit Freunden, Bekannten und Ihrer Familie.',
                share_link: 'Teilen Sie Ihren Link:',
                link: (uid: string) => `https://exporo.de/empfehlen/?friend_id=${uid}`,
                copy_link: 'Link kopieren',
                explanation: {
                    headline: 'So einfach funktioniert\'\s:',
                    step_headline_1: '1. Teilen',
                    step_text_1: 'Teilen Sie Ihren persönlichen Empfehlungslink mit Freunden, Bekannten und Ihrer Familie.',
                    step_headline_2: '2. Registrieren',
                    step_text_2: 'Nach erfolgreicher Registrierung auf Exporo.de, erhält Ihre Empfehlung einen 100 EUR Aktionscode für das erste Investment.',
                    step_headline_3: '3. Bonus erhalten',
                    step_text_3: 'Kommt es durch Ihre Empfehlung zu einem Investment, erhalten auch Sie einen 100 EUR Aktionscode für Ihr nächstes Investment.',
                    hint: `Hier finden Sie unsere Teilnahmebedingungen: <a href="https://exporo.de/teilnahmebedingungen-freunde-werben-freunde-aktion/" target="_blank">Teilnahmebedingungen</a>`
                }
            },
            documents: {
                title: 'Dokumente',
                quarterlyReports: 'Quartalsberichte zu Exporo Bestand',
                filesPayback: 'Zinsabrechnungen',
                depotFiles: 'Baader Bank - Depotunterlagen',
                explorationFiles: 'Exploration',
                documentsYearlyList: 'Steuerunterlagen',
                baseDataFiles: 'Änderungen Stammdaten Formular',
            },
            investors: {
                investor: 'Investor',
                title: 'Meine Investoren',
                label: 'Abbrechen',
                add_investor: 'Investor hinzufügen',
                investor_not_found_error: 'Investor existiert nicht.',
                form_hint: 'Legen Sie hier einen Investor an.',
                legal_entity_label: 'Juristische Person',
                legal_entity_checkbox: 'als juristische Person registrieren.',
                legal_entity_verification: 'Ich bestätige, gesetzlicher Vertreter der Gesellschaft zu sein.',
                entity_authorized_as: 'Vertretungsberechtigt als',
                entity_name: 'Gesellschaftsname',
                entity_type_of_company: 'Gesellschaftsform',
                entity_types: {
                    default: 'auswählen',
                    ag: 'AG',
                    ug: 'UG',
                    kg: 'KG',
                    gmbh: 'GmbH',
                    gmbhcokg: 'GmbH & Co. KG',
                },
                entity_commercial_register_number: 'Handelsregisternummer',
                entity_local_court: 'Zuständiges Amtsgericht',
                entity_tax_number: 'Steuernummer',
                entity_tax_office: 'Zuständiges Finanzamt',
                entity_commercial_register_file: 'Handelsregisterauszug',
                entity_shareholders_file: 'Gesellschafterliste',
                admin_hint: 'only editable for admins',
                entity_status: 'Bearbeitungsstatus',
                entity_status_open: 'in Bearbeitung',
                entity_status_accepted: 'Überprüft',
                submit_text_create: 'Anlegen',
                submit_text_update: 'Updaten'
            },
            contactCenter: {
                title: 'Benachrichtigungen',
                description: `Sie haben die Wahl: <br> Entscheiden Sie selbst, zu welchen Themen wir Sie informieren und über welche Kanäle wir Sie kontaktieren dürfen.`,
                advertisment_notification_control: 'Werbeerlaubnis'
            },
            detail: {
                documents_title: 'Dokumente',
                reportings_title: 'Bestandsreporting',
                reportings_title_current_development: 'Aktuelle Entwicklung',
                reportings_table_title: 'Quartalsüberschuss',
                reportings_hint_no_reportings: 'Hier finden Sie in Kürze detaillierte Berichte sowie weitere Informationen und Kennzahlen zur Entwicklung der Immobilie.',
                reportings_table_header: {
                    income_outcome: 'Einnahmen/Ausgaben',
                    rental_income: 'Mieteinnahmen',
                    rental_loss: 'Mietausfall',
                    operating_costs: 'Betriebskosten',
                    property_management: 'Hausverwaltung',
                    management_costs: 'Verwaltungskosten',
                    maintenance_cost: 'Instandhaltungskosten',
                    interest_payments: 'Zinszahlungen',
                    real_estate_result: 'Ergebnis der Immobilie(n)',
                    acquittance_payments: 'Tilgungszahlungen',
                    maintenance_reserve_funds: 'Instandhaltungsrücklage',
                    payout_euro: 'Ausschüttung in €',
                    payout_percent: 'Ausschüttung in %',
                    investor_capital: 'Anlegerkapital'
                },
                reportings_charts_title: '',
                reportings_charts: {
                    title_payout_euro: 'Ausschüttung pro Quartal [€]',
                    title_payout_percent: 'Ausschüttungsrendite (%)',
                    title_maintenance_reserve: 'Instandhaltungsrücklage (€)',
                    title_rental_income: 'Nettomieteinnahmen [€]',
                    series_a: 'Ist',
                    series_b: 'Geplant',
                },
                no_documents: 'Keine Dokumente vorhanden',
                kpis: {
                    signing_id: 'Vertrags ID',
                    created_at: 'Investiert am',
                    project: 'Projekt',
                    interest: 'Rendite p.a.',
                    isin: 'ISIN',
                    period: 'Laufzeit',
                    wkn: 'WKN',
                    investment: 'Investment',
                    expected_interest: 'Erw. Ausschüttungsrendite p.a.',
                    expected_total_interest: 'Erw. Gesamtrendite p.a.',
                    payoutdate: 'Laufzeitende',
                    payoutsum: 'Ausschüttungssumme',
                    investor: 'Investor',
                    pieces: 'Stückzahl',
                    piece_value: 'Nennwert eines Investments'
                }
            }
        }
    },
    secondaryMarket: {
        buyButton: {
            cta: 'Kaufen',
        },
        modalContainer: {
            no_depot_available: `Wir freuen uns über Ihr Interesse am Exporo Handelsplatz. Für die Abgabe eines Kaufangebotes ist die vorherige Eröffnung eines Wertpapierdepots notwendig. Rufen Sie uns gerne hierfür unter der <a href='tel:+4940210917300'>040 / 210 91 73 - 00</a> an oder schicken Sie eine Nachricht an die E-Mail Adresse: <a href=\'mailto:investment@exporo.com\'>investment@exporo.com</a>`,
            reminder_text_2: 'Ihre persönlichen Daten müssen zunächst vollständig sein.',
            label_2: 'Daten vervollständigen',
        },
        buySuccess: {
            title: 'Es wurde erfolgreich ein Kaufangebot eingereicht.',
        },
        buyTransaction: {
            title_1: 'Kaufangebot für',
            title_2: 'abgeben',
            label_1: 'Preis pro Stück:',
            label_2: 'Verfügbare Stückzahl:',
            label_3: 'Wie viele Stücke möchten Sie kaufen?',
            label_4: 'Stücke',
            label_5: 'Ihr Kaufangebot:',
            checkbox_1: 'Hiermit stimme ich den',
            checkbox_2: 'AGB',
            checkbox_3: 'zum Exporo Handelsplatz zu.',
            valid_input_1: 'Überprüfen Sie bitte Ihre Eingaben!',
            cta: 'Kaufangebot abgeben',
            small_hint: 'Hinweis: Ein Verkauf über den Exporo Handelsplatz ist steuerpflichtig, d.h. etwaige Gewinne\n' + 'unterliegen der Kapitalertragssteuer (plus Soli-Zuschlag plus ggf. Kirchensteuer).',
        },
        offerSuccess: {
            title: 'wurde auf dem Handelsplatz zum Kauf bereitgestellt.',
        },
        offerTransaction: {
            title: 'Verkaufsangebot erstellen für',
            label_1: 'Ihr verfügbares Kapital:',
            label_2: 'Wie viele Stücke möchten Sie maximal verkaufen?',
            tooltip_1: 'Stückzahlberechnung für dieses Projekt : 1 Stück = 1000 €',
            label_3: 'Stück',
            label_4: 'Zu welchem Verkaufspreis pro Stück möchten Sie verkaufen?',
            label_5: '€',
            label_6: 'Inseratsgebühr:',
            tooltip_2: 'Im Falle eines erfolgreichen Verkaufs entsteht eine Inseratsgebühr von 0,5% des Verkaufspreises je Anteil.',
            label_7: 'Ihr Verkaufsangebot:',
            valid_input_1: 'Keine verkaufbaren Stücke verfügbar!',
            valid_input_2: 'Verkauf nicht möglich',
            cta: 'Verkaufsangebot einreichen',
            small_hint: 'Hinweis: Ein Verkauf über den Exporo Handelsplatz ist steuerpflichtig, d.h. etwaige Gewinne unterliegen der Kapitalertragssteuer (plus Soli-Zuschlag plus ggf. Kirchensteuer).',
            store_error: 'Bei der Veröffentlichung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            sending_offer: 'Ihr Kaufangebot wird veröffentlicht'
        },
        offerRejection: {
            title: 'Verkaufsangebot zurückziehen für ',
            already_sold: 'Bereits verkaufte Stücke:',
            in_progress: 'In Abwicklung:',
            remaining_pieces: 'Offene Stücke:',
            sale_price_per_piece: 'Wert pro Stück:',

            store_error: 'Bei dem Zurückziehen des Verkaufsangebotes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            small_hint_1: 'Das Verkaufsangebot kann zurückgezogen werden. Es wurden noch keine Stücke verkauft.',
            small_hint_2: 'Das Verkaufsangebot kann nur für offene Stücke zurückgezogen werden. Bereits verkaufte oder im Handel befindliche Stücke sind vom Zurückziehen des Verkaufsangebotes ausgeschlossen und bleiben bestehen bzw. werden vollzogen.',
            cta: 'Offene Stücke zurückziehen',
            sending_rejection: 'Das Verkaufsangebot wird gelöscht',
            no_rejectable_pieces: 'Verkaufsangebot nicht zurückziehbar',
        },
        secondaryMarketVue: {
            login_hint: 'Jetzt einloggen oder kostenfrei registrieren, Angebot auswählen, Anteile erwerben und von quartalsweisen Ausschüttungen profitieren.',
            login_btn: 'Jetzt anmelden',
        },
    },

    contact: {
        title: 'Kontakt',
        exporo_description: 'Mit Exporo können Sie von attraktiven Immobilienprojekten profitieren - und das schon ab 500 Euro. Von unserem Sitz in Hamburg aus verbinden wir Anleger und Projektträger. Lassen auch Sie uns zusammenfinden: Schreiben Sie uns einfach und wir sprechen mit Ihnen über unsere Angebote oder helfen mit, Ihr Projekt zu realisieren.',
        exporo_contact: 'Wie können wir Ihnen helfen? Nehmen Sie Kontakt mit uns auf.',
        contact_placeholder_1: 'Name *',
        contact_placeholder_2: 'E-Mail *',
        contact_placeholder_3: 'Betreff *',
        contact_placeholder_4: 'Nachricht *',
        checkbox_ag_1: 'Hiermit stimme ich der',
        checkbox_ag_2: 'Einwilligungserklärung zum Kontaktformular',
        checkbox_ag_3: 'und der',
        checkbox_ag_4: 'Datenschutzerklärung',
        checkbox_ag_5: 'der Exporo AG zu.',
        checkbox_gmbh_5: 'der Exporo Investment GmbH zu.',
        cta: 'Absenden',
    },
    error_form: {
        error_message: 'Please choose your title.',
    },
    invest_button: {
        label: 'Investieren',
    },
    progress_bar: {
        funding_in_percentage: 'finanziert',
        remaining: 'verbleibend'
    },
    query_settings: {
        headline: 'Experimental Features',
        toggle: 'Animation:'
    },
    iban: {
        error_not_valid: 'Bitte geben Sie eine gültige IBAN ein.',
        error_cannot_check: 'Iban konnte nicht überprüft werden. Bitte geben Sie ihre BIC ein.',
    }
};

export default de;
