import ExporoVue from './../../ExporoVue';
import AuthRequestApi from './../../Rest/requests/AuthRequestApi';
import RequestCallback from './../../Rest/RequestCallback';
import RestError from './../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import {NOT_LOGGED_IN} from '@/components/Rest/models/Errors';
import RestApiService from '@/components/Rest/services/RestApiService';

class ExporoTokenService extends ExporoVue implements RequestCallback {

    private authRequestApi!: AuthRequestApi;
    private callback!: (token: string) => void;

    constructor() {
        super();

        this.authRequestApi = this.restApi.create(new AuthRequestApi());
    }

    fetchToken(callback: (token: string) => void, externalUserId?: string): any {
        this.callback = callback;

        if (externalUserId) {
            this.authRequestApi.getTokenForUser(this).addParam('id', externalUserId);
        } else {
            this.authRequestApi.getToken(this);
        }
    }

    onFailure(error: RestError, id?: string): void {
        if (error.errorCode === NOT_LOGGED_IN) {
            this.getModalService().openModal('login-modal', {
                data: {
                    reload: true
                }
            });
        }
    }

    onSuccess(response: AxiosResponse, id?: string): void {
        if (response.data.token) {
            this.callback(response.data.token);
        }
    }
}

export default ExporoTokenService;
