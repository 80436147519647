import Vue from 'vue';
import Vuex from 'vuex';
import {TOKEN_KEY} from '@/components/Auth/services/AuthService';

Vue.use(Vuex);

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';

const store = new Vuex.Store({
    state: {
        pending: false,
        isLoggedIn: !!localStorage.getItem(TOKEN_KEY),
    },
    mutations: {
        [LOGIN](state) {
            state.pending = true;
        },
        [LOGIN_SUCCESS](state) {
            state.isLoggedIn = true;
            state.pending = false;
        },
        [LOGIN_FAILED](state) {
            state.isLoggedIn = false;
            state.pending = false;
        },
        [LOGOUT](state) {
            state.isLoggedIn = false;
        },
    },
    actions: {},
    getters: {},
});

export default store;
