


































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import InvestService from '../services/InvestService';
import ExporoVue from '../../ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import InvestorForm from '@/components/User/Investor/InvestorForm.vue';
import Input from '@/components/Input/Input.vue';
import InvestmentRequestPart from '../models/InvestmentRequestPart';
import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
import Events from '@/events';
import LocalStorage from '@/components/LocalStorage/LocalStorage';

@Component({
    components: {
        ExporoModal,
        InvestorForm,
        Input
    }
})
class Investor extends ExporoVue implements InvestmentRequestPart {

    public TITLE = 'Wer investiert?';

    private shouldCheckedDefault: boolean = true;

    @Prop() shouldHide!: boolean;

    @Prop() investors!: models.Investor[];

    @Prop() switchStep!: () => void;
    @Prop() investService!: InvestService;
    @Prop() isAdmin!: boolean;

    @Prop()
    private storeInvestmentRequestService!: StoreInvestmentRequestService;

    @Prop()
    private user!: models.User;
    @Prop()
    private country!: string;

    private investor = -1;

    private formData: models.Investor | null = null;

    isInvestorDeactivated(item: any) {

        return 'accepted' !== item.entity_status && 1 === item.legal_entity;
    }

    isUnderAge(item: any) {
        if (item.dob) {
            const validDate  = new Date();
            validDate.setFullYear( validDate.getFullYear() - 18);
            const investorDate = new Date(item.dob);
            return validDate < investorDate;
        }
        return false;
    }

  openUserEdit(e) {
      e.preventDefault();
      LocalStorage.addBoolean('userEditMode', true);
      // Events.$emit('editUserData');
  }

    @Watch('investor')
    onInvestorChanged() {
        if (+this.investor >= 0) {
            this.investService.setInvestor(this.investors[+this.investor]);
            this.shouldCheckedDefault = false;
        } else {
            this.investService.setInvestor(null);
            this.shouldCheckedDefault = true;
        }
    }

    constructor() {
        super();

        this.storeInvestmentRequestService.registerRequestPart(this);
    }

    mounted() {
        this.TITLE = this.trans.get('checkout.investor.title');
    }

    onInvestorChangeCallback(changedInvestor: models.Investor) {

        const found = this.investors.some((investor: models.Investor, index: number) => {

            if (investor.email === changedInvestor.email) {
                this.investors[index] = changedInvestor;

                return true;
            }

            return false;
        });

        if (!found) {
            this.investors.push(changedInvestor);
        }

        this.$forceUpdate();

        this.getModalService().closeModal('investor-create');
    }

    editInvestor(key: any) {
        this.formData = this.investors[key];
        this.getModalService().openModal('investor-create');
    }

    addInvestor() {
        this.formData = null;
        this.getModalService().openModal('investor-create');
    }

    editUser() {
        this.getModalService().openModal('user-edit');
    }

    getRequestPart(): object {
        const investor: models.Investor | null = this.investService.getInvestor();

        return {
            investor_id: investor ? investor.id : 'null'
        };
    }
}

export default Investor;
