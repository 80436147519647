import {Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import {ExplorationRequest} from '@/components/Checkout/ExplorationV2/models/Types';
import InvestmentRequests from '@/components/Checkout/models/InvestmentRequests';
import RequestApi from '@/components/Rest/models/RequestApi';

class CheckoutRequestApi extends RequestApi {

    @Get('/invest/create/{slug}')
    getCheckoutVermAnlG(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/invest/store/webapp')
    storeCheckoutVermAnlG(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/investment/bonds/create/{contract-id}?equity-modal')
    getCheckoutWpHG(data?: object, callback?: InvestmentRequests): RestApi {
        return {} as RestApi;
    }

    @Get('/investment/fp/bonds/create/{tranche-id}')
    getCheckoutWpHGByTranche(data?: object, callback?: InvestmentRequests): RestApi {
        return {} as RestApi;
    }

    @Post('/investment/bonds/store')
    storeCheckoutWpHG(data?: object, callback?: InvestmentRequests): RestApi {
        return {} as RestApi;
    }

    @Post('/api/exploration/createPreview')
    createExplorationPreview(data?: ExplorationRequest, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/coupon/{coupon}?sum={investSum}')
    getCoupon(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/external/services/iban/bicfromiban/{iban}')
    checkIban(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('{url}')
    uploadInvestorImage(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default CheckoutRequestApi;
