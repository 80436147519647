import ExporoConfig from '@/ExporoConfig';

const EXPORO_LANGUAGE = ExporoConfig.EXPORO_LANGUAGE;

class URLHelper {

    static getQueryParam(paramName: string): string | null {
        const results = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(window.location.href);

        return results ? (decodeURI(results[1]) || null) : null;
    }

    static hasQueryParam(paramName: string): boolean {
        return null !== new RegExp('[\?&]' + paramName).exec(window.location.href);
    }

    static hasFragment(paramName: string): boolean {
        return null !== new RegExp(paramName).exec(window.location.href);
    }

    static getCCTLD(): string {
        return (window.location.hostname.split('.').reverse()[0] || 'local').toUpperCase();
    }

}

export default URLHelper;
