import 'es6-promise/auto';
import cssVars from 'css-vars-ponyfill';
import smoothscroll from 'smoothscroll-polyfill';
require('formdata-polyfill');
require('babel-polyfill');

import Vue from 'vue/dist/vue';
import AuthButton from './components/Auth/AuthButton.vue';
import AuthButtonLogout from './components/Auth/AuthButtonLogout.vue';
import AuthModals from './components/Auth/AuthModals.vue';
import CheckoutWpHGLite from './components/Checkout/CheckoutWpHGLite.vue';
import CheckoutVermAnlG from './components/Checkout/CheckoutVermAnlG.vue';
import InvestButton from './components/InvestButton/InvestButton.vue';
import ExporoModal from './components/Modal/ExporoModal.vue';
import ExporoModalService from './components/Modal/services/ExporoModalService.vue';
import UserEdit from './components/User/Edit/UserEdit.vue';
import ForceEmailCheckbox from './components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
import App from './App.vue';
import PubSubService from '@/components/PubSub/services/PubSubService';
import PasswordChange from '@/components/Auth/PasswordChange.vue';
import Contact from '@/components/Contact/Contact.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import ProgressBarCSSTest from '@/components/ProgressBarCSSTest/ProgressBarCSSTest.vue';

import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Select from '@/components/Select/Select.vue';
import Input from '@/components/Input/Input.vue';
import ErrorForm from '@/components/ErrorForm/ErrorForm.vue';
import Accordion from '@/components/Accordion/Accordion.vue';
import Iban from '@/components/Iban/IbanInput.vue';
import ActionCode from '@/components/ActionCode/ActionCode.vue';
import Toggle from '@/components/Toggle/Toggle.vue';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {Integration, LogLevel} from '@sentry/types';
import VersionManagerService from '@/components/VersionManager/VersionManagerService';
import Router from '@/components/Router/Router';
import Logger from '@/components/Helper/LogHelper';

export const RELEASE_VERSION: string = `exporo-checkout@2.1.0-${ExporoConfig.BUILD_NUMBER}`;

const browserOptions: any = {
    dsn: 'https://80a4df46313e4ac29c1c2f8564fef41c@o38895.ingest.sentry.io/5868247',
    environment: 'production',
    integrations: [
        new Integrations.Vue({
            Vue,
            attachProps: true,
        }),
    ],
    logLevel: LogLevel.Debug
};

// @ts-ignore
Sentry.init(browserOptions);

VersionManagerService.getInstance().initVersion(RELEASE_VERSION);

const pubSubService = PubSubService.getInstance();
pubSubService.init();

cssVars();
smoothscroll.polyfill();

Vue.config.productionTip = false;
// Vue.config.performance = true;
import Lightbox from 'vue-my-photos';
import store from './store/index';
import Button from '@/components/Button/Button.vue';
import ExporoSiri from '@/components/ExperimentalFeatures/ExporoSiri.vue';
import ExporoConfig from '@/ExporoConfig';
import KPIS from '@/components/KPIS/KPIS.vue';
import Checkout from '@/components/Checkout/Checkout.vue';
Vue.component('lightbox', Lightbox);

// Logger.isProduction = false;
// Logger.filter = 'GTM';

Vue.component('exporo-style', App);
Vue.component('Button', Button);
Vue.component('exporo-auth-button', AuthButton);
Vue.component('exporo-auth-button-logout', AuthButtonLogout);
Vue.component('exporo-auth-modals', AuthModals);
Vue.component('exporo-checkout-vermanlg', CheckoutVermAnlG);
Vue.component('exporo-checkout-wphg-lite', CheckoutWpHGLite);
Vue.component('exporo-invest', InvestButton);
Vue.component('exporo-modal', ExporoModal);
Vue.component('exporo-modal-service', ExporoModalService);
Vue.component('exporo-user-edit', UserEdit);
Vue.component('exporo-password-change', PasswordChange);
Vue.component('force-email-checkbox', ForceEmailCheckbox);
Vue.component('exporo-contact', Contact);
Vue.component('exporo-progress', ProgressBar);
Vue.component('exporo-progresstest', ProgressBarCSSTest);

Vue.component('checkbox', Checkbox);
Vue.component('exporo-select', Select);
Vue.component('exporo-input', Input);
Vue.component('error-msg', ErrorForm);
Vue.component('accordion', Accordion);
Vue.component('iban', Iban);
Vue.component('exporo-action-code', ActionCode);
Vue.component('toggle', Toggle);
Vue.component('checkout', Checkout);

const components: object = {
    'exporo-style': App,
    'exporo-auth-button': AuthButton,
    'exporo-auth-button-logout': AuthButtonLogout,
    'exporo-auth-modals': AuthModals,
    'exporo-checkout-vermanlg': CheckoutVermAnlG,
    'exporo-checkout-wphg-lite': CheckoutWpHGLite,
    'exporo-invest': InvestButton,
    'exporo-modal': ExporoModal,
    'exporo-modal-service': ExporoModalService,
    'exporo-user-edit': UserEdit,
    'force-email-checkbox': ForceEmailCheckbox,
    'exporo-password-change': PasswordChange,
    'exporo-contact': Contact,
    'exporo-progress': ProgressBar,
    'exporo-progresstest': ProgressBarCSSTest,
	'checkout': Checkout,
    'checkbox': Checkbox,
    'exporo-select': Select,
    'exporo-input': Input,
    'error-msg': ErrorForm,
    'accordion': Accordion,
    'iban': Iban,
    'exporo-action-code': ActionCode,
    'toggle': Toggle
};

const initSPAs = () => {
    Object.keys(components).forEach( (key) => {
        const exporoComponent = components[key];

        if ('exporo-modal' !== key && 'exporo-modal-service' !== key) {

            if (document.querySelectorAll('.' + key).length > 0) {
                Array.prototype.forEach.call(document.querySelectorAll('.' + key), (el) => new Vue({
                    el: el,
                    store: store,
                    components: {
                        exporoComponent
                    }
                }));
            }
        } else if ('exporo-modal-service' === key) {
            if (document.querySelectorAll('.exporo-modal-service').length > 0) {
                Array.prototype.forEach.call(document.querySelectorAll('.exporo-modal-service'), (el) => new Vue({
                    el: el,
                    store: store,
                    components: {
                        ExporoModal,
                        ExporoModalService
                    }
                }));
            }
        }
    });

    Router.config({mode: 'hash'}).listen();
};

if (document.readyState !== 'loading') {
    Logger.tag('APP').log( 'document is already ready, init SPA`s' );
    initSPAs();
} else {
    document.addEventListener('DOMContentLoaded', () => {
        Logger.tag('APP').log( 'document was not ready, init SPA`s' );
        initSPAs();
    });
}


