
















































































































import {Component, Vue} from 'vue-property-decorator';
import AuthService from './services/AuthService';
import RequestCallback from '../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import RestApiService, {HOST, REDIRECT_AFTER_LOGIN_URL} from '../Rest/services/RestApiService';
import RestError from '../Rest/models/RestError';
import ExporoVue from '../ExporoVue';
import LocalStorage from '../LocalStorage/LocalStorage';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import Input from '@/components/Input/Input.vue';
import ToastService from '../Toast/ToastService';
import {UNDEFINED_ERROR} from '../Rest/models/Errors';
import Toast from '../Toast/Toast';
import ShowError from '../ErrorForm/ShowError.vue';
import Form from '../User/Edit/services/Form';
import FormErrors from '../User/Edit/services/FormErrors';
import ActivationResponse from '@/components/Auth/models/ActivationResponse';
import Tracking from '@/components/GoogleTagManager/services/Tracking';


@Component({
    components: {
        Input,
        ShowError,
    },
    props: {
        openAuthModalByName: Function,
        modalNames: Object,
    },
})
export default class Activation extends ExporoVue implements RequestCallback {

    public input: any;
    public $props: any;
    private authService: AuthService;
    private successText: any;
    private id: string = '';
    private restApiService: RestApiService;
    private userRequestApi: UserRequestApi;
    private isDisabled: boolean = true;
    private error: string = '';
    private activationResponse: ActivationResponse = {
        first_name: '',
        friend_id: null,
        phone: '',
        phone_mobile: '',
        last_name: '',
        success: true,
        id: -1
    };

    private form: Form = {
        sex: '',
        first_name: '',
        last_name: '',
        phone: '',
        phone_mobile: '',
        password: '',
        password_confirmation: '',
        affiliated_partner_id: '',
    };

    private formErrors: FormErrors = {
        sex: [''],
        first_name: [''],
        last_name: [''],
        password: [''],
        password_confirmation: [''],
        phone: [''],
        phone_mobile: [''],
    };

    constructor() {
        super();
        this.restApiService = new RestApiService(this);
        this.authService = new AuthService(this.restApiService);
        this.userRequestApi = this.restApi.create(new UserRequestApi());
    }

    isSexChecked(value: string): boolean {

        return value === this.form.sex;
    }

    mounted() {
        this.id = LocalStorage.get(ExporoVue.ACTIVATION_ID, null) || this.getActivationToken();
        LocalStorage.remove(ExporoVue.ACTIVATION_ID);
        LocalStorage.remove('activation_redirect_enabled');
        this.userRequestApi.activate(this).addParam('token', this.id);
    }

    getActivationToken() {
        const token = location.search.split('activation/')[1];

        return token.split('&')[0];
    }

    save() {
        const form: any = document.getElementById('activation-form');

        if (form) {

            const affiliatedPartner = this.form.affiliated_partner_id;

            const formData = new FormData(form);

            let isAllowedKeyword: boolean = false;

            if (affiliatedPartner && '' !== affiliatedPartner) {
                formData.append('affiliated_partner_id', affiliatedPartner);
            }

            if ('immocation' === String(affiliatedPartner).toLowerCase()) {
                formData.append('affiliated_partner_id', '126151');
                isAllowedKeyword = true;
            }

            if ('rentablo' === String(affiliatedPartner).toLowerCase()) {
                formData.append('affiliated_partner_id', '125062');
                isAllowedKeyword = true;
            }

            if (!isAllowedKeyword && '' !== affiliatedPartner && isNaN(Number(affiliatedPartner))) {
                this.form.affiliated_partner_id = '';
                this.error = this.trans.get('auth.activation.error');
                Tracking.trackError('activation overlay', true, 'activation form');
            } else {
                this.error = '';
            }
            if ('' === this.error) {
                formData.append('activation_token', this.id);

                if ('' === formData.get('affiliated_partner_id') || null === formData.get('affiliated_partner_id')) {
                    formData.delete('affiliated_partner_id');
                }

                this.userRequestApi.completeActivation(formData, this);
                Tracking.trackActivationSubmit('activation overlay', false, this.activationResponse.id, affiliatedPartner);
            }

        }
    }

    openModalByName(name: string) {

        this.$props.openAuthModalByName(name);
    }

    closeModal(): void {
        this.getModalService().closeModal('activation-modal');
    }

    onFailure(error: RestError): void {
        this.form.affiliated_partner_id = '';

        this.fillErrors(error.errorMessage);
        Tracking.trackError('activation overlay', true, 'activation form');


        if (error.errorCode === UNDEFINED_ERROR) {
            const toastService = new ToastService();
            toastService.addToast(new Toast(this.trans.get('auth.activation.toast_error'), 5000));
        }
    }

    fillErrors(errors: any) {
        this.formErrors = {
            sex: [''],
            first_name: [''],
            last_name: [''],
            password: [''],
            password_confirmation: [''],
            phone: this.formErrors.phone,
            phone_mobile: this.formErrors.phone_mobile,
        };

        if (this.form.phone) {
            this.formErrors.phone = [''];
        }

        if (this.form.phone_mobile) {
            this.formErrors.phone_mobile = [''];
        }


        if (errors.hasOwnProperty('first_name')) {
            this.formErrors.first_name = errors.first_name[0];
        }

        if (errors.hasOwnProperty('last_name')) {
            this.formErrors.last_name = errors.last_name[0];
        }

        if (errors.hasOwnProperty('sex')) {
            this.formErrors.sex = errors.sex[0];
        }

        if (errors.hasOwnProperty('password')) {
            const keyPart = errors.password[0];

            if ('password_lacks_complexity' === keyPart) {
                const transKey = 'auth.activation.' + keyPart;
                this.formErrors.password = [this.trans.get(transKey)];
            } else {
                this.formErrors.password = [keyPart];
            }
        }

        if (!this.form.phone && errors.hasOwnProperty('phone')) {
            this.formErrors.phone = errors.phone[0];
        }

        if (errors.hasOwnProperty('phone_mobile')) {
            this.formErrors.phone_mobile = errors.phone_mobile[0];
        }
    }

    onSuccess(response: AxiosResponse): void {

        if (response.config.url && -1 === response.config.url.indexOf('activation/webapp/complete')) {
            this.activationResponse = response.data;
            this.isDisabled = !this.activationResponse.success;

        } else {

            // //this.$store.commit(LOGIN);
            // //this.$store.commit(LOGIN_SUCCESS);

            let lifetime = 30;

            if (response.headers.hasOwnProperty('x-session-lifetime')) {
                lifetime = response.headers['x-session-lifetime'];
            }

            this.authService.storeLogin(JSON.stringify({
                'lifetime': lifetime,
                'saved_at': +new Date(),
            }));

            if (REDIRECT_AFTER_LOGIN_URL) {
                window.location.href = REDIRECT_AFTER_LOGIN_URL;
            } else {
                window.location = (window as any).location.href.split('?')[0];
            }
        }
    }
}
