import PushObject from '@/components/GoogleTagManager/interfaces/PushObject';
import Logger from '@/components/Helper/LogHelper';
import CardProduct from '@/components/GoogleTagManager/interfaces/CardProduct';
import PushCardObject from '@/components/GoogleTagManager/interfaces/PushCardObject';
import DetailViewProduct from '@/components/GoogleTagManager/interfaces/DetailViewProduct';
import PushDetailViewObject from '@/components/GoogleTagManager/interfaces/PushDetailViewObject';
import PushCheckoutObject from '@/components/GoogleTagManager/interfaces/PushCheckoutObject';
import CheckoutField from '@/components/GoogleTagManager/interfaces/CheckoutField';
import TransactionProducts from '@/components/GoogleTagManager/interfaces/TransactionProducts';
import PushPurchaseObject from '@/components/GoogleTagManager/interfaces/PushPurchaseObject';
import PushUserObject from '@/components/GoogleTagManager/interfaces/PushUserObject';
import UserService from '@/components/User/services/UserService';

declare global {
    interface Window {
        dataLayer: Array<PushObject | PushCardObject | PushDetailViewObject | PushCheckoutObject | PushPurchaseObject | PushUserObject>;
    }
}

class TrackService {

    public createFirstInvestObject(aid: string | null): any {
        return {
            'event': 'invest',
            'transactionInvestmentCount': 1,
            'a_aid': aid
        };
    }

    public createUserObject(id: string, aid: string): PushUserObject {
        return {
            'user': {'loginState': 'true', 'userID': id, 'a_aid': aid},
            'event': 'gtmEvent',
            'eventCategory': 'user login',
            'eventAction': 'success',
            'eventLabel': undefined,
            'eventValue': undefined,
            'eventProperty': undefined,
            'eventNonInteraction': false,
        };
    }

    public createDetailViewProduct(detailViewProduct: DetailViewProduct): PushDetailViewObject {

        return {
            'event': 'productDetail',
            'ecommerce': {
                'detail': {
                    'products': [detailViewProduct]
                }
            }
        };
    }

    public createCardProduct(cardProduct: CardProduct): PushCardObject {

        return {
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {
                    'products': [cardProduct]
                }
            }
        };
    }

    public createCheckoutProduct(checkoutField: CheckoutField): PushCheckoutObject {

        return {
            'event': 'checkout',
            'ecommerce': {
                'checkout': checkoutField,
            }
        };
    }

    public createPushObject(action: string, category: string, nonInteraction: boolean, label?: string, type?: string, step?: number): PushObject {
        let baseObject: PushObject = {
            'event': 'gtmEvent',
            'eventAction': action,
            'eventCategory': category,
            'eventLabel': label,
            'eventValue': undefined,
            'eventNonInteraction': nonInteraction,
        };

        if (undefined !== type && undefined !== step) {
            baseObject = Object.assign(baseObject, {'eventProperty': {checkout_type: type, checkout_step: step}});
        }

        return baseObject;
    }

    public createPurchaseObject(id: string, affiliation: any, total: string, tax: string, shipping: string, transcoupon: string, transactionProducts: TransactionProducts): PushPurchaseObject {

        return {
            'event': 'invest',
            'transactionId': id,
            'transactionAffiliation': affiliation,
            'transactionTotal': total,
            'transactionTax': tax,
            'transactionShipping': shipping,
            'transactionCoupon': transcoupon, // may be omitted or set to empty string if not available
            'transactionProducts': [transactionProducts]
        };
    }


    public setLog(pushObject: PushObject | PushCardObject | PushDetailViewObject | PushCheckoutObject | PushPurchaseObject | PushUserObject) {
        Logger.tag('GTM').log('Push object to the DataLayer: ', pushObject);
    }

    public pushUserToDataLayer(pushUserObject) {

        window.dataLayer = window.dataLayer || [];

        this.setLog(pushUserObject);
        window.dataLayer.push(pushUserObject);
    }

    public pushAnyToDataLayer(pushObject: any) {

        window.dataLayer = window.dataLayer || [];

        this.setLog(pushObject);
        window.dataLayer.push(pushObject);
    }

    public pushToDataLayer(pushObject: PushObject) {

        window.dataLayer = window.dataLayer || [];

        this.setLog(pushObject);
        window.dataLayer.push(pushObject);
    }

    public pushCheckoutToDataLayer(pushCheckoutObject: PushCheckoutObject) {
        window.dataLayer = window.dataLayer || [];

        this.setLog(pushCheckoutObject);
        window.dataLayer.push(pushCheckoutObject);
    }

    public pushDetailViewProductToDataLayer(pushDetailViewObject: PushDetailViewObject) {
        window.dataLayer = window.dataLayer || [];

        this.setLog(pushDetailViewObject);
        window.dataLayer.push(pushDetailViewObject);
    }

    public pushCardProductToDataLayer(pushCardObject: PushCardObject) {
        window.dataLayer = window.dataLayer || [];

        this.setLog(pushCardObject);
        window.dataLayer.push(pushCardObject);
    }

    public pushPurchaseObjectToDataLayer(pushPurchaseObject: PushPurchaseObject) {
        window.dataLayer = window.dataLayer || [];

        this.setLog(pushPurchaseObject);
        window.dataLayer.push(pushPurchaseObject);
    }

    public pushFirstInvestmentObjectToDataLayer(pushFirstInvestObject: any) {
        window.dataLayer = window.dataLayer || [];

        this.setLog(pushFirstInvestObject);
        window.dataLayer.push(pushFirstInvestObject);
    }
}

export default TrackService;
