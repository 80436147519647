












































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ForceEmailCheckbox from '@/components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
import ExporoVue from '../../ExporoVue';
import InvestService from '../services/InvestService';
import Events from '../../../events';
import Error from '@/components/Checkout/Validator/Error.vue';
import CheckoutValidator from '../Validator/CheckoutValidator';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import {BASE_TOKEN, HOST} from '@/components/Rest/services/RestApiService';
import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
import InvestmentRequestPart from '../models/InvestmentRequestPart';
import ExplorationService from '@/components/Checkout/ExplorationV2/services/ExplorationService';
import LegalMailRequestApi from '../../Rest/requests/LegalMailRequestApi';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import ToastService from '../../Toast/ToastService';
import Toast from '../../Toast/Toast';
import {CHECKOUT_MODAL_NAME} from '../CheckoutWpHGLite.vue';
import SessionStorage from '@/components/SessionStorage/SessionStorage';

@Component({
    components: {
        ForceEmailCheckbox,
        Error,
        Checkbox
    },
    props: {
        shouldHide: Boolean,
        switchStep: Function
    }
})
class SummaryWpHGLite extends ExporoVue implements InvestmentRequestPart, RequestCallback {

    private payloadPart: any = {
        accept_to_save: false,
        i_have_read_all_docs: false,
        contract_id: null
    };

    public TITLE = this.trans.get('checkout.WpHGLite.summaryWpHGLite.title');

    @Prop() private checkoutValidator!: CheckoutValidator;
    @Prop() private legalMailsAlreadySent?: boolean;
    @Prop() private user!: {
        first_name;
        last_name;
        email;
        street;
        street_no;
        postal_code;
        city;
    };
    @Prop() private step!: number;
    @Prop() private uniqueUserHash!: string;
    @Prop() private country!: string;
    @Prop() private contract!: any;
    @Prop() private investService!: InvestService;
    @Prop() private isFirstInvestment!: boolean;
    @Prop() private isBondTypeBlockchain!: boolean;
    @Prop() private requiredFilesForDownloadInSummary!: string[];
    @Prop() private baaderBankFiles!: object[];
    @Prop() private investmentProduct!: any;
    @Prop() private storeInvestmentRequestService!: StoreInvestmentRequestService;
    @Prop() private explorationService!: ExplorationService;
    @Prop() private summaryConfirmationText!: string;

    private investType: string = 'debit';
    private investSum: number = 0;

    private legalMailRequestApi: LegalMailRequestApi;

    constructor() {
        super();
        this.storeInvestmentRequestService.registerRequestPart(this);
        this.updateInvestData();

        this.payloadPart.contract_id = this.contract.id;

        Events.$on(InvestService.investChangedChannel, () => {
            this.updateInvestData();
        });

        this.legalMailRequestApi = this.restApi.create(new LegalMailRequestApi());
    }

    onChangeCheckbox(name: string) {

        const checkbox: any = document.getElementById(name);

        if (checkbox) {
            this.payloadPart.accept_to_save = checkbox.checked;
            this.investService.updateFomData(name.toLowerCase(), checkbox.checked);
        }

        if ('accept_to_save' === name && this.isFirstInvestment && !this.isBondTypeBlockchain) {

            this.sendBaaderMails();
        }
    }

    sendBaaderMails() {
        this.legalMailRequestApi.sendBaaderBankMail({
            contractId: this.contract.id
        }, this);
    }

    updateInvestData() {
        this.investType = this.investService.getInvestType();
        this.investSum = this.investService.getInvestSum();
    }

    openEditForm() {
        this.getModalService().openModal('user-edit', {
            onClose: (data: any) => {
                if (data) {
                    this.user.first_name = data.first_name;
                    this.user.last_name = data.last_name;
                    this.user.email = data.email;
                    this.user.street = data.street;
                    this.user.street_no = data.street_no;
                    this.user.postal_code = data.postal_code;
                    this.user.city = data.city;
                    this.$forceUpdate();
                }
            },
            from: CHECKOUT_MODAL_NAME
        });
    }


    getPreviewLink(): string {
        const {exploration, ...rest} = this.explorationService.form;
        const data = Object.assign({}, exploration, rest);

        const parameters = Object.keys(data).map((key) => {
            return `${key}=${data[key]}`;
        }).join('&');

        return `${HOST}/preview/exploration/webapp?${parameters}&uniqueUserHash=${this.uniqueUserHash}&x-api-access-key=${BASE_TOKEN}`;
    }

    mounted() {
      Events.$on('saveCheckoutData', () => {
        SessionStorage.addObject('summaryPayload', this.payloadPart);
      });

      if (SessionStorage.get('checkMandate', false)) {
        this.payloadPart = SessionStorage.getObject('summaryPayload', {});
      }
    }

    getRequestPart(): object {

        return this.payloadPart;
    }

    onFailure(error: RestError): void {
        const toastService = new ToastService();

        toastService.addToast(new Toast('Unbekannter Fehler! Baader Bank Mail konnten nicht gesendet werden. Bitte kontaktieren Sie uns.', 5000));
    }

    onSuccess(response: AxiosResponse): void {

    }
}

export default SummaryWpHGLite;
